import { FC, useMemo } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';

import { GraphLegend } from '../components/GraphLegend/GraphLegend';
import { GraphTooltip } from '../components/GraphTooltip/GraphTooltip';
import { TooltipFormatter } from '../components/TooltipFormatter/TooltipFormatter';
import { useGraphTooltip } from '../hooks/useGraphTooltip';

interface Props {
  data: {
    name: string;
    value: number;
    color: string;
  }[];
  height?: number
}

export const JitPieChart: FC<Props> = ({ data, height = 240 }) => {
  const { position, activeKey, handleMouseMove, handleMouseEnter, handleMouseLeave } = useGraphTooltip();

  const handleMove = (name: string, event: React.MouseEvent) => {
    handleMouseEnter(name);
    handleMouseMove(event);
  };

  const activeItemCount = useMemo(() => data.find((entry) => entry.name === activeKey)?.value, [data, activeKey]);

  return (
    <ResponsiveContainer height={height} width='100%'>
      <PieChart onMouseLeave={handleMouseLeave}>
        <Pie
          data={data}
          dataKey='value'
          onMouseLeave={handleMouseLeave}
          outerRadius={90}
          stroke='none'
        >
          {data.map((entry) => (
            <Cell
              key={entry.name}
              fill={entry.color}
              onMouseEnter={() => handleMouseEnter(entry.name)}
              onMouseMove={(event) => handleMove(entry.name, event)}
              opacity={!activeKey || activeKey === entry.name ? 1 : 0.3}
            />
          ))}
        </Pie>

        <Tooltip
          content={(
            <GraphTooltip hide={!activeItemCount}>
              <TooltipFormatter label={activeKey} value={activeItemCount} />
            </GraphTooltip>
              )}
          position={position}
          wrapperStyle={{ outline: 'none' }}
        />

        <Legend content={<GraphLegend />} layout='vertical' />
      </PieChart>
    </ResponsiveContainer>
  );
};
