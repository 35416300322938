import { MetricCommonProps } from '../../types';
import { GenericDateBasedMetric } from '../GenericDateBasedMetric/GenericDateBasedMetric';

import { Actions } from './components/Actions/Actions';

import { Threshold } from 'services/MetricService/types';

const FALSE_POSITIVE_REDUCTION_THRESHOLDS: Threshold[] = [
  {
    metric: 'false_positive_reduction',
    goal: 0,
    threshold: 0,
    isPlaceholder: true,
  },
];

export const FalsePositiveReduction = ({ timeRange, thresholds, cardRef }: MetricCommonProps) => (
  <GenericDateBasedMetric
    cardRef={cardRef}
    dateRange={timeRange}
    details={<Actions timeFrame={timeRange} />}
    drillDownMetric='prioritized_findings_drill_down'
    mainMetric='prioritized_findings_score'
    subtitle='pages.securityImpactV3.components.falsePositiveReduction.subtitle'
    summaryText='pages.securityImpactV3.components.falsePositiveReduction.summary'
    thresholds={thresholds ?? FALSE_POSITIVE_REDUCTION_THRESHOLDS}
    title='pages.securityImpactV3.components.falsePositiveReduction.title'
  />
);
