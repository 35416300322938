import { FC, useCallback, useMemo } from 'react';

import styles from './TicketsList.module.scss';

import { useVendorTicketConfig } from 'components/CreateTicketButton/VendorConfig';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { i18n } from 'locale/i18n';
import { TicketFinding } from 'types/interfaces';
import { formatDate } from 'utils/functions/stringFormatter';

interface Props {
  tickets: TicketFinding[];
  showStatus?: boolean;
}

export const TicketsList: FC<Props> = ({ tickets, showStatus = false }) => {
  const sortedTickets = useMemo(() => tickets.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()), [tickets]);
  const { vendorTicketConfig } = useVendorTicketConfig();

  const getTicketText = useCallback((ticket: TicketFinding) => vendorTicketConfig[ticket.vendor as keyof typeof vendorTicketConfig].ticketName, [vendorTicketConfig]);

  const ticketsToRender = useMemo(() => sortedTickets.map((ticket, index) => ({
    ...ticket,
    ticketUrl: ticket.ticketUrl || '',
    text: getTicketText(ticket),
    label: i18n.t('tickets.link.createdDate', { date: formatDate(ticket.createdAt, false, true) }),
    testId: `ticket-link-${index}`,
  })), [getTicketText, sortedTickets]);

  return (
    <div className={styles.ticketsList} data-testid='ticketsList'>
      {ticketsToRender.map((ticket) => (
        <div key={ticket.createdAt} className={styles.ticketItem}>
          <JitExternalLink
            href={ticket.ticketUrl}
            label={ticket.label}
            showIcon={false}
            testId={ticket.testId}
            text={ticket.text}
            withUnderline
          />

          {showStatus && ticket.tmsStatus && (
            <span
              className={styles.ticketStatus}
              data-testid={`ticket-status-${ticket.testId}`}
            >
              {ticket.tmsStatus}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
