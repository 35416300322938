import { Dispatch, SetStateAction, useMemo } from 'react';

import actionsStyles from '../actionComponents.module.scss';

import { MicrosoftTeamsActionButton } from './MicrosoftTeamsActionButton';

import { SlackIconIris } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useCommunicationPlatforms } from 'pages/TeamsPortal/GuidePage/hooks/useCommunicationPlatforms';
import { extractValueLabel } from 'services/SlackService/utils';
import { ActionButtonVariant } from 'types/interfaces';
import { ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

export const useGetConfigureSlackAction = (
  check: ITeamCheckDetails | undefined,
  setOpenedDialogKey: Dispatch<SetStateAction<string | null>>,
) => {
  const [platformOptions] = useCommunicationPlatforms();
  const toolTipText = useMemo(() => check?.info || '', [check?.info]);

  const disabled = useMemo(() => !platformOptions.length, [platformOptions]);

  const gradientButton = useMemo(() => (
    !disabled ? (
      <JitGradientButton
        data-testid={`action-${TaskKeyEnum.configureSlack}`}
        onClick={() => setOpenedDialogKey(TaskKeyEnum.configureSlack)}
      >
        <div className={actionsStyles.gradientButton}>
          <JitText size='s' text={check?.options?.button?.label} />
        </div>
      </JitGradientButton>
    ) : (
      <JitTooltip
        key={check?.checkSlug}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none',
            },
          },
        }}
        data-testid='InfoTooltip'
        followCursor={false}
        placement='top-start'
        title={toolTipText}
      >

        <JitGradientButton
          data-testid={`action-${TaskKeyEnum.configureSlack}`}
          disabled
          onClick={() => setOpenedDialogKey(TaskKeyEnum.configureSlack)}
        >
          <div className={actionsStyles.gradientButton}>
            <JitText size='s' text={check?.options?.button?.label} />
          </div>
        </JitGradientButton>
      </JitTooltip>
    )
  ), [check?.checkSlug, check?.options?.button?.label, disabled, setOpenedDialogKey, toolTipText]);

  const checkValue = check?.value;
  const button = useMemo(
    () => {
      const vendor = typeof checkValue === 'object' ? checkValue?.vendor : 'slack';

      if (vendor === 'microsoftTeams' && typeof checkValue === 'object') {
        return (
          <MicrosoftTeamsActionButton
            checkValue={checkValue}
            disabled={disabled}
            onClick={() => setOpenedDialogKey(TaskKeyEnum.configureSlack)}
          />
        );
      }

      const label = extractValueLabel(checkValue);

      return (
        <JitActionButton actionButton={{
          disabled,
          label,
          handleClick: () => setOpenedDialogKey(TaskKeyEnum.configureSlack),
          icon: SlackIconIris,
          variant: ActionButtonVariant.SECONDARY,
        }}
        />
      );
    },
    [checkValue, disabled, setOpenedDialogKey],
  );

  const actionButton = useMemo(
    () => (check?.isCompleted && !disabled ? button : gradientButton),
    [button, check?.isCompleted, disabled, gradientButton],
  );

  return ({ actionButton });
};
