import { uniqBy } from 'lodash';
import { useMemo } from 'react';

import { MetricCommonProps } from '../../types';
import { DrillDownChart } from '../GenericDateBasedMetric/components/DrillDownChart/DrillDownChart';
import { DrillDownSummary } from '../GenericDateBasedMetric/components/DrillDownSummary/DrillDownSummary';
import { useValueAndTrend } from '../GenericDateBasedMetric/useValueAndTrend';

import { MTTRSummary } from './components/MTTRSummary/MTTRSummary';
import { useMttrActions } from './useMttrActions';

import { MetricCard } from 'components/MetricCard/MetricCard';
import { Actions } from 'components/MetricDetails/components/Actions/Actions';
import { useDateBasedMetric } from 'services/MetricService/hooks';
import { Threshold } from 'services/MetricService/types';

const DEFAULT_THRESHOLDS: Threshold[] = [
  {
    metric: 'mttr',
    metricSubtype: 'percentage',
    goal: 90,
    threshold: 80,
    isPlaceholder: true,
  },
  {
    metric: 'mttr',
    metricSubtype: 'HIGH',
    goal: 14,
    threshold: 21,
    isPlaceholder: true,
  }, {
    metric: 'mttr',
    metricSubtype: 'CRITICAL',
    goal: 7,
    threshold: 14,
    isPlaceholder: true,
  },

];
const thresholdLabelTransform = (label: string | undefined) => label && `pages.securityImpactV3.components.mttr.goals.labels.${label}`;

export const MTTR = ({ timeRange, thresholds, cardRef }: MetricCommonProps) => {
  const { data: mttrData, isLoading: isLoadingMTTR } = useDateBasedMetric('mttr_overall_threshold_compliance', timeRange);

  const actions = useMttrActions(timeRange);

  const metricThresholds = useMemo(() => {
    const thresholdValues = uniqBy([...(thresholds ?? []), ...DEFAULT_THRESHOLDS], 'metricSubtype');
    return thresholdValues.sort((a, b) => {
      if (a.metricSubtype === 'percentage') return -1;
      if (b.metricSubtype === 'percentage') return 1;
      return a.metricSubtype?.localeCompare(b.metricSubtype ?? '') ?? 0;
    });
  }, [thresholds]);

  const percentageThreshold = useMemo(() => thresholds?.find((threshold) => threshold.metricSubtype === 'percentage'), [thresholds]);

  const formattedGoal = useMemo(() => {
    if (!percentageThreshold) return undefined;
    return `${percentageThreshold.goal}%`;
  }, [percentageThreshold]);

  const { formattedValue, trend } = useValueAndTrend(mttrData);

  return (
    <MetricCard
      ref={cardRef}
      details={(
        <>
          {mttrData?.narration ? (
            <DrillDownSummary
              narration={mttrData.narration}
              subTitle='pages.securityImpactV3.components.mttr.subTitle'
              title='pages.securityImpactV3.components.mttr.title'
            />
          ) : null}

          <Actions actions={actions} />

          <DrillDownChart
            dateRange={timeRange}
            metricName='mttr_threshold_compliance_by_criticality'
            subtitle='pages.securityImpactV3.components.mttr.subTitle'
            title='pages.securityImpactV3.components.mttr.title'
          />

        </>
      )}
      goal={formattedGoal}
      loading={isLoadingMTTR}
      subtitle='pages.securityImpactV3.components.mttr.subTitle'
      summaryComponent={(
        <MTTRSummary timeRange={timeRange} />
      )}
      thresholds={metricThresholds}
      title='pages.securityImpactV3.components.mttr.title'
      transformSubmetricLabel={thresholdLabelTransform}
      trend={trend}
      value={formattedValue}
    />
  );
};
