import { LightbulbOutlined } from '@mui/icons-material';
import React from 'react';

import styles from './Summary.module.scss';

import { JitText } from 'components/JitText/JitText';

interface SummaryProps {
  summary: string
}

export const Summary: React.FC<SummaryProps> = ({ summary }) => (
  <div className={styles.summary}>
    <div className={styles.summaryContent}>
      <div className={styles.summaryHeader}>
        <LightbulbOutlined className={styles.icon} />

        <JitText component='h3' muted text='components.metricCard.summaryHeader' />

      </div>

      <JitText bold text={summary} />

    </div>
  </div>
);
