import { mapValues } from 'lodash';
import { useMemo } from 'react';

import { ChartSkeleton } from '../ChartSkeleton/ChartSkeleton';

import { Chart } from 'components/Chart/Chart';
import { Section } from 'components/MetricDetails/components/Section/Section';
import { GraphEmpty } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { useAggregateWeeklyData } from 'pages/InsightsPage/SubPages/SecurityImpact/hooks/useAggregateWeeklyData';
import { useEnhanceDataWithThreshold } from 'pages/InsightsPage/SubPages/SecurityImpact/hooks/useEnhanceDataWithThreshold';
import { useDateBasedMetric } from 'services/MetricService/hooks';
import { GenericMetric, Threshold } from 'services/MetricService/types';
import { TimeAgo } from 'types/enums';

interface Props {
  title: string;
  subtitle: string;
  metricName: GenericMetric;
  dateRange: TimeAgo;
  threshold?: Threshold;
  transformLabels?: (label: string) => string;
}

export const DrillDownChart = <T extends string = string>({ title, subtitle, metricName, dateRange, transformLabels, threshold }: Props) => {
  const { data, isLoading } = useDateBasedMetric<T>(metricName, dateRange);

  // Apply weekly aggregation for ThreeMonths data
  const aggregatedData = useAggregateWeeklyData<T>(data, dateRange);

  const transformedData = useMemo((): typeof aggregatedData => {
    if (!transformLabels) {
      return aggregatedData;
    }

    if (!aggregatedData) {
      return aggregatedData;
    }

    return {
      ...aggregatedData,
      label: mapValues(aggregatedData.label, transformLabels),
    };
  }, [aggregatedData, transformLabels]);

  const enhancedData = useEnhanceDataWithThreshold(transformedData, threshold);

  return (
    <Section subTitle={title} title={subtitle}>
      {isLoading && <ChartSkeleton style={{ transform: 'none' }} />}

      {enhancedData && <Chart data={enhancedData} />}

      {(!isLoading && !enhancedData) && (
        <div style={{ height: '220px' }}>
          <GraphEmpty />
        </div>
      )}
    </Section>
  );
};
