import { paragon } from '@useparagon/connect';
import { useQuery } from 'react-query';

import * as keys from './keys';
import { MSTeamsTeamsResponse, MSTeamsChannelsResponse } from './types';

const buildMSODataFilter = (search?: string) => {
  if (!search) {
    return '';
  }
  return `$filter=startswith(displayName,'${search.replace(/'/g, "''")}')`;
};

const GET_OPTIONS = {
  method: 'GET',
  body: undefined,
  headers: undefined,
} as const;

export const useMSTeamsTeams = (search?: string) => useQuery({
  queryKey: keys.teams(search),
  queryFn: () => paragon.request<MSTeamsTeamsResponse>(
    'microsoftTeams',
    `teams?${buildMSODataFilter(search)}`,
    GET_OPTIONS,
  ),
  keepPreviousData: true,
});

export const useMSTeamsTeamChannels = (selectedTeam: string, search?: string) => useQuery({
  queryKey: keys.channels(selectedTeam, search),
  queryFn: () => paragon.request<MSTeamsChannelsResponse>(
    'microsoftTeams',
    `teams/${selectedTeam}/channels?${buildMSODataFilter(search)}`,
    GET_OPTIONS,
  ),
  keepPreviousData: true,
  enabled: Boolean(selectedTeam),
});
