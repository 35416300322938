import {
  APIResource,
  APIStage,
  AWSAccount,
  AWSLambda,
  AWSPolicy,
  AWSTag,
  AzurePipelines,
  BitbucketIcon,
  Bright,
  CircleCI,
  Cloudformation,
  CloudTrail,
  DNSZone,
  Docker,
  EBSVolume,
  EC2Instance,
  EditPen,
  EKSCluster,
  GCPBucket, GCPDisk,
  GCPFirewall, GCPInstance,
  GCPLabel,
  GCPProject,
  GCPServiceAccount,
  GCPVpc,
  GithubActions,
  GithubActionsBlue,
  GithubIcon,
  GitlabIcon,
  InvictiIcon,
  IntegrationsPage,
  Jenkins,
  Kubernetes,
  LaunchConfiguration,
  LoadBalancer,
  PremiumLock,
  S3Bucket,
  Shield,
  SNS,
  SQSQueue,
  TravisCI,
  Users,
  WizIcon,
  OrcaSecurity,
} from 'assets';

export const iconsMap = {
  // Priority Factors
  'Externally Accessible': {
    icon: Shield,
    bgColor: '#ED7100',
  },
  'Measurement Tools': {
    icon: Shield,
    bgColor: '#ED7100',
  },
  'Non-Production': {
    icon: Shield,
    bgColor: '#ED7100',
  },
  Production: {
    icon: Shield,
    bgColor: '#ED7100',
  },
  'Database Integration': {
    icon: IntegrationsPage,
    bgColor: '#186aba',
  },
  DBIntegration: {
    icon: IntegrationsPage,
    bgColor: '#186aba',
  },
  Authentication: {
    icon: Shield,
    bgColor: '#D02028',
  },
  Payment: {
    icon: PremiumLock,
    bgColor: '#ED7100',
  },
  'Critical Severity': {
    icon: Shield,
    bgColor: '#D02028',
  },
  'Testing Tools': {
    icon: Shield,
    bgColor: '#357fdf',
  },
  'Test File': {
    icon: Shield,
    bgColor: '#357fdf',
  },
  TestFile: {
    icon: Shield,
    bgColor: '#357fdf',
  },
  'Business Critical': {
    icon: Shield,
    bgColor: '#ED7100',
  },
  Administrator: {
    icon: AWSAccount,
    bgColor: '#D02028',
  },
  BusinessRole: {
    icon: AWSAccount,
    bgColor: '#D02028',
  },
  FixAvailable: {
    icon: Shield,
    bgColor: '#0067ed',
  },
  Severity: {
    icon: Shield,
    bgColor: '#D02028',
  },
  Finding: {
    icon: Shield,
    bgColor: '#EC5B5B',
  },
  'Fix Availability': {
    icon: EditPen,
    bgColor: '#A5A6F6',
  },
  // SCM Resources
  GitHubRepository: {
    icon: GithubIcon,
    bgColor: '#474747',
  },
  'GitHub Repository': {
    icon: GithubIcon,
    bgColor: '#474747',
  },
  GitHubOrganization: {
    icon: GithubIcon,
    bgColor: '#474747',
  },
  'GitHub Organization': {
    icon: GithubIcon,
    bgColor: '#474747',
  },
  'GitLab Project': {
    icon: GitlabIcon,
    bgColor: '#474747',
  },
  'GitLab Group': {
    icon: GitlabIcon,
    bgColor: '#474747',
  },
  Workflow: {
    icon: GithubActionsBlue,
    bgColor: '#474747',
  },
  // AWS Resources
  S3Bucket: {
    icon: S3Bucket,
    bgColor: '#377B20',
  },
  S3: {
    icon: S3Bucket,
    bgColor: '#377B20',
  },
  'S3 Bucket': {
    icon: S3Bucket,
    bgColor: '#377B20',
  },
  AWSAccount: {
    icon: AWSAccount,
    bgColor: '#D02028',
  },
  'AWS Account': {
    icon: AWSAccount,
    bgColor: '#D02028',
  },
  AWSTag: {
    icon: AWSTag,
    bgColor: '#ED7100',
  },
  'AWS Tag': {
    icon: AWSTag,
    bgColor: '#ED7100',
  },
  AWSRole: {
    icon: AWSPolicy,
    bgColor: '#D02028',
  },
  'AWS Role': {
    icon: AWSPolicy,
    bgColor: '#D02028',
  },
  IAM: {
    icon: AWSPolicy,
    bgColor: '#D02028',
  },
  AWSPolicy: {
    icon: AWSPolicy,
    bgColor: '#D02028',
  },
  'AWS Policy': {
    icon: AWSPolicy,
    bgColor: '#D02028',
  },
  AWSPrincipal: {
    icon: AWSPolicy,
    bgColor: '#D02028',
  },
  'AWS Principal': {
    icon: AWSPolicy,
    bgColor: '#D02028',
  },
  APIResource: {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  'API Resource': {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  APIGatewayResource: {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  'API Gateway Resource': {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  APIGatewayRestAPI: {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  'API Gateway Rest API': {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  APIGatewayStage: {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  'API Gateway Stage': {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  RestAPI: {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  'Rest API': {
    icon: APIResource,
    bgColor: '#8C4FFF',
  },
  APIStage: {
    icon: APIStage,
    bgColor: '#8C4FFF',
  },
  'API Stage': {
    icon: APIStage,
    bgColor: '#8C4FFF',
  },
  DNSZone: {
    icon: DNSZone,
    bgColor: '#8C4FFF',
  },
  'DNS Zone': {
    icon: DNSZone,
    bgColor: '#8C4FFF',
  },
  AWSDNSZone: {
    icon: DNSZone,
    bgColor: '#8C4FFF',
  },
  'AWS DNS Zone': {
    icon: DNSZone,
    bgColor: '#8C4FFF',
  },
  LoadBalancer: {
    icon: LoadBalancer,
    bgColor: '#ED7100',
  },
  'Load Balancer': {
    icon: LoadBalancer,
    bgColor: '#ED7100',
  },
  EC2Instance: {
    icon: EC2Instance,
    bgColor: '#ED7100',
  },
  'EC2 Instance': {
    icon: EC2Instance,
    bgColor: '#ED7100',
  },
  EBSVolume: {
    icon: EBSVolume,
    bgColor: '#E45A4D',
  },
  'EBS Volume': {
    icon: EBSVolume,
    bgColor: '#E45A4D',
  },
  LaunchConfiguration: {
    icon: LaunchConfiguration,
    bgColor: '#ED7100',
  },
  'Launch Configuration': {
    icon: LaunchConfiguration,
    bgColor: '#ED7100',
  },
  EKSCluster: {
    icon: EKSCluster,
    bgColor: '#ED7100',
  },
  'EKS Cluster': {
    icon: EKSCluster,
    bgColor: '#ED7100',
  },
  EC2Group: {
    icon: EC2Instance,
    bgColor: '#ED7100',
  },
  'EC2 Group': {
    icon: EC2Instance,
    bgColor: '#ED7100',
  },
  EC2SecurityGroup: {
    icon: EC2Instance,
    bgColor: '#ED7100',
  },
  'EC2 Security Group': {
    icon: EC2Instance,
    bgColor: '#ED7100',
  },
  Cloudtrail: {
    icon: CloudTrail,
    bgColor: '#E7157B',
  },
  AWSLambda: {
    icon: AWSLambda,
    bgColor: '#ED7100',
  },
  'AWS Lambda': {
    icon: AWSLambda,
    bgColor: '#ED7100',
  },
  WizIssue: {
    icon: WizIcon,
    bgColor: '#325FE8',
  },
  'Wiz Issue': {
    icon: WizIcon,
    bgColor: '#325FE8',
  },
  'Orca Issue': {
    icon: OrcaSecurity,
    bgColor: '#0080ff',
  },
  Bright: {
    icon: Bright,
    bgColor: '#0080ff',
  },
  'Invicti Issue': {
    icon: InvictiIcon,
    bgColor: '#E01E5A',
  },
  SQSQueue: {
    icon: SQSQueue,
    bgColor: '#E7157B',
  },
  SNS: {
    icon: SNS,
    bgColor: '#E7157B',
  },
  Cloudformation: {
    icon: Cloudformation,
    bgColor: '#E7157B',
  },
  // GCP
  'GCP Project': {
    icon: GCPProject,
    bgColor: '#85A4E6',
  },
  'GCP Label': {
    icon: GCPLabel,
    bgColor: '#85A4E6',
  },
  'GCP Service Account': {
    icon: GCPServiceAccount,
    bgColor: '#4285f4',
  },
  'GCP Bucket': {
    icon: GCPBucket,
    bgColor: '#aecbfa',
  },
  'GCP VPC': {
    icon: GCPVpc,
    bgColor: '#669df6',
  },
  'GCP Firewall': {
    icon: GCPFirewall,
    bgColor: '#669df6',
  },
  'GCP Instance': {
    icon: GCPInstance,
    bgColor: '#aecbfa',
  },
  'GCP Disk': {
    icon: GCPDisk,
    bgColor: '#aecbfa',
  },
  // k8s
  'K8S Service': {
    icon: Kubernetes,
    bgColor: '#2c63e2',
  },
  'K8S Deployment': {
    icon: Kubernetes,
    bgColor: '#2c63e2',
  },
  'K8S StatefulSet': {
    icon: Kubernetes,
    bgColor: '#2c63e2',
  },
  'K8S DaemonSet': {
    icon: Kubernetes,
    bgColor: '#2c63e2',
  },
  'K8S Ingress': {
    icon: Kubernetes,
    bgColor: '#2c63e2',
  },
  // CI / CD
  'GitHub Actions': {
    icon: GithubActions,
    bgColor: '#474747',
  },
  'GitLab CI/CD': {
    icon: GitlabIcon,
    bgColor: '#474747',
  },
  Jenkins: {
    icon: Jenkins,
    bgColor: '#474747',
  },
  CircleCI: {
    icon: CircleCI,
    bgColor: '#474747',
  },
  'Travis CI': {
    icon: TravisCI,
    bgColor: '#474747',
  },
  'Azure Pipelines': {
    icon: AzurePipelines,
    bgColor: '#474747',
  },
  'Bitbucket Pipelines': {
    icon: BitbucketIcon,
    bgColor: '#474747',
  },
  // misc.
  'Docker Image': {
    icon: Docker,
    bgColor: '#1b58ea',
  },
  Team: {
    icon: Users,
    bgColor: '#474747',
  },
};
