import React, { useEffect, useRef } from 'react';

import styles from './InfiniteScrollLoader.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';

interface InfiniteScrollLoaderProps {
  onIntersect: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const InfiniteScrollLoader: React.FC<InfiniteScrollLoaderProps> = ({
  onIntersect,
  isLoading = false,
  isDisabled = false,
}) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDisabled) return undefined;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !isLoading) {
          onIntersect();
        }
      },
      { threshold: 0.1 },
    );

    const currentRef = loaderRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [onIntersect, isLoading, isDisabled]);

  return (
    <div
      ref={loaderRef}
      className={styles.loader}
    >
      {isLoading && <CircularLoadingSpinner size='small' />}
    </div>
  );
};
