import { FC, useMemo } from 'react';

import { HeaderIcon, HeaderIcons } from 'components/JitCard/JitCard';
import { IntegrationTemplate } from 'context/IntegrationsContext/templates/interfaces';
import { ISvg } from 'types/interfaces';
import { IIntegration, IntegrationProvider, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

// Component for the header icons logic
export const useHeaderIcons = (
  icon: FC<ISvg> | string,
  integrationTemplate: IntegrationTemplate | undefined,
  integrationStatus: IntegrationStatus | undefined,
  statusIcon: FC<ISvg> | undefined,
  integrationFailureReason: IIntegration | undefined,
): HeaderIcons => useMemo(
  () => {
    const iconUrl = integrationTemplate?.logoUrl || (integrationTemplate?.provider && integrationTemplate?.provider !== IntegrationProvider.JIT ? icon as string : undefined);
    const integrationIcon: HeaderIcon = {
      icon: iconUrl ? undefined : icon as FC<ISvg>,
      iconUrl,
    };
    const statusHeaderIcon: HeaderIcon = {
      icon: statusIcon,
      tooltip: integrationFailureReason?.reason,
    };

    return {
      icons: integrationStatus ? [integrationIcon, statusHeaderIcon] : [integrationIcon],
    };
  },
  [icon, integrationFailureReason?.reason, integrationStatus, integrationTemplate?.provider, integrationTemplate?.logoUrl, statusIcon],
);
