import { FC, useCallback, useMemo } from 'react';

import { IntegrationModals } from './components/IntegrationModals';
import { useCustomCardModal } from './hooks/useCustomCardModal';
import { useGetActionButtons } from './hooks/useGetActionButtons';
import { useHeaderIcons } from './hooks/useHeaderIcons';
import { useIntegrationConfiguration } from './hooks/useIntegrationConfiguration';
import { useIntegrationProjects } from './hooks/useIntegrationProjects';
import { useIntegrationStatus } from './hooks/useIntegrationStatus';
import { useProjectsModal } from './hooks/useProjectsModal';
import { useStatusIcon } from './hooks/useStatusIcon';

import { JitCard } from 'components/JitCard/JitCard';
import { IntegrationTemplate, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { i18n } from 'locale/i18n';
import { getVariantTagArray } from 'pages/IntegrationsPage/utils/variantTags';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

interface Props {
  icon: FC<ISvg> | string;
  vendor?: string;
  title?: string;
  description?: string;
  variant: IntegrationVendorType;
  integrations?: IIntegration[];
  testId?: string;
  integrationFileLink?: string;
  isLoading?: boolean;
  integrationTemplate?: IntegrationTemplate;
  isActionHidden?: boolean;
  isNew?: boolean;
  backgroundColor?: string;
}

// Integration card failure reason lookup
const useIntegrationFailureReason = (integrations?: IIntegration[]) => useMemo(
  () => integrations?.find(({
    status,
    reason,
  }) => status === IntegrationStatus.FAILURE && reason),
  [integrations],
);

export const IntegrationCard: FC<Props> = ({
  integrations,
  icon,
  vendor,
  variant,
  testId,
  integrationFileLink,
  isLoading,
  title,
  description,
  integrationTemplate,
  isActionHidden = false,
  backgroundColor,
}) => {
  const { t } = i18n;
  const {
    isScmVendorIntegrated,
    isAnyScmVendorIntegrated,
  } = useTenantContext();

  // Extract configuration and validation logic
  const {
    validation,
    isOpened,
    component,
    action,
    isComingSoon,
  } = useIntegrationConfiguration(integrationTemplate);

  // Get project availability and related data
  const { supportsMultipleProjects, availableProjects } = useIntegrationProjects(integrationTemplate, integrations);

  // Use custom hooks for modal state management
  const { showProjectsModal, toggleProjectsModal } = useProjectsModal();
  const {
    customCardIntegration,
    setCustomCardModalSafely,
    closeCustomCardModal,
  } = useCustomCardModal();

  // Get integration status
  const integrationStatus = useIntegrationStatus(validation, integrations, integrationTemplate);
  const integrationFailureReason = useIntegrationFailureReason(integrations);

  // Button click handler
  const handleButtonClick = useCallback(() => {
    if (action) {
      action();
    } else if (integrationFileLink) {
      window.open(integrationFileLink, '_blank');
    }
  }, [action, integrationFileLink]);

  // Get status icon
  const statusIcon = useStatusIcon(integrationTemplate, integrationStatus, isScmVendorIntegrated);
  const borderColor = integrationStatus === IntegrationStatus.FAILURE ? colors.failRed : undefined;

  // Get header icons
  const headerIcons = useHeaderIcons(icon, integrationTemplate, integrationStatus, statusIcon, integrationFailureReason);

  // Configure action buttons
  const actionButtons = useGetActionButtons({
    isActionHidden,
    integrationTemplate,
    isComingSoon,
    supportsMultipleProjects,
    integrationStatus,
    availableProjects,
    toggleProjectsModal,
    t,
    isAnyScmVendorIntegrated,
    isLoading,
    integrations,
    integrationFileLink,
    action,
    handleButtonClick,
    setShowCustomCardModal: setCustomCardModalSafely,
  });

  // Learn more link configuration
  const learnMoreLink = useMemo(
    () => (integrationTemplate?.link ? {
      text: integrationTemplate?.link?.text || 'cards.buttons.learnMore',
      href: integrationTemplate?.link?.href,
    } : undefined),
    [integrationTemplate?.link],
  );

  return (
    <>
      <IntegrationModals
        closeCustomCardModal={closeCustomCardModal}
        component={component}
        customCardIntegration={customCardIntegration}
        isOpened={isOpened}
        showProjectsModal={showProjectsModal}
        toggleProjectsModal={toggleProjectsModal}
        vendor={vendor || ''}
      />

      <JitCard
        actionButtons={actionButtons}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        description={description || `cards.${vendor}.description`}
        extraContent={null}
        headerIcons={headerIcons}
        isComingSoon={isComingSoon}
        isNew={integrationTemplate?.isNew}
        link={learnMoreLink}
        tags={getVariantTagArray(variant)}
        testid={testId || `cards.${vendor}.title`}
        title={title || `cards.${vendor}.title`}
      />
    </>
  );
};
