import { useCallback } from 'react';

import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { i18n } from 'locale/i18n';
import { Vendor } from 'types/enums';
import { IConnectCredentialConfig } from 'types/interfaces/Integrations/IConnectCredentialConfig';
import { sortWithDefault } from 'utils/functions/sortWithDefault';

/**
 * Project configuration interface used across the application
 */
export interface ProjectConfig {
  id: string;
  name: string;
  externalId?: string;
}

/**
 * Hook to fetch and process project configurations for a specific vendor
 *
 * @param vendor - The vendor to get configurations for (e.g., Jira, Linear)
 * @returns An object containing the projects and loading state
 */
export const useProjectConfigurations = (vendor?: Vendor) => {
  const { user, isLoading } = useParagonAuth();

  const fetchProjects = useCallback(async (): Promise<ProjectConfig[]> => {
    if (!vendor || !user?.integrations?.[vendor]) {
      return [];
    }

    const integration = user.integrations[vendor];
    const configurations = integration?.allConfigurations || {};

    // First cast to unknown, then to array of IConnectCredentialConfig as recommended by TypeScript
    const configValues = Object.values(configurations) as unknown as IConnectCredentialConfig[];

    // Map to our standardized project structure
    const unsortedProjects = configValues.map((config) => ({
      id: config.externalId || 'default',
      name: config.externalId || i18n.t('tickets.defaultProject').toString(),
      externalId: config.externalId,
    }));

    // Sort projects with default if needed
    return sortWithDefault(unsortedProjects, 'default', 'id');
  }, [user, vendor]);

  return {
    fetchProjects,
    isLoading,
  };
};
