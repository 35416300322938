import { memo } from 'react';

import { Highlights } from 'components/MetricDetails/components/Highlights/Highlights';
import { Narration } from 'services/MetricService/types';

interface Props {
  narration: Narration;
  title: string;
  subTitle?: string;
}

export const DrillDownSummary = memo(({ narration, title, subTitle }: Props) => (
  <Highlights
    highlights={narration.insights ?? []}
    improvementAreas={narration.improvement ? [narration.improvement] : []}
    subTitle={subTitle}
    title={title}
  />
));
