import { FC } from 'react';

import { AwsIcon, GithubIcon, JiraIcon, SlackIcon } from 'assets';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { ISvg } from 'types/interfaces';

/**
 * Custom integration types
 */
export type CustomIntegrationType = 'aws' | 'github' | 'slack' | 'jira';

/**
 * Custom integration data
 */
export interface CustomIntegrationData {
  vendor: CustomIntegrationType;
  vendorType: IntegrationVendorType;
  label: string;
  icon: FC<ISvg>;
}

/**
 * Custom integration labels for display
 */
export const CUSTOM_INTEGRATION_LABELS: Record<CustomIntegrationType, string> = {
  github: 'GitHub',
  aws: 'AWS',
  slack: 'Slack',
  jira: 'Jira',
};

/**
 * Custom integration vendor types mapping
 */
export const CUSTOM_INTEGRATION_VENDOR_TYPES: Record<CustomIntegrationType, IntegrationVendorType> = {
  aws: IntegrationVendorType.cloud,
  github: IntegrationVendorType.sourceCodeManagement,
  slack: IntegrationVendorType.collaborationPlatform,
  jira: IntegrationVendorType.tms,
};

/**
 * Custom integration icons mapping
 */
export const CUSTOM_INTEGRATION_ICONS: Record<CustomIntegrationType, FC<ISvg>> = {
  aws: AwsIcon,
  github: GithubIcon,
  slack: SlackIcon,
  jira: JiraIcon,
};

/**
 * Get all custom integrations data
 */
export const getCustomIntegrationsData = (): CustomIntegrationData[] => Object.keys(CUSTOM_INTEGRATION_LABELS).map((vendor) => ({
  vendor: vendor as CustomIntegrationType,
  vendorType: CUSTOM_INTEGRATION_VENDOR_TYPES[vendor as CustomIntegrationType],
  label: CUSTOM_INTEGRATION_LABELS[vendor as CustomIntegrationType],
  icon: CUSTOM_INTEGRATION_ICONS[vendor as CustomIntegrationType],
}));
