import { useCallback, useMemo, useRef, useState } from 'react';

import styles from './Goal.module.scss';

import { JitTextInput } from 'components/JitTextInput/JitTextInput';

interface Props {
  value: number | null;
  onChange: (value: number) => void;
  autoFocus?: boolean;
  placeholder?: string;
}
export const GoalValueInput = ({ value, onChange, autoFocus, placeholder = 'components.metricDetails.addTarget' }: Props) => {
  const [inputValue, setInputValue] = useState(() => value?.toString() ?? '');
  const previousValue = useRef(value);
  if (previousValue.current !== value) {
    setInputValue(value?.toString() ?? '');
    previousValue.current = value;
  }

  const isError = useMemo(() => inputValue === '' || Number.isNaN(Number(inputValue)), [inputValue]);

  const handleBlur = useCallback(() => {
    if (isError) {
      setInputValue(value?.toString() ?? '');
    } else {
      onChange(Number(inputValue));
    }
  }, [isError, value, setInputValue, onChange, inputValue]);

  return (
    <div className={styles.inputWrapper} onBlur={handleBlur}>
      <JitTextInput
        autoFocus={autoFocus}
        className={styles.goalInput}
        isError={isError}
        onChange={setInputValue}
        placeholder={placeholder}
        value={inputValue}
      />
    </div>

  );
};
