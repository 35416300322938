
import { Action } from 'components/MetricDetails/components/Actions/Actions';
import { constants } from 'globalConstants';
import { TimeAgo } from 'types/enums';

export const useShiftLeftActions = (timeFrame: TimeAgo): Action[] => {
  const actions: Action[] = [
    {
      title: 'pages.securityImpactV3.components.shiftLeft.actions.workflowConfiguration.title',
      text: 'pages.securityImpactV3.components.shiftLeft.actions.workflowConfiguration.text',
      label: 'pages.securityImpactV3.components.shiftLeft.actions.workflowConfiguration.label',
      handleClick: () => {
        window.open(`/${constants.routes.WORKFLOWS}`, '_blank');
      },
    },
    {
      title: 'pages.securityImpactV3.components.shiftLeft.actions.ignoredFindingsReview.title',
      text: 'pages.securityImpactV3.components.shiftLeft.actions.ignoredFindingsReview.text',
      label: 'pages.securityImpactV3.components.shiftLeft.actions.ignoredFindingsReview.label',
      handleClick: () => {
        window.open(`/${constants.routes.FINDINGS}?${new URLSearchParams({
          ignored: 'true',
          ...(timeFrame ? { time_ago: timeFrame } : {}),
        }).toString()}`, '_blank');
      },
    },
  ];

  return actions;
};
