import { FC } from 'react';

import { Section } from '../../../Section/Section';
import { Action } from '../../types';

import styles from './MultipleActions.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';

const nbsp = '\u00A0';

interface Props {
  actions: Action[];
}

export const MultipleActions: FC<Props> = ({ actions }) => (
  <Section
    subTitle='components.metricDetails.actions'
  >
    <div className={styles.actionsContainer}>
      {actions.map((action) => (
        <div key={action.text} className={styles.actionItem}>
          <div>
            <JitText bold className={styles.actionTitle} text={action.title ?? nbsp} />

            {action.prediction && (
            <div className={styles.prediction}>
              <JitText size='xs' text={action.prediction} />
            </div>
            )}

            <JitText className={styles.actionLabel} text={action.label} />
          </div>

          <div className={styles.actionFooter}>
            <JitGradientButton onClick={action.handleClick}>
              <JitText bold padding={1} size='s' text={action.text} />
            </JitGradientButton>
          </div>
        </div>

      ))}
    </div>

  </Section>
);
