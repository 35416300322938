import { ArrowDropDown, ArrowDropUp, UnfoldMore } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrgScoreMetric } from '../../types';

import styles from './ScoreBreakdown.module.scss';

import { JitText } from 'components/JitText/JitText';
import { Section } from 'components/MetricDetails/components/Section/Section';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import colors from 'themes/colors.module.scss';

interface SecurityTheme {
  metric: OrgScoreMetric;
  score: number;
  change: number;
  label: string;
}

type SortField = 'label' | 'score' | 'change';
type SortDirection = 'asc' | 'desc' | null;

interface Props {
  themes: SecurityTheme[];
  onMetricClick: (metric: OrgScoreMetric) => void;
}

export const ScoreBreakdown: FC<Props> = ({ themes, onMetricClick }) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<SortField | null>('score');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const handleSort = useCallback((field: SortField) => {
    if (sortField === field) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortField(null);
        setSortDirection(null);
      }
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  }, [sortField, sortDirection]);

  const getSortIcon = useCallback((field: SortField) => {
    if (sortField !== field) return <UnfoldMore fontSize='small' />;
    if (sortDirection === 'asc') return <ArrowDropUp fontSize='small' style={{ color: colors.iris }} />;
    if (sortDirection === 'desc') return <ArrowDropDown fontSize='small' style={{ color: colors.iris }} />;
    return <UnfoldMore fontSize='small' />;
  }, [sortField, sortDirection]);

  const sortedThemes = useMemo(() => [...themes].sort((a, b) => {
    if (!sortField || !sortDirection) return 0;

    const getValue = (theme: SecurityTheme) => {
      switch (sortField) {
        case 'label':
          return theme.label;
        case 'score':
          return theme.score;
        case 'change':
          return theme.change;
        default:
          return '';
      }
    };

    const aValue = getValue(a);
    const bValue = getValue(b);

    if (typeof aValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue as string)
        : (bValue as string).localeCompare(aValue);
    }

    return sortDirection === 'asc'
      ? (aValue as number) - (bValue as number)
      : (bValue as number) - (aValue as number);
  }), [sortField, sortDirection, themes]);

  const renderHeader = useCallback((field: SortField) => (
    <th className={styles.sortable} onClick={() => handleSort(field)}>
      <div className={styles.sortableCell}>
        {t(`pages.securityImpact.orgScore.scoreBreakdown.${field}`)}

        {getSortIcon(field)}
      </div>
    </th>
  ), [getSortIcon, handleSort, t]);

  return (
    <Section section='components.metricDetails.metricInfo' title='pages.securityImpact.orgScore.scoreBreakdown.subtitle'>
      <table className={styles.table}>
        <thead>
          <tr>
            {renderHeader('label')}

            {renderHeader('score')}

            {renderHeader('change')}
          </tr>
        </thead>

        <tbody>
          {sortedThemes.map((theme) => (
            <tr key={theme.metric}>
              <td>
                <JitText onClick={() => onMetricClick(theme.metric)} role='button' size='m' text={theme.label} />
              </td>

              <td>
                <JitText size='m' text={`${theme.score?.toFixed(0)}%`} />

              </td>

              <td>
                <PercentageChip displayBorder percentage={Number(theme.change?.toFixed(0))} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Section>
  );
};
