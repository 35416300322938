import { GenericMetric } from './types';

import { TimeAgo } from 'types/enums';

export const base = ['metrics'];

const metricBase = [...base, 'metric'];

export const thresholds = [...base, 'thresholds'];

export const mttr = (range: TimeAgo) => [...metricBase, 'mttr', range];

export const dateBasedMetricBase = (name: GenericMetric) => [...metricBase, 'dateBased', name];

export const dateBasedMetric = (name: GenericMetric, range: TimeAgo) => [...dateBasedMetricBase(name), range];

export const teamsScoreTrending = (range: TimeAgo) => [...metricBase, 'teams_score_trending', range];

export const teamTrend = (teamId: string) => [...metricBase, 'team_trend', teamId];

export const resourcesCoverage = [...metricBase, 'resources_coverage'];
