import { FC } from 'react';

import { ISvg } from 'types/interfaces';

/**
 * A simple placeholder icon component that renders an empty div
 * Used as a fallback when we need to provide an icon component but the actual icon
 * will be determined elsewhere or isn't needed
 */
export const PlaceholderIcon: FC<ISvg> = () => <div />;
