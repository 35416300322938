import { useGetColumns } from '../useGetColumns';
import { addCurrentTeamIfNotInTop10, assignRanksToTeams } from '../utils/utils';

import { useGetTableInstance, ITableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { useGetScoreTrendingByWeek } from 'components/ScoreTrendingWidget/hooks/useGetScoreTrendingByWeek';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';

interface UseLeaderboardTableProps {
  teams: IGetTeamResponseItem[];
  currentTeam?: IGetTeamResponseItem;
}

export const useLeaderboardTable = ({
  teams,
  currentTeam,
}: UseLeaderboardTableProps): {
  tableInstance: ITableInstance;
  isLoading: boolean;
} => {
  let teamsWithRank: IGetTeamResponseItem[] = assignRanksToTeams(teams);
  teamsWithRank = addCurrentTeamIfNotInTop10(teamsWithRank, currentTeam);
  const teamIds = teamsWithRank.map((team) => team.id);
  const { scoreTrending, isLoading } = useGetScoreTrendingByWeek(teamIds);

  teamsWithRank = teamsWithRank.map((team) => ({
    ...team,
    scoreTrending: isLoading ? null : scoreTrending?.find((score) => score.entityId === team.id),
  }));

  const columns = useGetColumns();
  const tableInstance = useGetTableInstance(columns, teamsWithRank);

  return { tableInstance,
    isLoading };
};
