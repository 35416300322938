/* eslint-disable react/jsx-max-depth */
import { t } from 'i18next';
import { FC } from 'react';

import styles from './Loading.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';

export const Loading: FC = () => (
  <div className={styles.wrapper} data-testid='loading-skeleton'>
    <div className={styles.leftSection}>
      <div className={styles.graphContainer}>
        <div className={styles.scoreHeader}>
          <JitText
            bold
            size='l'
            text={t('pages.securityImpact.orgScore.title').toString()}
          />

          <LoadingSkeleton
            data-testid='score-skeleton'
            style={{ width: '80px',
              height: '40px' }}
          />
        </div>

        <div className={styles.scoreHistory}>
          <h3>{t('pages.securityImpact.orgScore.scoreHistory').toString()}</h3>

          <div className={styles.graph}>
            <LoadingSkeleton
              data-testid='graph-skeleton'
              style={{ height: '140px',
                borderRadius: '4px' }}
            />
          </div>
        </div>
      </div>
    </div>

    <div className={styles.rightSection}>
      <div className={styles.rightSectionContent}>
        <LoadingSkeleton
          data-testid='improvement-skeleton'
          style={{ width: '120px',
            height: '32px',
            borderRadius: '6px' }}
        />

        <LoadingSkeleton
          data-testid='header-skeleton'
          style={{ width: '240px',
            height: '32px' }}
        />

        <div className={styles.trendsList}>
          {[1, 2].map((index) => (
            <div key={index} className={styles.trendItem} data-testid='trend-item-skeleton'>
              <LoadingSkeleton style={{ width: '24px',
                height: '24px',
                borderRadius: '6px' }}
              />

              <LoadingSkeleton style={{ width: '160px',
                height: '24px' }}
              />
            </div>
          ))}
        </div>
      </div>

      <LoadingSkeleton
        data-testid='button-skeleton'
        style={{ width: '120px',
          height: '24px' }}
      />
    </div>
  </div>
);
