import { Action } from 'components/MetricDetails/components/Actions/Actions';
import { constants } from 'globalConstants';
import { TimeAgo } from 'types/enums';

export const useFalsePositiveReductionActions = (timeFrame: TimeAgo): Action[] => {
  const actions: Action[] = [
    {
      text: 'pages.securityImpactV3.components.falsePositiveReduction.actions.viewResources.text',
      label: 'pages.securityImpactV3.components.falsePositiveReduction.actions.viewResources.label',
      title: 'pages.securityImpactV3.components.falsePositiveReduction.actions.viewResources.title',
      handleClick: () => {
        window.open(`/${constants.routes.RESOURCES}`, '_blank');
      },
    },
    {
      text: 'pages.securityImpactV3.components.falsePositiveReduction.actions.reviewHighPriorityFindings.text',
      label: 'pages.securityImpactV3.components.falsePositiveReduction.actions.reviewHighPriorityFindings.label',
      title: 'pages.securityImpactV3.components.falsePositiveReduction.actions.reviewHighPriorityFindings.title',
      handleClick: () => {
        window.open(`/${constants.routes.FINDINGS}?${new URLSearchParams({
          priority_score: '70,100',
          resolution: 'OPEN',
          ignored: 'false',
          ...(timeFrame ? { time_ago: timeFrame } : {}),
        })}`, '_blank');
      },
    },
  ];

  return actions;
};
