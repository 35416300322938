import { useState, useRef, useCallback } from 'react';

import { IIntegration } from 'types/interfaces/Integrations/IIntegration';

/**
 * Hook to manage custom card integration modal state
 */
export const useCustomCardModal = () => {
  const [customCardIntegration, setCustomCardIntegration] = useState<IIntegration | null>(null);
  // Use ref to prevent duplicate setting of modal state
  const modalOpenedRef = useRef(false);

  const setCustomCardModalSafely = useCallback((integration: IIntegration) => {
    if (!modalOpenedRef.current) {
      modalOpenedRef.current = true;
      setCustomCardIntegration(integration);
    }
  }, []);

  const closeCustomCardModal = useCallback(() => {
    setCustomCardIntegration(null);
    // Reset the ref immediately instead of using a timeout
    modalOpenedRef.current = false;
  }, []);

  return {
    customCardIntegration,
    setCustomCardModalSafely,
    closeCustomCardModal,
  };
};
