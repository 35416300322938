import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Section.module.scss';

import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';

interface Props {
  section?: string;
  title?: string;
  subTitle?: string;
  children: ReactNode;
}

export const Section: FC<Props> = ({ section, title, subTitle, children }) => {
  const { t } = useTranslation();

  return (
    <JitSidePanelItem title={section ?? ''}>
      {title && <h2 className={styles.title}>{t(title)}</h2>}

      {subTitle && <p className={styles.subTitle}>{t(subTitle)}</p>}

      {children}
    </JitSidePanelItem>
  );
};
