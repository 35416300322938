/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */

import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';

import { useAggregateWeeklyData } from '../../hooks/useAggregateWeeklyData';

import { Loading } from './components/Loading/Loading';
import { ScoreBreakdown } from './components/ScoreBreakdown/ScoreBreakdown';
import { Trends } from './components/Trends/Trends';
import styles from './OrgScore.module.scss';
import { OrgScoreMetric } from './types';
import { calculateMetricScores } from './utils';

import { JitSidePanel } from 'components/JitSidePanel/JitSidePanel';
import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { JitText } from 'components/JitText/JitText';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import { ScoreTrendingGraph } from 'components/ScoreTrendingGraph/ScoreTrendingGraph';
import { useDateBasedMetric, useOrgScoreTrending } from 'services/MetricService/hooks';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';

interface Props {
  timeRange: TimeAgo;
  onMetricClick: (metric: OrgScoreMetric) => void;
}

export const OrgScore: FC<Props> = ({ timeRange, onMetricClick }) => {
  const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);
  const { data: mttrData, isLoading: isLoadingMTTR } = useDateBasedMetric('mttr_overall_threshold_compliance', timeRange);
  const { data: shiftLeftData, isLoading: isLoadingShiftLeft } = useDateBasedMetric('shift_left_score', timeRange);
  const { data: prioritizedFindingsData, isLoading: isLoadingPrioritizedFindings } = useDateBasedMetric('prioritized_findings_score', timeRange);
  const { data: riskRemediationRateData, isLoading: isLoadingRiskRemediationRate } = useDateBasedMetric('risk_remediation_rate', timeRange);
  const { data: criticalAssetSecurityData, isLoading: isLoadingCriticalAssetSecurity } = useDateBasedMetric('critical_asset_security', timeRange);
  const { data: resourcesCoverageData, isLoading: isLoadingResourcesCoverage } = useDateBasedMetric('total_asset_coverage', timeRange);
  const { data: orgScoreTrendingData, isLoading: isLoadingOrgScoreTrending } = useOrgScoreTrending(timeRange);

  const isLoading = isLoadingMTTR || isLoadingShiftLeft || isLoadingPrioritizedFindings
    || isLoadingRiskRemediationRate || isLoadingResourcesCoverage
    || isLoadingCriticalAssetSecurity || isLoadingOrgScoreTrending;

  const weeklyAggregatedMTTRData = useAggregateWeeklyData(mttrData, timeRange);
  const weeklyAggregatedShiftLeftData = useAggregateWeeklyData(shiftLeftData, timeRange);
  const weeklyAggregatedPrioritizedFindingsData = useAggregateWeeklyData(prioritizedFindingsData, timeRange);
  const weeklyAggregatedRiskRemediationRateData = useAggregateWeeklyData(riskRemediationRateData, timeRange);
  const weeklyAggregatedCriticalAssetSecurityData = useAggregateWeeklyData(criticalAssetSecurityData, timeRange);
  const weeklyAggregatedResourcesCoverageData = useAggregateWeeklyData(resourcesCoverageData, timeRange);

  const { currentScore, scoreChange, trends, scoreTrendingEntity, metricScores } = useMemo(() => calculateMetricScores(
    {
      mttr: weeklyAggregatedMTTRData,
      shiftLeft: weeklyAggregatedShiftLeftData,
      prioritizedFindings: weeklyAggregatedPrioritizedFindingsData,
      resourcesCoverage: weeklyAggregatedResourcesCoverageData,
      riskRemediation: weeklyAggregatedRiskRemediationRateData,
      criticalAssetSecurity: weeklyAggregatedCriticalAssetSecurityData,
    },
    orgScoreTrendingData,
  ), [
    weeklyAggregatedMTTRData,
    weeklyAggregatedShiftLeftData,
    weeklyAggregatedPrioritizedFindingsData,
    weeklyAggregatedResourcesCoverageData,
    weeklyAggregatedRiskRemediationRateData,
    weeklyAggregatedCriticalAssetSecurityData,
    orgScoreTrendingData,
  ]);

  const percentageChipSuffix = useMemo(
    () => (scoreChange > 0
      ? t('pages.securityImpact.orgScore.improved').toString()
      : scoreChange < 0
        ? t('pages.securityImpact.orgScore.declined').toString()
        : t('pages.securityImpact.orgScore.unchanged').toString()),
    [scoreChange],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.leftSection}>
          <div className={styles.graphContainer}>
            <div className={styles.scoreHeader}>
              <JitText
                text='pages.securityImpact.orgScore.title'
              />

              <div className={styles.score} data-testid='org-score'>
                <span>{Number.isNaN(currentScore) ? 0 : currentScore}</span>

                <span>%</span>
              </div>
            </div>

            <div className={styles.scoreHistory}>
              <JitText
                color='grey'
                size='s'
                style={{
                  marginBottom: '5px',
                }}
                text={t('pages.securityImpact.orgScore.scoreHistory').toString()}
              />

              <div className={styles.graph} data-testid='score-trending-graph'>
                <ScoreTrendingGraph
                  mode='full'
                  scoreTrendingEntity={scoreTrendingEntity}
                  threshold={80}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.rightSectionContent}>
            <div className={styles.chipWrapper}>

              <PercentageChip displayBorder percentage={scoreChange} suffix={percentageChipSuffix} />
            </div>

            <JitText bold component='h6' size='xxl' text='pages.securityImpact.orgScore.header' />

            <Trends trends={trends} />
          </div>

          <JitText
            className={styles.actionLink}
            color={colors.iris}
            marginBottom={1}
            onClick={() => setIsBreakdownOpen(true)}
            role='button'
            text='pages.securityImpact.orgScore.viewKeyDrivers'
          />

        </div>
      </div>

      <JitSlidingPanel
        closePanel={() => setIsBreakdownOpen(false)}
        isOpen={isBreakdownOpen}
      >
        <JitSidePanel closePanel={() => setIsBreakdownOpen(false)} title={t('pages.securityImpact.orgScore.scoreBreakdown.title')}>
          <ScoreBreakdown onMetricClick={onMetricClick} themes={metricScores} />
        </JitSidePanel>
      </JitSlidingPanel>
    </>
  );
};
