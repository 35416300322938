import { CheckCircleOutline, ErrorOutline, NotificationsOutlined, InfoOutlined } from '@mui/icons-material';
import { FC } from 'react';

import { Section } from '../Section/Section';

import styles from './Highlights.module.scss';

import { JitText } from 'components/JitText/JitText';
import { NarrationInsight } from 'services/MetricService/types';
import colors from 'themes/colors.module.scss';

interface Props {
  highlights: NarrationInsight[];
  improvementAreas: string[];
  title: string;
  subTitle?: string;
}

export const Highlights: FC<Props> = ({ highlights, improvementAreas, title, subTitle }) => (
  <Section section='components.metricDetails.metricInfo' title={title}>
    <JitText marginTop={-1.5} text={subTitle} />

    <div className={styles.highlights}>
      {highlights.map((highlight) => (
        <div key={highlight.text} className={styles.highlight}>
          {highlight.sentiment === 'positive' ? (
            <CheckCircleOutline className={`${styles.highlightIcon} ${styles.successIcon}`} />
          ) : highlight.sentiment === 'negative' ? (
            <ErrorOutline className={`${styles.highlightIcon} ${styles.errorIcon}`} />
          ) : (
            <InfoOutlined className={`${styles.highlightIcon} ${styles.neutralIcon}`} />
          )}

          <p>{highlight.text}</p>
        </div>
      ))}

      <div className={styles.improvementArea}>
        <JitText color={colors.lightGray} component='h4' text='components.metricDetails.improvementArea' />

        <div className={styles.improvementList}>
          {improvementAreas.map((area) => (
            <div key={area} className={styles.improvementContent}>
              <NotificationsOutlined fontSize='small' />

              <p>{area}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Section>
);
