import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { i18n } from 'locale/i18n';
import { Vendor } from 'types/enums';
import { IIntegration } from 'types/interfaces/Integrations/IIntegration';
import { sortWithDefault } from 'utils/functions/sortWithDefault';

interface VendorProject {
  key: string;
  name: string;
}

interface UseVendorProjectsResult {
  vendorProjects: VendorProject[];
  hasMultipleProjects: boolean;
  isVendorIntegration: boolean;
  activeIntegration: IIntegration | undefined;
}

export const useVendorProjects = (integration: IIntegration | undefined): UseVendorProjectsResult => {
  const { user } = useParagonAuth();

  const vendor = integration?.vendor as Vendor;
  const isVendorIntegration = !!integration?.vendor;

  const vendorProjects = !isVendorIntegration || !user?.integrations?.[vendor] ? []
    : (() => {
      const vendorIntegration = user.integrations[vendor];
      if (!vendorIntegration) return [];
      const vendorConfigurations = vendorIntegration.allConfigurations || {};

      const unsortedProjects = Object.values(vendorConfigurations).map((config) => ({
        key: config.externalId || 'default',
        name: config.externalId || i18n.t('tickets.defaultProject').toString(),
      }));

      return sortWithDefault(unsortedProjects);
    })();

  const hasMultipleProjects = isVendorIntegration && vendorProjects.length > 1;

  return {
    vendorProjects,
    hasMultipleProjects,
    isVendorIntegration,
    activeIntegration: integration,
  };
};
