import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IGithubStatusAlert } from 'types/enums/GithubStatus';
import { IInstallation } from 'types/interfaces';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

const serviceName = 'github';

const suggestedRepositoriesParamValue = 'suggested';

export interface FetchRespositoriesParams {
  suggestedRepos: boolean;
  page?: number;
  limit?: number;
  search?: string;
}

export interface FetchMembersParams {
  user_name: string;
  limit: number;
  after?: string;
}

export interface UpdateInstallationRepositoriesParams {
  allow_all_repositories?: boolean;
  repositories?: {
    id: string;
    name: string;
  }[];
}

export interface IRepository {
  id: string;
  name: string;
  fullName: string;
}

export const useGithubService = () => {
  const { client } = useClient();

  const fetchStatus = useCallback(async () => {
    const url = `${serviceName}/github-status`;
    const response = await client.get<IGithubStatusAlert>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      return response.data;
    }
    throw new Error('Error fetching github status');
  }, [client]);

  const fetchMembers = useCallback(async (params: FetchMembersParams) => {
    const url = getApiUrls.githubService.getMembers();
    const response = await client.get<IPaginatedResponse<string>>({
      url,
      allowedStatuses: [200],
      requestConfig: { params },
    });
    return response;
  }, [client]);

  const fetchSCMResources = useCallback(
    async (params: FetchRespositoriesParams): Promise<IRepository[]> => {
      const url = `${serviceName}/repos`;
      const { suggestedRepos, ...restParams } = params;
      let ghParams = {};
      if (params.suggestedRepos) {
        ghParams = {
          sort_by: suggestedRepositoriesParamValue,
        };
      } else {
        ghParams = restParams;
      }
      const response = await client.get<{ repositories: IRepository[] }>({
        url,
        allowedStatuses: [200],
        requestConfig: { params: ghParams },
      });
      return camelizeSnakeCaseKeys(response?.data ?? []) as IRepository[];
    },
    [client],
  );

  const updateInstallationSCMResources = useCallback(
    async (body: UpdateInstallationRepositoriesParams) => {
      const url = `${serviceName}/assets`;
      const response = await client.post<IInstallation>({
        url,
        allowedStatuses: [202],
        requestConfig: { body },
      });
      return response;
    },
    [client],
  );

  return {
    fetchStatus,
    fetchMembers,
    fetchSCMResources,
    updateInstallationSCMResources,
  };
};
