import { ReactNode, useMemo } from 'react';

import { BaseProps } from '../../types';

import { getColor } from 'components/Chart/utils';
import { BarConfig, BarData, JitBarChart } from 'components/graphs/BarChart/JitBarChart';
import { BarChartData } from 'services/MetricService/types';

interface BarChartProps<XField extends string, YFields extends string> extends BarChartData<XField, YFields>, BaseProps {
  children?: ReactNode;
}

export const BarChart = <XField extends string = string, YFields extends string = string>({
  data,
  xField,
  yFields,
  label,
  height,
  children,
}: BarChartProps<XField, YFields>) => {
  const bars = useMemo((): BarConfig[] => yFields.map((yField, idx) => ({
    key: yField,
    label: label[yField],
    color: getColor(idx),
  })), [yFields, label]);

  const barData = useMemo((): BarData[] => data.map((item) => ({
    name: item[xField],
    ...Object.fromEntries(yFields.map((field) => [field, item[field]])),
  })), [data, xField, yFields]);

  return (
    <JitBarChart
      bars={bars}
      data={barData}
      height={height}
      xAxisInterval='preserveEnd'
    >
      {children}
    </JitBarChart>
  );
};
