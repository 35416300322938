import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useIntegrationsService } from '../IntegrationsService/useIntegrationService';

import { client } from './useParagonClient';

import { Queries } from 'types/enums/Queries';

export const useParagonService = () => {
  const { getParagonEnv } = useIntegrationsService();
  const { data: paragonEnv } = useQuery(
    Queries.ParagonEnv,
    getParagonEnv,
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  );
  if (!paragonEnv?.data) {
    throw new Error('Failed to get Paragon environment');
  }

  const { token, projectId } = paragonEnv.data;

  const connectUserThroughProxy = async (payload: Record<string, string>, integrationId: string) => {
    const data = {
      integrationId,
      config: {},
      payload,
      installOptions: {},
    };
    const path = `/projects/${projectId}/sdk/credentials`;

    try {
      const response = await client.post(`${path}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Return an object with standard structure including status and data
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      // Catch axios errors and rethrow with formatted message
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.data && typeof axiosError.response.data === 'object') {
          const responseData = axiosError.response.data as { message?: string; meta?: { info?: string } };
          const errorMessage = responseData.message || 'Connection failed: Unknown error';
          throw new Error(`${errorMessage}`);
        }
      }
      // Rethrow the original error if it's not an axios error or doesn't have a structured response
      throw error;
    }
  };

  return {
    connectUserThroughProxy,
  };
};
