
import styles from './TeamsSummary.module.scss';

import { JitTable } from 'components/JitTable/JitTable';
import { useLeaderboardTable } from 'pages/TeamsPortal/LeaderboardPage/components/LeaderboardTable/hooks/useLeaderboardTable';
import { useTeamsInfiniteQuery } from 'services/TeamsService/hooks';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

export const TeamsSummary = () => {
  const { data: teamsData, isLoading: isLoadingTeams } = useTeamsInfiniteQuery(
    {
      sortBy: TeamSortBy.SCORE,
      sortOrder: TeamSortOrder.DESC,
      limit: 3,
      displayImage: true,
      displayMembers: true,
    },
  );

  const { tableInstance } = useLeaderboardTable({ teams: teamsData?.pages?.[0]?.data ?? [] });

  return (
    <div className={styles.container}>

      <JitTable
        isLoading={isLoadingTeams}
        showPagination={false}
        skeletonRows={3}
        tableInstance={tableInstance}
      />

    </div>
  );
};
