import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const useGetSelectProjectDataComponent = (isActivated: boolean) => {
  const text = isActivated
    ? 'pages.teamsPortal.guidePage.configured'
    : 'pages.teamsPortal.guidePage.notConfigured';

  const color = isActivated ? colors.successGreen02 : colors.lightGray;

  const dataComponent = (
    <JitText
      color={color}
      size='m'
      text={text}
    />
  );

  return ({ dataComponent });
};
