import { FC } from 'react';

import { MultipleActions } from './components/MultipleActions/MultipleActions';
import { Recommendations } from './components/Recommendations/Recommendations';
import type { Action } from './types';

interface Props {
  actions: Action[];
}

export const Actions: FC<Props> = ({ actions }) => {
  if (actions.length === 0) {
    return null;
  }

  if (actions.length === 1) {
    return <Recommendations actions={actions} />;
  }

  return <MultipleActions actions={actions} />;
};

export type { Action };
