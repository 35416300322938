import { useMemo } from 'react';

import { DateBasedChartData } from 'services/MetricService/types';

export const useValueAndTrend = (data?: DateBasedChartData) => {
  const dataKey = useMemo(() => {
    const lastDataPoint = data?.data.at(-1);
    if (!lastDataPoint) {
      return null;
    }
    const value = Object.keys(lastDataPoint).find((key) => key !== 'date');

    if (!value) {
      return null;
    }

    return value as keyof typeof lastDataPoint;
  }, [data?.data]);

  const filteredData = useMemo(() => (dataKey ? data?.data.filter((dt) => dt[dataKey] !== null) : data?.data), [dataKey, data?.data]);

  const formattedValue = useMemo(() => {
    if (!dataKey) {
      return '-';
    }
    return `${filteredData?.at(-1)?.[dataKey]?.toFixed(0) ?? '-'}%`;
  }, [dataKey, filteredData]);

  const trend = useMemo(() => {
    if (!dataKey) {
      return undefined;
    }
    return (filteredData?.at(-1)?.[dataKey] ?? 0) - (filteredData?.at(0)?.[dataKey] ?? 0);
  }, [dataKey, filteredData]);

  return { formattedValue,
    trend };
};
