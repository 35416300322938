import { IntegrationTemplate } from 'context/IntegrationsContext/templates/interfaces';
import { useAddSecretDialog } from 'pages/IntegrationsPage/hooks/useAddSecretDialog/useAddSecretDialog';
import { IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

interface IntegrationActionHooks {
  component?: JSX.Element;
  isOpened: boolean;
  action: () => void;
  validation: (x: string | undefined) => IntegrationStatus | undefined;
}

type CustomHook = (integration?: IntegrationTemplate) => IntegrationActionHooks;

interface IntegrationActions {
  onConnect: CustomHook;
  onConfigure: CustomHook;
}

interface IntegrationTypeToActionMap {
  secret: IntegrationActions;
}

const integrationTypeToActionMap: IntegrationTypeToActionMap = {
  secret: {
    onConnect: useAddSecretDialog,
    onConfigure: useAddSecretDialog,
  },
};

export const useIntegrationConfiguration = (integrationTemplate?: IntegrationTemplate) => {
  const {
    validation,
    isOpened,
    component,
    action,
  } = integrationTypeToActionMap[
    integrationTemplate?.integrationType as keyof typeof integrationTypeToActionMap]?.onConnect(integrationTemplate) || {};

  const isComingSoon = integrationTemplate?.integrationType === 'comingSoon';

  return {
    validation,
    isOpened,
    component,
    action,
    isComingSoon,
  };
};
