import React, { useMemo } from 'react';

import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import { useTeamTrendQuery } from 'services/MetricService/hooks';
import { calculateTeamTrend } from 'services/TeamsService/utils';

interface TeamTrendProps {
  teamId: string;
}

export const TeamTrend: React.FC<TeamTrendProps> = ({ teamId }) => {
  const { data: trendValue, isLoading, isError } = useTeamTrendQuery(teamId);

  const trend = useMemo(() => trendValue && calculateTeamTrend(trendValue, teamId), [trendValue, teamId]);

  if (isLoading) {
    return (
      <LoadingSkeleton
        style={{
          width: 60,
          height: 24,
        }}
      />
    );
  }

  if (isError || typeof trend !== 'number') {
    // When there's an error or no data, display a zero trend
    return <PercentageChip displayBorder percentage={0} />;
  }

  return <PercentageChip displayBorder percentage={trend} />;
};
