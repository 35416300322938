import { useState, useCallback } from 'react';

/**
 * Hook to manage projects modal state
 */
export const useProjectsModal = () => {
  const [showProjectsModal, setShowProjectsModal] = useState(false);

  const toggleProjectsModal = useCallback(() => {
    setShowProjectsModal((prev) => !prev);
  }, []);

  return {
    showProjectsModal,
    toggleProjectsModal,
  };
};
