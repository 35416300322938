import { EntityDaysScore, ScoreTrendMetric, DayScore } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';

/**
 * Calculates the trend value from score trend metric data for a specific team
 * @param response The response from the score trend metric API
 * @param teamId The ID of the team to calculate trend for
 * @returns Calculated trend value or null/0 if insufficient data
 */
export const calculateTeamTrend = (
  response: ScoreTrendMetric | undefined | null,
  teamId: string,
): number | null => {
  // Return null if no data is available
  if (!response?.entitiesScores?.length) {
    return null;
  }

  // Find the entity data for the requested team
  const teamData = response.entitiesScores.find(
    (entity: EntityDaysScore) => entity.entityId === teamId,
  );
  if (!teamData?.scoresByDay?.length) {
    return null;
  }

  // Filter out entries with null scores
  const validScores = teamData.scoresByDay.filter(
    (day: DayScore) => day.score !== null,
  );
  if (validScores.length < 2) {
    return 0; // No trend if we don't have at least 2 valid scores
  }

  // Calculate trend as the difference between the latest and earliest scores
  const latestScore = validScores.at(-1)?.score || 0;
  const earliestScore = validScores.at(-2)?.score || 0;

  return latestScore - earliestScore;
};
