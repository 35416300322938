import { useCallback } from 'react';
import { useQuery, useMutation } from 'react-query';

import { useIntegrationsContext } from 'context/IntegrationsContext/IntegrationsContext';
import { useIntegrationsService } from 'services/IntegrationsService/useIntegrationService';
import { useParagonService } from 'services/ParagonService/useParagonService';
import { Queries } from 'types/enums/Queries';
import { MappingValue } from 'types/interfaces/Integrations/IIntegration';

// Note: Recent changes made to properly handle MappingValue types and fix errors with status response handling
// - Added convertMappingToStringRecord function to convert MappingValue to strings
// - Fixed error handling to check for both HTTP status codes and status in response data
// - Removed direct axios calls in favor of using the paragonService

/**
 * Process all mapping values by replacing field references with actual values
 * @param mappings Mapping object from integration configuration
 * @param formValues User entered form values
 * @returns Processed mapping object with substituted values
 */
const processPayloadMappings = (
  mappings: Record<string, MappingValue>,
  formValues: Record<string, string>,
): Record<string, MappingValue> => {
  if (!mappings) return {};

  const result: Record<string, MappingValue> = {};

  // Process each key in the mapping object
  Object.entries(mappings).forEach(([key, value]) => {
    if (typeof value === 'string') {
      // Handle cases with ${FIELD_NAME} format (support multiple substitutions)
      if (value.includes('${')) {
        // Replace all occurrences of ${FIELD_NAME} with the corresponding form value
        const processedValue = value.replace(/\${([^}]+)}/g, (match, fieldName) => formValues[fieldName] || '');

        result[key] = processedValue;
      } else {
        result[key] = value;
      }
    } else if (typeof value === 'object' && value !== null) {
      // Recursively process nested objects
      result[key] = processPayloadMappings(value as Record<string, MappingValue>, formValues);
    } else {
      // Keep non-string values as is
      result[key] = value;
    }
  });

  return result;
};

/**
 * Convert MappingValue objects to string where possible
 * @param mappings Mapping object processed from configuration
 * @returns Record with string values where possible
 */
const convertMappingToStringRecord = (
  mappings: Record<string, MappingValue>,
): Record<string, string> => {
  const result: Record<string, string> = {};

  Object.entries(mappings).forEach(([key, value]) => {
    // Convert to string if it's a primitive type
    if (value === null) {
      result[key] = '';
    } else if (typeof value !== 'object') {
      result[key] = String(value);
    }
    // Complex objects are skipped to comply with the expected string type
  });

  return result;
};

/**
 * Hook for connecting to Paragon proxy service
 */
export const useParagonProxy = () => {
  const { getParagonEnv } = useIntegrationsService();
  const { fetchIntegrations } = useIntegrationsContext();
  const paragonService = useParagonService();

  // Use useQuery to fetch Paragon environment
  const { data: paragonEnv } = useQuery(
    Queries.ParagonEnv,
    getParagonEnv,
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  );

  /**
   * Prepare payload for Paragon API request
   */
  const preparePayload = useCallback(
    (
      mapping: Record<string, MappingValue> | undefined,
      formValues: Record<string, string>,
    ): Record<string, MappingValue> => (mapping ? processPayloadMappings(mapping, formValues) : { token: Object.values(formValues)[0] || '' }),
    [],
  );

  /**
   * Connect to Paragon proxy with form credentials
   */
  const { mutate: connectToParagonProxy, isLoading } = useMutation(
    async ({
      integrationId,
      formValues,
      mapping,
    }: {
      integrationId: string;
      formValues: Record<string, string>;
      mapping?: Record<string, MappingValue>;
    }) => {
      if (!paragonEnv?.data) {
        throw new Error('Failed to get Paragon environment');
      }

      // Prepare the payload
      const payload = preparePayload(mapping, formValues);

      // Verify payload is not empty
      if (Object.keys(payload).length === 0) {
        throw new Error('Missing integration credentials');
      }

      // Convert MappingValue to string records as required by Paragon service
      const stringPayload = convertMappingToStringRecord(payload);

      // Send request to Paragon proxy
      const response = await paragonService.connectUserThroughProxy(stringPayload, integrationId);

      // Check if response contains data and has a valid HTTP status
      if (!response.data || (response.status !== 200 && response.status !== 201)) {
        // If the response data has a status field and it's not "SUCCESS" or similar positive status,
        // use it in the error message
        if (response.data && typeof response.data === 'object' && 'status' in response.data) {
          throw new Error(`Request failed with message: ${response.data.status}`);
        }
        throw new Error(`Request failed with status: ${response.status}`);
      }

      return response;
    },
    {
      // Default success handler to refresh integrations
      onSuccess: () => {
        fetchIntegrations(true);
      },
    },
  );

  return {
    connectToParagonProxy,
    isLoading,
  };
};
