import { paragon } from '@useparagon/connect';
import { useMemo } from 'react';

import { InnerOptions, IntegrationTemplate, IntegrationType } from 'context/IntegrationsContext/templates/interfaces';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

// Define types properly to avoid linting issues
interface ActionButtonsProps {
  isActionHidden: boolean;
  integrationTemplate?: IntegrationTemplate;
  isComingSoon: boolean;
  supportsMultipleProjects: boolean;
  integrationStatus?: IntegrationStatus;
  availableProjects: Array<{ id: string; name: string }>;
  toggleProjectsModal: () => void;
  t: (key: string, options?: Record<string, unknown>) => string;
  isAnyScmVendorIntegrated: boolean;
  isLoading?: boolean;
  integrations?: IIntegration[];
  integrationFileLink?: string;
  action?: () => void;
  handleButtonClick: () => void;
  setShowCustomCardModal?: (integration: IIntegration) => void;
}

// Make sure this function is defined before it's used
const getParagonHandleClick = (
  relatedIntegration: IIntegration | undefined,
  integrationTemplate: IntegrationTemplate,
  integrationStatus: IntegrationStatus | undefined,
  setShowCustomCardModal?: (integration: IIntegration) => void,
) => {
  // Basic handle click function for default case
  let handleClick = () => paragon.connect(integrationTemplate?.key, {});

  // Check if integration is already connected
  const isIntegrated = integrationStatus === IntegrationStatus.SUCCESS;

  // If the integration has a custom card AND is not connected,
  // show our own modal instead of Paragon's UI
  if (relatedIntegration?.display?.card && !isIntegrated && setShowCustomCardModal) {
    return () => setShowCustomCardModal(relatedIntegration);
  }

  // If this is a headless config integration and not yet connected,
  // use the installIntegration method
  if (relatedIntegration?.display?.extras?.headlessConfig && !isIntegrated) {
    handleClick = async () => paragon.installIntegration(integrationTemplate?.key, {});
  }

  // Return the appropriate click handler
  return handleClick;
};

export const useGetActionButtons = ({
  isActionHidden,
  integrationTemplate,
  isComingSoon,
  supportsMultipleProjects,
  integrationStatus,
  availableProjects,
  toggleProjectsModal,
  t,
  isAnyScmVendorIntegrated,
  isLoading,
  integrations,
  integrationFileLink,
  action,
  handleButtonClick,
  setShowCustomCardModal,
}: ActionButtonsProps): ActionButton[] => useMemo(() => {
  if (isActionHidden) {
    return [];
  }

  if (integrationTemplate?.integrationType === IntegrationType.securityTool) {
    return [];
  }

  // For coming soon integrations, return empty array to remove the button
  if (isComingSoon) {
    return [];
  }

  // If this is a multi-project integration with connected status, show manage projects button
  if (supportsMultipleProjects && integrationStatus === IntegrationStatus.SUCCESS) {
    return [{
      label: t('handleFixSuggestion.multiProjectIntegrationCard.buttons.manageProjects', { count: availableProjects.length }),
      handleClick: toggleProjectsModal,
      variant: ActionButtonVariant.OUTLINED,
    }];
  }

  const defaultConfig = {
    tooltip: !isAnyScmVendorIntegrated ? 'cards.tooltip.requiresSCMIntegration' : undefined,
    variant: integrationStatus ? ActionButtonVariant.OUTLINED : ActionButtonVariant.PRIMARY,
    isLoading,
  };
  if (integrationTemplate?.integrationType === IntegrationType.thirdParty) {
    const relatedIntegration = integrations?.find((integration) => integration.vendor === integrationTemplate?.key);
    const handleClick = getParagonHandleClick(relatedIntegration, integrationTemplate, integrationStatus, setShowCustomCardModal);
    return [{
      ...defaultConfig,
      tooltip: undefined,
      label: integrationStatus ? 'cards.buttons.configure' : 'cards.buttons.connect',
      handleClick,
    }];
  }
  return [{
    ...defaultConfig,
    disabled: (!integrationFileLink && !action) || isLoading || !isAnyScmVendorIntegrated,
    label: integrationStatus
      ? 'cards.buttons.configure'
      : (integrationTemplate?.options as InnerOptions)?.connect.label || 'cards.buttons.integrateAsCode',
    handleClick: handleButtonClick,
  }];
}, [
  isActionHidden,
  integrationTemplate,
  isAnyScmVendorIntegrated,
  integrationStatus,
  isLoading,
  integrationFileLink,
  action,
  handleButtonClick,
  integrations,
  isComingSoon,
  supportsMultipleProjects,
  availableProjects,
  toggleProjectsModal,
  t,
  setShowCustomCardModal,
]);

