import { PERMISSIONS } from '../constants';

import { constants } from 'globalConstants';

const {
  FINDINGS,
  INTEGRATIONS,
  PIPELINES,
  ACTIONS,
  PLAN,
  OVERVIEW,
  PLANS,
  TEAMS,
  SECURITY_MAPPING,
  SECURITY_TOOLS,
  QUICKSTART,
  RESOURCE,
  APPS_INTEGRATIONS,
  PRICING_PLANS,
  BILLING,
  insights: {
    BASE_ROUTE: BASE_INSIGHTS_ROUTE,
    DEPLOYMENTS,
    PERFORMANCE,
    PULL_REQUESTS,
    SNAPSHOT,
    SNAPSHOT_NOT_FOUND,
    SECURITY_IMPACT,
    SBOM,
  },
  teamsPortal: {
    BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE,
    WELCOME,
    GUIDE,
    LEADERBOARD,
    PORTAL_TEAMS,
    PORTAL_RESOURCES,
    PORTAL_PLAN_ITEMS,
    TOP_FINDINGS,
  },
  risks: {
    BASE_ROUTE: BASE_RISKS_ROUTE,
    BACKLOG,
    RESOURCES_LIST,
    RESOURCES,
  },
  POLICIES,
  WORKFLOWS,
} = constants.routes;

const {
  PIPELINE_ID,
  SNAPSHOT_ID,
  PLAN_SLUG,
  TEAM_ID,
  RESOURCE_ID,
  PLAN_ITEM_SLUG,
  WORKFLOW_ID,
} = constants.routes.pathParameters;

const MANAGEMENT_PLATFORM_ROUTES_PERMISSIONS = {
  [`/${OVERVIEW}`]: PERMISSIONS.READ_OVERVIEW,
  [`/${PIPELINES}`]: PERMISSIONS.READ_PIPELINES,
  [`/${PLAN}`]: PERMISSIONS.READ_PLAN,
  [`/${PLAN}/:${PLAN_SLUG}`]: PERMISSIONS.READ_PLAN,
  [`/${PLANS}`]: PERMISSIONS.READ_PLAN,
  [`/${PLANS}/:${PLAN_SLUG}`]: PERMISSIONS.READ_PLAN,
  [`/${PIPELINES}/:${PIPELINE_ID}`]: PERMISSIONS.READ_PIPELINE,
  [`/${ACTIONS}`]: PERMISSIONS.READ_ACTIONS,
  [`/${TEAMS}`]: PERMISSIONS.READ_TEAMS,
  [`/${TEAMS}/:${TEAM_ID}/${RESOURCE}/:${RESOURCE_ID}`]: PERMISSIONS.READ_TEAMS,
  [`/${TEAMS}/:${TEAM_ID}`]: PERMISSIONS.READ_TEAMS,
  [`/${RESOURCE}/:${RESOURCE_ID}`]: PERMISSIONS.READ_RESOURCE,
  [`/${BASE_INSIGHTS_ROUTE}/${SECURITY_IMPACT}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${DEPLOYMENTS}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${PULL_REQUESTS}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT}/:${SNAPSHOT_ID}`]: PERMISSIONS.READ_SNAPSHOT,
  [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT_NOT_FOUND}`]: PERMISSIONS.READ_SNAPSHOT,
  [`/${BASE_INSIGHTS_ROUTE}/${SBOM}`]: PERMISSIONS.READ_SBOM,
  [`/${BASE_RISKS_ROUTE}/${BACKLOG}`]: PERMISSIONS.READ_BACKLOG,
  [`/${BASE_RISKS_ROUTE}/${RESOURCES}`]: PERMISSIONS.READ_RESOURCES,
  [`/${BASE_RISKS_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`]: PERMISSIONS.READ_RESOURCES,
  [`/${BASE_RISKS_ROUTE}/${RESOURCES}/${RESOURCES_LIST}/:${RESOURCE_ID}`]: PERMISSIONS.READ_RESOURCES,
  [`/${FINDINGS}`]: PERMISSIONS.READ_FINDINGS,
  [`/${INTEGRATIONS}`]: PERMISSIONS.READ_INTEGRATIONS,
  [`/${SECURITY_TOOLS}`]: PERMISSIONS.READ_INTEGRATIONS,
  [`/${SECURITY_TOOLS}/:toolName`]: PERMISSIONS.READ_INTEGRATIONS,
  [`/${POLICIES}`]: PERMISSIONS.READ_POLICIES,
  [`/${WORKFLOWS}`]: PERMISSIONS.READ_WORKFLOWS,
  [`/${WORKFLOWS}/:${WORKFLOW_ID}`]: PERMISSIONS.READ_WORKFLOWS,
  [`/${SECURITY_MAPPING}`]: PERMISSIONS.READ_SECURITY_MAPPING,
  [`/${QUICKSTART}`]: PERMISSIONS.QUICKSTART,
  [`/${APPS_INTEGRATIONS}`]: PERMISSIONS.READ_OVERVIEW,
  [`/${PRICING_PLANS}`]: PERMISSIONS.READ_PRICING,
  [`/${BILLING}`]: PERMISSIONS.READ_BILLING,
};

const TEAM_PORTAL_ROUTES_PERMISSIONS = {
  [`/${BASE_TEAMS_PORTAL_ROUTE}/${GUIDE}`]: PERMISSIONS.TEAM_PORTAL_QUICKSTART,
  [`/${BASE_TEAMS_PORTAL_ROUTE}/${LEADERBOARD}`]: PERMISSIONS.TEAMS_PORTAL,
  [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}`]: PERMISSIONS.TEAMS_PORTAL,
  [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}/${PORTAL_RESOURCES}/:${RESOURCE_ID}`]: PERMISSIONS.TEAMS_PORTAL,
  [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}/${PORTAL_RESOURCES}/:${RESOURCE_ID}/${PORTAL_PLAN_ITEMS}/:${PLAN_ITEM_SLUG}`]: PERMISSIONS.TEAMS_PORTAL,
  [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}/${TOP_FINDINGS}`]: PERMISSIONS.TEAMS_PORTAL,
  [`/${BASE_TEAMS_PORTAL_ROUTE}/${WELCOME}`]: PERMISSIONS.TEAMS_PORTAL,
};

export const buildRoutesPermissions = (userPermissions: string[]): Record<string, string> => {
  let routesPermissions: Record<string, string> = {};

  if (userPermissions.includes(PERMISSIONS.MANAGEMENT_PLATFORM)) {
    routesPermissions = {
      ...routesPermissions,
      ...MANAGEMENT_PLATFORM_ROUTES_PERMISSIONS,
    };
  }

  if (userPermissions.includes(PERMISSIONS.TEAMS_PORTAL)) {
    routesPermissions = {
      ...routesPermissions,
      ...TEAM_PORTAL_ROUTES_PERMISSIONS,
    };
  }

  return routesPermissions;
};
