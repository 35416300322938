import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { paragon } from '@useparagon/connect';
import { FC, ReactElement, useCallback, useMemo, useState } from 'react';

import { JitButton } from '../JitButton/JitButton';

import { sx } from './MultiProjectsManagementModal.styles';

import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { i18n } from 'locale/i18n';
import { logError } from 'services/logger/logger';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { formatDateTime } from 'utils/functions/dateUtils';

interface ProjectsManagementModalProps {
  handleClose: () => void;
  vendor?: Vendor;
}

interface DestroyConfigurationParams {
  id: string;
  credentialId?: string;
}

/**
 * A generic projects management modal component for integration vendors
 */
export const MultiProjectsManagementModal: FC<ProjectsManagementModalProps> = ({
  handleClose,
  vendor = Vendor.Jira,
}) => {
  const { t } = i18n;
  const { user, isLoading: isLoadingUser, refetchUser } = useParagonAuth();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState<boolean>(false);
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);

  // Get vendor display name for UI
  const vendorDisplayName = useMemo(() => {
    // Map vendor enum values to properly formatted display names
    const vendorDisplayNames: Record<string, string> = {
      [Vendor.Jira]: 'Jira',
      [Vendor.Github]: 'Github',
      [Vendor.MONDAY]: 'Monday',
    };

    // Use the mapping or fall back to the vendor enum value
    return vendorDisplayNames[vendor] || vendor;
  }, [vendor]);

  // Transform user data into projects directly
  const projects = useMemo(() => {
    if (!user?.authenticated || !user.integrations?.[vendor]) {
      return [];
    }

    const vendorIntegration = user.integrations[vendor];
    if (!vendorIntegration) {
      return [];
    }

    const configs = vendorIntegration.allConfigurations || {};
    return Object.values(configs)
      .map((config) => {
        const displayName = config.externalId || t('modals.projectsManagement.defaultProject', { vendor: vendorDisplayName }).toString();
        return {
          id: config.id || '',
          name: displayName,
          dateCreated: config.dateCreated ? new Date(config.dateCreated).toISOString() : new Date().toISOString(),
          externalId: config.externalId,
          isDefault: !config.externalId,
        };
      })
      // Sort projects to put default project on top
      .sort((a, b) => {
        if (a.isDefault) return -1;
        if (b.isDefault) return 1;
        return 0;
      });
  }, [user, vendor, vendorDisplayName, t]);

  const isLoading = isLoadingUser;

  const toggleCreateModal = useCallback(() => {
    setShowCreateModal((prev) => !prev);
    setProjectName('');
    setError(null);
    setIsCreating(false);
  }, []);

  const handleProjectCreated = useCallback(async () => {
    try {
      await refetchUser();
      toggleCreateModal();
    } catch (err) {
      logError('Error handling project creation', { error: err });
      setError('Error finalizing project creation. Please try again.');
      setIsCreating(false);
    }
  }, [refetchUser, toggleCreateModal]);

  const handleSelectProject = useCallback(
    (projectId: string) => {
      // Find the project by ID
      const project = projects.find((p) => p.id === projectId);
      if (project) {
        // Open the Connect Portal with the selected configuration
        paragon.connect(vendor, {
          selectedConfigurationId: project.externalId ? `ext:${project.externalId}` : projectId,
          onSuccess: async () => {
            // Successfully opened configuration
            await refetchUser();
          },
          onError: (err) => {
            logError(`Error opening ${vendorDisplayName} configuration`, { error: err });
          },
        });
      }
    },
    [projects, vendor, refetchUser, vendorDisplayName],
  );

  const handleDeleteProject = useCallback(
    async (projectId: string) => {
      try {
        // Get the credential ID from the user object
        const credentialId = user?.integrations?.[vendor]?.allCredentials?.[0]?.id;

        if (!credentialId) {
          logError(`No ${vendorDisplayName} credential ID found`);
          return;
        }

        // Create params object with proper typing
        const params: DestroyConfigurationParams = {
          id: projectId,
          credentialId,
        };

        await paragon.destroyConfiguration(params);
        await refetchUser();
      } catch (err) {
        logError(`Error deleting ${vendorDisplayName} configuration`, { error: err });
      }
    },
    [user, vendor, refetchUser, vendorDisplayName],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
    if (error) setError(null);
  };

  const handleCreateProject = async () => {
    if (!projectName.trim()) {
      setError(t('modals.projectsManagement.createProject.nameRequired').toString());
      return;
    }

    setIsCreating(true);
    setError(null);

    try {
      // Get the credential ID from authenticated user
      const credentialId = user?.integrations?.[vendor]?.allCredentials?.[0]?.id;

      if (credentialId) {
        // If already connected, create a new configuration for the existing credential
        await paragon.createConfiguration({
          credentialId,
          externalId: projectName.trim(), // Use the project name as the external ID
        });

        await handleProjectCreated();
      } else {
        // If not connected, connect first
        await paragon.connect(vendor, {
          onSuccess: async () => {
            try {
              // After successful connection, get the credential ID
              const newUserData = await paragon.getUser();
              if (newUserData.authenticated) {
                const vendorIntegration = newUserData.integrations?.[vendor];

                if (vendorIntegration?.allCredentials && vendorIntegration.allCredentials.length > 0) {
                  const newCredentialId = vendorIntegration.allCredentials[0].id;

                  // Create a configuration for the new credential
                  await paragon.createConfiguration({
                    credentialId: newCredentialId,
                    externalId: projectName.trim(), // Use the project name as the external ID
                  });

                  await handleProjectCreated();
                } else {
                  setError(t('modals.projectsManagement.errors.credentialFailed').toString());
                  setIsCreating(false);
                }
              } else {
                setError(t('modals.projectsManagement.errors.authFailed').toString());
                setIsCreating(false);
              }
            } catch (err) {
              logError('Error in connection success handler', { error: err });
              setError('An error occurred during project creation. Please try again.');
              setIsCreating(false);
            }
          },
          onError: (err) => {
            logError(`Error connecting to ${vendorDisplayName}`, { error: err });
            setError(t('modals.projectsManagement.errors.connectionFailed', { vendor: vendorDisplayName }).toString());
            setIsCreating(false);
          },
        });
      }
    } catch (err) {
      logError(`Error creating ${vendorDisplayName} project`, { error: err });
      setError(t('modals.projectsManagement.errors.createFailed').toString());
      setIsCreating(false);
    }
  };

  const handleDisconnect = async () => {
    setIsDisconnecting(true);
    try {
      // Get the credential ID from the user object
      const credentialId = user?.integrations?.[vendor]?.allCredentials?.[0]?.id;

      if (!credentialId) {
        logError('No credential ID found for disconnection');
        setIsDisconnecting(false);
        return;
      }

      // Use paragon.uninstallIntegration for disconnecting
      await paragon.uninstallIntegration(vendor, {});
      await refetchUser();
      setShowDisconnectConfirm(false);
      setIsDisconnecting(false);
      handleClose(); // Close all modals after disconnecting
    } catch (err) {
      logError('Error disconnecting from integration', { error: err });
      setIsDisconnecting(false);
    }
  };

  const toggleDisconnectConfirm = useCallback(() => {
    setShowDisconnectConfirm((prev) => !prev);
  }, []);

  const modalTitle = t('modals.projectsManagement.title', { vendor: vendorDisplayName });

  const formatProjectDate = (dateString: string) => {
    const { fullDateTime } = formatDateTime(dateString);
    return fullDateTime;
  };

  // Loading state UI
  const loadingContent = (
    <Box sx={sx('loadingContainer')}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <CircularProgress
          size={40}
          sx={{ color: 'rgba(99, 102, 241, 0.8)' }}
        />

        <Typography>
          {t('modals.projectsManagement.loadingText', { vendor: vendorDisplayName }).toString()}
        </Typography>
      </Box>
    </Box>
  );

  // No projects state UI
  const noProjectsContent = (
    <Box sx={sx('noProjectsContainer')}>
      <Typography sx={{
        ...sx('noProjectsText'),
        fontSize: {
          xs: '0.875rem',
          sm: '0.975rem',
          md: '1.1rem',
        },
      }}
      >
        {t('modals.projectsManagement.noProjects').toString()}
      </Typography>
    </Box>
  );

  // Project card component
  const renderProjectCard = (project: { id: string; name: string; dateCreated: string; isDefault: boolean }) => (
    <Box
      key={project.id}
      sx={{
        ...sx('enhancedProjectCard'),
        margin: '0 0 16px 0',
        padding: '16px',
        borderRadius: '8px',
        border: `1px solid ${colors.backgroundDark}`,
        transition: 'all 0.2s ease-in-out',
        overflow: 'visible',
        position: 'relative',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          borderColor: '#6D72F6',
        },
      }}
    >
      <Box sx={sx('enhancedProjectCardHeader')}>
        <Box>
          <Typography
            data-testid={`project-name-${project.id}`}
            sx={{
              ...sx('combinedProjectTitle'),
              color: project.isDefault ? '#6D72F6' : colors.white,
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              fontSize: {
                xs: '0.95rem',
                sm: '1.05rem',
                md: '1.15rem',
                lg: '1.2rem',
              },
            }}
          >
            {project.isDefault ? (
              <FolderIcon
                sx={{
                  color: '#6D72F6',
                  fontSize: {
                    xs: '1.1rem',
                    md: '1.3rem',
                  },
                  marginRight: '8px',
                }}
              />
            ) : null}

            {project.name}

            {project.isDefault && (
              <Box
                component='span'
                sx={{
                  ...sx('defaultProjectBadge'),
                  marginLeft: '8px',
                  fontSize: {
                    xs: '0.75rem',
                    md: '0.85rem',
                  },
                  padding: '2px 8px',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(109, 114, 246, 0.15)',
                  color: '#6D72F6',
                }}
              >
                {t('modals.projectsManagement.projectCard.default').toString()}
              </Box>
            )}
          </Typography>

          <Typography sx={{
            ...sx('enhancedProjectDate'),
            fontSize: {
              xs: '0.75rem',
              sm: '0.8rem',
              md: '0.9rem',
            },
            color: 'rgba(255, 255, 255, 0.6)',
            marginTop: '4px',
          }}
          >
            {formatProjectDate(project.dateCreated)}
          </Typography>
        </Box>

        <Box sx={{
          ...sx('enhancedButtonGroup'),
          display: 'flex',
          gap: '8px',
        }}
        >
          <JitButton
            onClick={() => handleSelectProject(project.id)}
            startIcon={<SettingsIcon fontSize='small' />}
            sx={{
              ...sx('configureButton'),
              fontSize: {
                xs: '0.875rem',
                md: '0.95rem',
              },
              transition: 'background-color 0.2s',
              '&:hover': {
                backgroundColor: 'rgba(109, 114, 246, 0.15)',
              },
            }}
            type='button'
          >
            {t('modals.projectsManagement.projectCard.configure').toString()}
          </JitButton>

          {/* Only show delete button for non-default projects */}
          {!project.isDefault && (
            <JitButton
              onClick={() => handleDeleteProject(`ext:${project.name}`)}
              startIcon={<DeleteOutlineIcon fontSize='small' />}
              sx={{
                ...sx('deleteButton'),
                fontSize: {
                  xs: '0.875rem',
                  md: '0.95rem',
                },
                transition: 'background-color 0.2s',
                '&:hover': {
                  backgroundColor: 'rgba(239, 68, 68, 0.15)',
                },
              }}
              type='button'
            >
              {t('modals.projectsManagement.projectCard.delete').toString()}
            </JitButton>
          )}
        </Box>
      </Box>
    </Box>
  );

  // Project list content
  const projectListContent: ReactElement = (
    <Box sx={sx('contentContainer')}>
      <Typography sx={{
        ...sx('enhancedDescription'),
        fontSize: {
          xs: '0.9rem',
          sm: '1rem',
          md: '1.1rem',
        },
        marginBottom: '16px',
      }}
      >
        {t('modals.projectsManagement.description', { vendor: vendorDisplayName }).toString()}
      </Typography>

      {isLoading ? (
        loadingContent
      ) : (
        <Box sx={sx('flexColumn')}>
          <Box sx={sx('flexGrow')}>
            {projects.length === 0 ? (
              noProjectsContent
            ) : (
              <Box sx={{
                ...sx('projectsContainer'),
                padding: '8px 4px',
                maxHeight: {
                  xs: '400px',
                  md: '450px',
                  lg: '500px',
                },
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '8px',
                  '&:hover': {
                    background: 'rgba(255, 255, 255, 0.3)',
                  },
                },
              }}
              >
                {projects.map(renderProjectCard)}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );

  // Loading states for buttons
  const creatingProjectButtonContent = (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
      }}
    >
      <CircularProgress
        size={16}
        sx={{ color: 'white' }}
      />

      {t('modals.projectsManagement.createProject.creating').toString()}
    </Box>
  );

  const disconnectingButtonContent = (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
      }}
    >
      <CircularProgress
        size={16}
        sx={{ color: 'white' }}
      />

      {t('modals.projectsManagement.disconnectConfirm.disconnecting', { vendor: vendorDisplayName }).toString()}
    </Box>
  );

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={!showCreateModal && !showDisconnectConfirm}
        PaperProps={{
          sx: {
            ...sx('enhancedDialog'),
            overflow: 'visible',
            width: {
              xs: 'auto',
              sm: '550px',
              md: '650px',
              lg: '700px',
            },
          },
        }}
      >
        <DialogTitle sx={sx('enhancedDialogTitle')}>
          <Typography
            component='h2'
            sx={{
              ...sx('dialogTitleText'),
              fontSize: {
                xs: '1.25rem',
                md: '1.4rem',
                lg: '1.5rem',
              },
            }}
            variant='h6'
          >
            {modalTitle}
          </Typography>

          <JitButton
            data-testid='disconnect-button'
            onClick={toggleDisconnectConfirm}
            sx={{
              ...sx('disconnectButton'),
              fontSize: {
                xs: '0.875rem',
                md: '0.95rem',
              },
            }}
            type='button'
          >
            {t('modals.projectsManagement.disconnectButton').toString()}
          </JitButton>
        </DialogTitle>

        <DialogContent sx={{
          ...sx('enhancedDialogContent'),
          overflow: 'hidden',
        }}
        >
          {projectListContent}
        </DialogContent>

        <Box sx={sx('enhancedDialogFooter')}>
          <JitButton
            data-testid='create-project-button'
            onClick={toggleCreateModal}
            startIcon={<AddIcon />}
            sx={{
              ...sx('createProjectButton'),
              fontSize: {
                xs: '0.875rem',
                md: '0.95rem',
              },
              transition: 'background-color 0.2s',
              '&:hover': {
                backgroundColor: 'rgba(109, 114, 246, 0.15)',
              },
            }}
            type='button'
          >
            {t('modals.projectsManagement.createProject.button').toString()}
          </JitButton>

          <JitButton
            onClick={handleClose}
            sx={{
              ...sx('enhancedPrimaryButton'),
              fontSize: {
                xs: '0.875rem',
                md: '0.95rem',
              },
            }}
            type='button'
          >
            {t('modals.projectsManagement.doneButton').toString()}
          </JitButton>
        </Box>
      </Dialog>

      {showCreateModal && (
        <Dialog
          onClose={toggleCreateModal}
          open={showCreateModal}
          PaperProps={{
            sx: {
              ...sx('enhancedDialog'),
              marginTop: {
                xs: '40px',
                sm: '60px',
                md: '80px',
              },
              width: {
                xs: 'auto',
                sm: '550px',
                md: '600px',
              },
            },
          }}
        >
          <DialogTitle sx={sx('enhancedDialogTitle')}>
            <Typography
              component='h2'
              sx={sx('dialogTitleText')}
              variant='h6'
            >
              {t('modals.projectsManagement.createProject.title', { vendor: vendorDisplayName }).toString()}
            </Typography>

            <IconButton onClick={toggleCreateModal} size='small' sx={sx('closeButton')}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={sx('dialogContentWithPadding')}>
            <Box sx={{ width: '100%' }}>
              <Typography
                component='label'
                htmlFor='project-name'
                sx={{
                  ...sx('formLabel'),
                  marginTop: {
                    xs: '16px',
                    sm: '20px',
                    md: '24px',
                  },
                  fontSize: {
                    xs: '1rem',
                    sm: '1.1rem',
                    md: '1.2rem',
                  },
                  display: 'block',
                }}
              >
                {t('modals.projectsManagement.createProject.nameLabel').toString()}
              </Typography>

              <TextField
                autoFocus
                data-testid='project-name-input'
                error={!!error}
                fullWidth
                helperText={error}
                id='project-name'
                onChange={handleInputChange}
                placeholder={t('modals.projectsManagement.createProject.namePlaceholder', { vendor: vendorDisplayName }).toString()}
                sx={sx('formInput')}
                type='text'
                value={projectName}
              />
            </Box>
          </DialogContent>

          <Box sx={sx('dialogFooterEnd')}>
            <JitButton onClick={toggleCreateModal} sx={sx('secondaryButton')} type='button'>
              {t('modals.projectsManagement.createProject.backButton').toString()}
            </JitButton>

            <JitButton
              disabled={isCreating || !projectName.trim()}
              onClick={handleCreateProject}
              sx={sx('primaryButton')}
              type='button'
            >
              {isCreating ? creatingProjectButtonContent : t('modals.projectsManagement.createProject.createButton').toString()}
            </JitButton>
          </Box>
        </Dialog>
      )}

      {/* Disconnect confirmation dialog */}
      {showDisconnectConfirm && (
        <Dialog
          onClose={toggleDisconnectConfirm}
          open={showDisconnectConfirm}
          PaperProps={{
            sx: {
              ...sx('enhancedDialog'),
              marginTop: {
                xs: '20px',
                sm: '30px',
                md: '40px',
              },
              width: {
                xs: '300px',
                sm: '400px',
                md: '450px',
              },
              maxHeight: {
                xs: '200px',
                sm: '300px',
              },
            },
          }}
        >
          <DialogTitle sx={sx('enhancedDialogTitle')}>
            <Typography
              component='h2'
              sx={sx('dialogTitleText')}
              variant='h6'
            >
              {t('modals.projectsManagement.disconnectConfirm.title', { vendor: vendorDisplayName }).toString()}
            </Typography>

            <IconButton onClick={toggleDisconnectConfirm} size='small' sx={sx('closeButton')}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={sx('dialogContentWithPadding')}>
            <Typography sx={{
              marginTop: {
                xs: '16px',
                sm: '20px',
                md: '24px',
              },
              fontSize: {
                xs: '0.9rem',
                sm: '1rem',
                md: '1.1rem',
              },
              lineHeight: 1.6,
            }}
            >
              {t('modals.projectsManagement.disconnectConfirm.message', { vendor: vendorDisplayName }).toString()}
            </Typography>
          </DialogContent>

          <Box sx={sx('dialogFooterEnd')}>
            <JitButton onClick={toggleDisconnectConfirm} sx={sx('secondaryButton')} type='button'>
              {t('modals.projectsManagement.disconnectConfirm.cancel').toString()}
            </JitButton>

            <JitButton
              disabled={isDisconnecting}
              onClick={handleDisconnect}
              sx={sx('combinedDangerButton')}
              type='button'
            >
              {isDisconnecting ? disconnectingButtonContent : t('modals.projectsManagement.disconnectConfirm.confirm').toString()}
            </JitButton>
          </Box>
        </Dialog>
      )}
    </>
  );
};
