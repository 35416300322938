import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { Queries } from 'types/enums/Queries';
import { IWebsocketNotification, IWebsocketNotificationEntity, IFinding } from 'types/interfaces';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  setFinding: Dispatch<SetStateAction<IFinding | null>>,
}

export const useHandleFindingsWebSocketNotification = ({ setFinding }: Props) => {
  const queryClient = useQueryClient();

  const updateFinding = useCallback((
    currentFinding: IFinding | null,
    updatedFindings: IFinding[],
  ) => {
    if (!currentFinding) {
      return currentFinding;
    }

    const updatedFinding = updatedFindings.find((finding) => finding.id === currentFinding.id);
    if (!updatedFinding) {
      return currentFinding;
    }

    return {
      ...currentFinding,
      ...updatedFinding,
    };
  }, []);

  const handleFindingsWebSocketNotification = useCallback((notification: IWebsocketNotification<IFinding>) => {
    const camelizedNotification = camelizeSnakeCaseKeys(notification) as IWebsocketNotificationEntity<IFinding>;
    assertWebsocketNotificationEntity(camelizedNotification);

    let { message: { created, updated, deleted } } = camelizedNotification;
    created ??= [];
    updated ??= [];
    deleted ??= [];

    // Handle updates to the current finding
    setFinding((currentFinding) => updateFinding(currentFinding, updated));

    // Invalidate findings queries to refresh the findings list
    if (!isEmpty(created) || !isEmpty(updated) || !isEmpty(deleted)) {
      queryClient.invalidateQueries({ queryKey: [Queries.Findings] });
    }
  }, [queryClient, setFinding, updateFinding]);

  return { handleFindingsWebSocketNotification };
};
