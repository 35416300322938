import { AuthenticatedConnectUser, paragon, SDK_EVENT } from '@useparagon/connect';
import { IntegrationInstallEvent } from '@useparagon/connect/dist/src/types/sdk';
import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';

import { useIntegrationsContext } from '../IntegrationsContext';

import { constants } from 'globalConstants';
import { useIntegrationsService } from 'services/IntegrationsService/useIntegrationService';
import { Queries } from 'types/enums/Queries';
import { IntegrationLifecycleEventState } from 'types/interfaces/Integrations/IIntegration';

interface IUseParagonAuth {
  user?: AuthenticatedConnectUser;
  isLoading: boolean;
  isLoadingIntegrations: boolean;
  refetchUser: () => Promise<void>;
}

export const useParagonAuth = (): IUseParagonAuth => {
  const {
    fetchIntegrations,
    isLoading: isLoadingIntegrations,
  } = useIntegrationsContext();
  const {
    getParagonEnv: fetchParagonEnv,
    publishLifecycleNotification,
  } = useIntegrationsService();
  const { HOUR } = constants.time;

  const { data: authCredentials, isLoading, refetch: refetchParagonEnv } = useQuery(Queries.ParagonEnv, fetchParagonEnv, {
    refetchInterval: HOUR * 12, // 12 hours - because the token is for 24 hours and we don't want to get there
  });

  const authenticateUser = async () => {
    const { projectId, token } = authCredentials!.data;
    await paragon.authenticate(projectId, token, {
      // metadata allows adding extra user data that can be accessed within Paragon's platform
      // and used for customizing integration behavior
      metadata: {
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
    return paragon.getUser();
  };

  const { data: user, refetch: refetchParagonUser, isLoading: isLoadingUser } = useQuery(
    [Queries.ParagonUser, authCredentials?.data.projectId, authCredentials?.data.token],
    authenticateUser,
    {
      enabled: !isLoading && !!authCredentials?.data.projectId && !!authCredentials?.data.token,
      onError: async () => {
        await refetchParagonEnv();
        refetchParagonUser();
      },
    },
  );

  const refetchUser = useCallback(async () => {
    await refetchParagonUser();
    fetchIntegrations(true);
  }, [fetchIntegrations, refetchParagonUser]);

  const listener = useCallback(async () => {
    await refetchUser();
  }, [refetchUser]);

  const listenerUninstall = useCallback(async (event: IntegrationInstallEvent) => {
    await refetchUser();
    await publishLifecycleNotification(event.integrationType, IntegrationLifecycleEventState.DISABLED);
  }, [publishLifecycleNotification, refetchUser]);

  useEffect(() => {
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listenerUninstall);

    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listenerUninstall);
    };
  }, [listenerUninstall, listener]);

  return {
    user: user?.authenticated ? user : undefined,
    isLoading: isLoading || isLoadingUser,
    isLoadingIntegrations,
    refetchUser,
  };
};
