
import { Action } from 'components/MetricDetails/components/Actions/Actions';
import { constants } from 'globalConstants';

/**
 * Hook that returns actions for the MTTR metric
 * @param timeFrame - Optional time frame parameter to apply to navigation
 * @returns Array of actions for the MTTR metric
 */
export const useMttrActions = (timeFrame?: string): Action[] => {
  const actions: Action[] = [
    {
      title: 'pages.securityImpactV3.components.mttr.actions.configureWorkflows.title',
      text: 'pages.securityImpactV3.components.mttr.actions.configureWorkflows.text',
      label: 'pages.securityImpactV3.components.mttr.actions.configureWorkflows.label',
      handleClick: () => {
        window.open(`/${constants.routes.WORKFLOWS}`, '_blank');
      },
    },
    {
      title: 'pages.securityImpactV3.components.mttr.actions.addressOldestFindings.title',
      text: 'pages.securityImpactV3.components.mttr.actions.addressOldestFindings.text',
      label: 'pages.securityImpactV3.components.mttr.actions.addressOldestFindings.label',
      handleClick: () => {
        window.open(`/${constants.routes.FINDINGS}?${new URLSearchParams({
          resolution: 'OPEN',
          ignored: 'false',
          sort: 'created_at',
          order: 'asc',
          ...(timeFrame ? { time_ago: timeFrame } : {}),
        })}`, '_blank');
      },
    },
  ];

  return actions;
};
