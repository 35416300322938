import { FC, useMemo } from 'react';

import styles from './ResolvedFindingsWidgetContent.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { ThreatPreventionContent } from 'pages/InsightsPage/SubPages/SecurityImpactOld/components/DevProductivity/components/ThreatPreventionContent/ThreatPreventionContent';

interface Props {
  preProdCount: number
  totalCount: number;
  isLoading: boolean;
}

export const ResolvedFindingsWidgetContent: FC<Props> = ({ preProdCount, totalCount, isLoading }) => {
  const content = useMemo(() => {
    if (isLoading) {
      return <CircularLoadingSpinner />;
    }

    return (
      <ThreatPreventionContent
        preProdResolvedFindings={preProdCount}
        size='s'
        totalResolvedFindings={totalCount}
        width={300}
      />
    );
  }, [isLoading, preProdCount, totalCount]);

  return (
    <div className={styles.wrapper} data-testid='ResolvedFindingsWidgetContent'>

      <div className={styles.content} data-testid='content'>
        {content}
      </div>
    </div>
  );
};
