import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';

import { Section } from '../Section/Section';

import { TeamTrend } from './components/TeamTrend/TeamTrend';
import styles from './TeamSecurityBreakdown.module.scss';

import { InfiniteScrollLoader } from 'components/InfiniteScrollLoader/InfiniteScrollLoader';
import { JitText } from 'components/JitText/JitText';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { constants } from 'globalConstants';
import { useTeamsInfiniteQuery } from 'services/TeamsService/hooks';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

type SortDirection = 'asc' | 'desc';
type SortField = 'teamName' | 'score' | 'trend';

const mapSortFieldToBackend = (field: SortField): TeamSortBy => {
  switch (field) {
    case 'teamName':
      return TeamSortBy.NAME;
    case 'score':
    case 'trend':
      return TeamSortBy.SCORE;
    default:
      return TeamSortBy.SCORE;
  }
};

const mapSortDirectionToBackend = (direction: SortDirection): TeamSortOrder => (direction === 'asc' ? TeamSortOrder.ASC : TeamSortOrder.DESC);

interface TeamSecurityBreakdownProps {
  timeRange?: string;
}

export const TeamSecurityBreakdown: React.FC<TeamSecurityBreakdownProps> = () => {
  const [sortField, setSortField] = useState<SortField>('score');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const { data: teamsData, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useTeamsInfiniteQuery({
    sortBy: mapSortFieldToBackend(sortField),
    sortOrder: mapSortDirectionToBackend(sortDirection),
    limit: 10,
    displayImage: false,
    displayMembers: false,
  });

  const handleSort = useCallback((field: SortField) => {
    if (sortField === field) {
      setSortDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  }, [sortField]);

  const getSortIcon = useCallback((field: SortField) => {
    if (sortField !== field) {
      return <ArrowDropDown className={styles.sortIcon} />;
    }
    return sortDirection === 'asc'
      ? <ArrowDropUp className={`${styles.sortIcon} ${styles.active}`} />
      : <ArrowDropDown className={`${styles.sortIcon} ${styles.active}`} />;
  }, [sortField, sortDirection]);

  const renderHeader = useCallback((key: SortField, isSortable = true) => (
    <th
      className={`${styles.teamNameHeader} ${isSortable ? styles.sortable : ''}`}
      onClick={() => isSortable && handleSort(key)}
    >
      <JitText component='span' text={`components.metricDetails.${key}`} />

      {isSortable && getSortIcon(key)}
    </th>
  ), [handleSort, getSortIcon]);

  const formatTeams = useCallback(() => {
    if (!teamsData?.pages || teamsData.pages.length === 0) return [];

    return teamsData.pages.flatMap((page) => {
      if (!page) return [];
      return page.data.map((team: IGetTeamResponseItem) => ({
        id: team.id,
        teamName: team.name,
        score: `${Math.round(team.score)}%`,
      }));
    });
  }, [teamsData]);

  const teamPerformances = formatTeams();

  if (isLoading) {
    return (
      <Section
        section='components.metricDetails.securityThemesContribution'
        title='components.metricDetails.scoreBreakdownByTeam'
      >
        <LoadingSkeleton style={{ height: '300px' }} />
      </Section>
    );
  }

  if (teamPerformances.length === 0) {
    return (
      <Section
        section='components.metricDetails.securityThemesContribution'
        title='components.metricDetails.scoreBreakdownByTeam'
      >
        <JitText text='components.metricDetails.noTeamsData' />
      </Section>
    );
  }

  return (
    <Section
      section='components.metricDetails.securityThemesContribution'
      title='components.metricDetails.scoreBreakdownByTeam'
    >
      <div className={styles.teamsTableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              {renderHeader('teamName')}

              {renderHeader('score')}

              {renderHeader('trend', false)}
            </tr>
          </thead>

          <tbody>
            {teamPerformances.map((team) => (
              <tr key={`team-${team.teamName}`} className={styles.teamRow}>
                <td
                  className={styles.teamName}
                  onClick={() => window.open(`${constants.routes.TEAMS}/${team.id}`, '_blank')}
                  role='button'
                  tabIndex={0}
                >
                  {team.teamName}
                </td>

                <td className={styles.teamScore}>{team.score}</td>

                <td>
                  <TeamTrend teamId={team.id} />
                </td>
              </tr>
            ))}

            {hasNextPage && (
              <tr>
                <td colSpan={3}>
                  <InfiniteScrollLoader
                    isDisabled={!hasNextPage}
                    isLoading={isFetchingNextPage}
                    onIntersect={fetchNextPage}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Section>
  );
};
