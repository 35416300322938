import { groupBy, memoize } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';

import { CriticalAssetSecurity } from './components/CriticalAssetSecurity/CriticalAssetSecurity';
import { FalsePositiveReduction } from './components/FalsePositiveReduction/FalsePositiveReduction';
import { MTTR } from './components/MTTR/MTTR';
import { OrgScore } from './components/OrgScore/OrgScore';
import { OrgScoreMetric } from './components/OrgScore/types';
import { RepositoryCoverage } from './components/RepositoryCoverage/RepositoryCoverage';
import { RiskRemediation } from './components/RiskRemediation/RiskRemediation';
import { ShiftLeft } from './components/ShiftLeft/ShiftLeft';
import { Teams } from './components/Teams/Teams';
import styles from './SecurityImpact.module.scss';

import { JitText } from 'components/JitText/JitText';
import { MetricCardRef } from 'components/MetricCard/MetricCard';
import { PeriodDropdown } from 'pages/InsightsPage/SubPages/PerformancePage/components/PeriodDropdown/PeriodDropdown';
import { useThresholds } from 'services/MetricService/hooks';
import { TimeAgo } from 'types/enums';

export const SecurityImpact = () => {
  const [period, setPeriod] = useState<TimeAgo>(TimeAgo.ThreeMonths);
  // Fetch thresholds data but ignore for now until we implement metrics display
  const { data: thresholds } = useThresholds();

  const thresholdsGrouppedByMetric = useMemo(() => groupBy(thresholds, 'metric'), [thresholds]);

  const cardRefs = useRef<Partial<Record<OrgScoreMetric, MetricCardRef>>>({});
  const setRef = useMemo(() => memoize((metric: OrgScoreMetric) => (ref: MetricCardRef | null) => {
    if (ref) {
      cardRefs.current[metric] = ref;
    } else {
      delete cardRefs.current[metric];
    }
  }), []);

  const handleMetricClick = useCallback((metric: OrgScoreMetric) => {
    cardRefs.current[metric]?.openDetails();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.gridContainer}>
        <div className={styles.orgScoreWrapper}>
          <div className={styles.headerContainer}>
            <JitText
              bold
              className={styles.pageTitle}
              size='xxl'
              text='Organization overview'
              variant='h6'
            />

          </div>

          <div className={styles.orgScoreContainer}>
            <OrgScore onMetricClick={handleMetricClick} timeRange={period} />
          </div>

          <div className={styles.periodDropdownContainer}>
            <PeriodDropdown
              handlePeriodChange={setPeriod}
              period={period}
            />
          </div>
        </div>

        <RepositoryCoverage
          cardRef={setRef('resourcesCoverage')}
          thresholds={thresholdsGrouppedByMetric.repository_coverage}
          timeRange={period}
        />

        <MTTR
          cardRef={setRef('mttr')}
          thresholds={thresholdsGrouppedByMetric.mttr}
          timeRange={period}
        />

        <FalsePositiveReduction
          cardRef={setRef('prioritizedFindings')}
          thresholds={thresholdsGrouppedByMetric.false_positive_reduction}
          timeRange={period}
        />

        <RiskRemediation
          cardRef={setRef('riskRemediation')}
          thresholds={thresholdsGrouppedByMetric.risk_remediation}
          timeRange={period}
        />

        <ShiftLeft
          cardRef={setRef('shiftLeft')}
          thresholds={thresholdsGrouppedByMetric.shift_left}
          timeRange={period}
        />

        <CriticalAssetSecurity
          cardRef={setRef('criticalAssetSecurity')}
          thresholds={thresholdsGrouppedByMetric.critical_asset_security}
          timeRange={period}
        />

        <div className={styles.teamsContainer}>
          <Teams
            cardRef={setRef('teamScores')}
            thresholds={thresholdsGrouppedByMetric.teams}
            timeRange={period}
          />
        </div>
      </div>
    </div>
  );
};
