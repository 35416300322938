
import axios from 'axios';

import { config } from '../../config';

const createClient = () => {
  const instance = axios.create({
    baseURL: config.paragonProxyBaseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  instance.interceptors.response.use(
    (response) => response,
  );
  return instance;
};

export const client = createClient();

