import { GET_TEAMS_PAGE_LIMIT } from './constants';
import { FetchTeamOptions } from './useTeamsService';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface FetchTeamsParams {
  limit?: number;
  page?: number;
  after?: string;
  sort_by?: TeamSortBy;
  sort_order?: TeamSortOrder;
  search_value?: string;
  include_members?: boolean;
  member_search_key?: string;
  member_search_value?: string;
  search_key?: string;
  include_image?: boolean;
  user_id?: string;
}

export const fetchTeams = async (client: ReturnType<typeof useClient>['client'], queryParams: FetchTeamOptions): Promise<IPaginatedResponse<IGetTeamResponseItem> | undefined> => {
  const {
    sortBy,
    sortOrder,
    after,
    searchValue,
    searchKey,
    memberSearch,
    limit = GET_TEAMS_PAGE_LIMIT,
    displayImage,
    userId,
    displayMembers = true,
    page = 1,
  } = queryParams;

  const params: FetchTeamsParams = {
    limit,
    after,
    page,
    sort_by: sortBy,
    sort_order: sortOrder,
    user_id: userId,
    ...(searchValue && { search_value: searchValue }),
    ...(memberSearch && {
      member_search_key: memberSearch.key,
      member_search_value: memberSearch.value,
    }),
    ...(searchKey && { search_key: searchKey }),
    ...(displayImage && { include_image: displayImage }),
    ...(displayMembers && { include_members: displayMembers }),
  };

  const url = getApiUrls.teamsService.fetchTeams();
  const response = await client.get<IPaginatedResponse<IGetTeamResponseItem>>({
    url,
    allowedStatuses: [200],
    requestConfig: { params },
  });

  if (response?.status === 200) {
    return {
      data: camelizeSnakeCaseKeys(response.data.data) as IGetTeamResponseItem[],
      metadata: response.data.metadata,
    };
  }

  throw new Error('Error fetching teams');
};

