import { CSSProperties } from 'react';

import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';

interface Props {
  style?: CSSProperties;
}

export const ChartSkeleton = ({ style }: Props) => (
  <LoadingSkeleton style={{ height: 200,
    marginBottom: 20,
    width: '100%',
    ...style }}
  />
);
