import { FC, ReactNode } from 'react';

import { Goals } from './components/Goals/Goals';
import { SubmetricLabelTransform } from './components/Goals/types';

import { JitSidePanel } from 'components/JitSidePanel/JitSidePanel';
import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { Threshold } from 'services/MetricService/types';

interface MetricDetailsProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  thresholds: Array<Threshold>
  transformSubmetricLabel?: SubmetricLabelTransform
  focusOnGoal?: boolean
}

export const MetricDetails: FC<MetricDetailsProps> = ({
  open,
  onClose,

  children,
  thresholds,
  transformSubmetricLabel,
  focusOnGoal,
}) => (
  <JitSlidingPanel
    closePanel={onClose}
    isOpen={open}
  >
    <JitSidePanel closePanel={onClose} title='Metric Details'>

      {children}

      <Goals focus={focusOnGoal} thresholds={thresholds} transformSubmetricLabel={transformSubmetricLabel} />

    </JitSidePanel>
  </JitSlidingPanel>
);
