export interface SortableItem {
  key?: string;
  id?: string;
  name: string;
}

/**
 * Sorts an array of items with a default value prioritized first, then alphabetically by name
 * @param items Array of items to sort
 * @param defaultValue The value to prioritize (defaults to 'default')
 * @param keyField The field to check for the default value (defaults to 'key')
 * @returns Sorted array of items
 */
export const sortWithDefault = <T extends SortableItem>(
  items: T[],
  defaultValue: string = 'default',
  keyField: keyof Pick<T, 'key' | 'id'> = 'key',
): T[] => [...items].sort((a, b) => {
    if (a[keyField] === defaultValue) return -1;
    if (b[keyField] === defaultValue) return 1;
    return a.name.localeCompare(b.name);
  });
