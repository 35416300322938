import { useMemo } from 'react';

import { MetricCommonProps } from '../../types';

import { TeamActions } from './components/TeamActions/TeamActions';
import { TeamsScoreDetails } from './components/TeamsScoreDetails/TeamsScoreDetails';
import { TeamsSummary } from './components/TeamsSummary/TeamsSummary';

import { MetricCard } from 'components/MetricCard/MetricCard';
import { TeamSecurityBreakdown } from 'components/MetricDetails/components/TeamSecurityBreakdown/TeamSecurityBreakdown';
import { useOrgScoreTrending } from 'services/MetricService/hooks';
import { Threshold } from 'services/MetricService/types';

export const Teams = ({ timeRange, thresholds, cardRef }: MetricCommonProps) => {
  const { data: orgScoreData, isLoading: isLoadingOrgScore } = useOrgScoreTrending(timeRange);

  const { formattedValue, trend } = useMemo(() => {
    if (!orgScoreData?.entitiesScores?.[0]?.scoresByDay) return { formattedValue: '-' };
    // Calculate the average score from the score data
    const { scoresByDay } = orgScoreData.entitiesScores[0];
    const validScores = scoresByDay.filter((day) => day.score !== null);

    if (!validScores.length) return { formattedValue: '-' };

    const trendValue = validScores.length > 1 ? (validScores.at(-1)!.score ?? 0) - (validScores.at(0)!.score ?? 0) : 0;

    return { formattedValue: `${validScores.at(-1)!.score}%`,
      trend: trendValue };
  }, [orgScoreData]);

  const thresholdValue = useMemo((): Threshold[] => {
    if (!thresholds?.length) {
      return [
        {
          metric: 'teams',
          goal: 80,
          threshold: 60,
          isPlaceholder: true,
        },
      ];
    }

    return thresholds;
  }, [thresholds]);

  return (
    <MetricCard
      ref={cardRef}
      details={(
        <>
          <TeamActions />

          <TeamsScoreDetails timeRange={timeRange} />

          <TeamSecurityBreakdown timeRange={timeRange} />

        </>
      )}
      goal={thresholds?.at(0)?.goal?.toString()}
      loading={isLoadingOrgScore}
      mainComponent={
        <TeamsSummary />
      }
      subtitle='pages.securityImpactV3.components.teams.subTitle'
      thresholds={thresholdValue}
      title='pages.securityImpactV3.components.teams.title'
      trend={trend}
      value={formattedValue}
    />
  );
};
