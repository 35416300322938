import { Avatar, AvatarGroup } from '@mui/material';
import { FC, useMemo } from 'react';

import styles from './JitAvatarGroup.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

export type AvatarGroupMember = {
  avatarUrl: string;
  name: string;
  id: string;
};

interface Props {
  members: AvatarGroupMember[];
  maxMembersToDisplay: number;
  mode?: 'group' | 'individual';
}

const AVATAR_GROUP_SIZE = 17;
const AVATAR_INDIVIDUAL_SIZE = 24;

export const JitAvatarGroup: FC<Props> = ({ members, maxMembersToDisplay, mode = 'group' }) => {
  const displayedMembers = useMemo(() => members.slice(0, maxMembersToDisplay), [members, maxMembersToDisplay]);

  return (
    <div className={styles.root} style={{ gap: mode === 'group' ? 0 : 10 }}>
      {mode === 'group' ? (
        <AvatarGroup
          data-testid='group-avatar'
          max={displayedMembers.length}
          sx={{ '& .MuiAvatar-root': { width: AVATAR_GROUP_SIZE,
            height: AVATAR_GROUP_SIZE,
            fontSize: 10,
            position: 'unset' } }}
          total={displayedMembers.length}
        >
          {displayedMembers.map((member) => (
            <Avatar
              key={member.id}
              alt={member.name}
              data-testid={`avatar-${member.id}`}
              src={member.avatarUrl}
              sx={{
                width: AVATAR_GROUP_SIZE,
                height: AVATAR_GROUP_SIZE,
              }}
            />
          ))}
        </AvatarGroup>
      ) : (
        <div className={styles.wrapper}>
          {displayedMembers.map((member) => (
            <JitTooltip
              key={member.id}
              followCursor={false}
              offset={[0, 4]}
              placement='top'
              title={member.name}
            >
              <Avatar
                src={member.avatarUrl}
                sx={{
                  width: AVATAR_INDIVIDUAL_SIZE,
                  height: AVATAR_INDIVIDUAL_SIZE,
                }}
              />
            </JitTooltip>
          ))}
        </div>
      )}

      {members.length > maxMembersToDisplay && (
      <JitText color={colors.lightGray} horizontalAlign='center' muted size={mode === 'group' ? 's' : 'm'} text={`+${members.length - maxMembersToDisplay}`} />
      )}
    </div>
  );
};
