import { t } from 'i18next';

import { BaseMetric, OrgScoreMetric } from './types';

import { DateBasedChartData } from 'services/MetricService/types';
import { ScoreTrendMetric } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';

const WEEKS_TO_SHOW = 6;
const WEEK_LENGTH = 7;

const normalizeMetricData = (data: DateBasedChartData | undefined) => {
  if (!data?.data || !data.data.length) return [];

  const firstDataPoint = data.data[0];
  const valueFieldName = Object.keys(firstDataPoint).find((key) => key !== 'date');

  if (!valueFieldName) return [];

  return data.data.map((day) => day[valueFieldName]).slice(-WEEKS_TO_SHOW);
};

const calculateWeeklyScores = (dailyScores: { date: string; value: number | null }[]) => {
  const recentScores = dailyScores.slice(-(WEEKS_TO_SHOW * WEEK_LENGTH));
  const availableWeeks = Math.ceil(recentScores.length / WEEK_LENGTH);

  return Array(availableWeeks).fill(0).map((_, weekIndex) => {
    const weekScores = recentScores
      .slice(weekIndex * WEEK_LENGTH, (weekIndex + 1) * WEEK_LENGTH)
      .map((day) => day.value);

    if (weekScores.every((score) => score === null)) {
      return null;
    }

    const validScores = weekScores.filter((v): v is number => v !== null);
    return validScores.length > 0
      ? Math.round(validScores.reduce((sum, score) => sum + score, 0) / validScores.length)
      : null;
  });
};

export const calculateMetricScores = (
  baseMetrics: Record<BaseMetric, DateBasedChartData | undefined>,
  orgScoreTrendingData: ScoreTrendMetric | undefined,
) => {
  const normalizeOrgScoreData = (data: ScoreTrendMetric | undefined) => {
    if (!data?.entitiesScores?.length) return [];

    const allDates = new Set<string>();
    data.entitiesScores.forEach((entity) => entity.scoresByDay.forEach((day) => allDates.add(day.date)));

    const sortedDates = Array.from(allDates).sort();

    return sortedDates.map((date) => {
      const scoresForDate = data.entitiesScores
        .map((entity) => entity.scoresByDay.find((day) => day.date === date)?.score)
        .filter((score): score is number => score !== null && score !== undefined && !Number.isNaN(score));

      return {
        date,
        value: scoresForDate.length > 0
          ? Math.round(scoresForDate.reduce((sum, score) => sum + score, 0) / scoresForDate.length)
          : null,
      };
    });
  };

  const weeklyMetricScores = {
    mttr: normalizeMetricData(baseMetrics.mttr),
    shiftLeft: normalizeMetricData(baseMetrics.shiftLeft),
    prioritizedFindings: normalizeMetricData(baseMetrics.prioritizedFindings),
    resourcesCoverage: normalizeMetricData(baseMetrics.resourcesCoverage),
    riskRemediation: normalizeMetricData(baseMetrics.riskRemediation),
    criticalAssetSecurity: normalizeMetricData(baseMetrics.criticalAssetSecurity),
    teamScores: calculateWeeklyScores(normalizeOrgScoreData(orgScoreTrendingData)),
  };

  const lengthValues = Object.values(weeklyMetricScores).map((scores) => scores.length);
  const availableWeeks = lengthValues.length > 0 ? Math.max(...lengthValues) : 0;

  const metricScores = Object.entries(weeklyMetricScores).map(([metric, weekScores]) => {
    const validWeekScores = weekScores.filter((score) => score !== null && !Number.isNaN(score)) as number[];

    const score = validWeekScores.length > 0
      ? validWeekScores[validWeekScores.length - 1]
      : 0;

    const firstValidScore = validWeekScores[0] ?? 0;
    const lastValidScore = validWeekScores[validWeekScores.length - 1] ?? 0;
    const change = lastValidScore - firstValidScore;

    const safeChange = Number.isNaN(change) ? 0 : change;

    return {
      metric: metric as OrgScoreMetric,
      score,
      change: safeChange,
      label: t(`pages.securityImpact.orgScore.${metric}`),
    };
  });

  const weeklyScores = Array(availableWeeks).fill(0).map((_, weekIndex) => {
    const weekMetricScores = Object.values(weeklyMetricScores).map((scores) => scores[weekIndex]);

    if (weekMetricScores.every((score) => score === null || score === undefined || Number.isNaN(score))) {
      return {
        week: `w${weekIndex + 1}`,
        avgScore: null,
        days: Array(WEEK_LENGTH).fill(null).map(() => ({
          date: new Date(Date.now() - (((availableWeeks * WEEK_LENGTH - 1) - (weekIndex * WEEK_LENGTH)) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
          score: null,
        })),
      };
    }

    const validScores = weekMetricScores.filter((score): score is number => score !== null && score !== undefined && !Number.isNaN(score));

    const avgScore = validScores.length > 0
      ? Math.round(validScores.reduce((sum, score) => sum + score, 0) / validScores.length)
      : null;

    const safeAvgScore = Number.isNaN(avgScore) ? null : avgScore;

    return {
      week: `w${weekIndex + 1}`,
      avgScore: safeAvgScore,
      days: Array(WEEK_LENGTH).fill(null).map(() => ({
        date: new Date(Date.now() - (((availableWeeks * WEEK_LENGTH - 1) - (weekIndex * WEEK_LENGTH)) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
        score: safeAvgScore,
      })),
    };
  });

  const currentScore = weeklyScores.at(-1)?.avgScore ?? 0;
  const firstWeekScore = weeklyScores.at(0)?.avgScore ?? 0;

  const safeCurrentScore = Number.isNaN(currentScore) ? 0 : currentScore;
  const safeFirstWeekScore = Number.isNaN(firstWeekScore) ? 0 : firstWeekScore;

  const scoreChange = Number.isNaN(safeCurrentScore - safeFirstWeekScore) ? 0 : (safeCurrentScore - safeFirstWeekScore);

  const trends = metricScores
    .filter((score) => score.score > 0 && !Number.isNaN(score.score))
    .sort((a, b) => {
      const scoreComparison: number = b.change - a.change;
      if (scoreComparison !== 0) return scoreComparison;
      return a.label.localeCompare(b.label);
    })
    .slice(0, 2)
    .map(({ label, change }) => ({
      label,
      value: Number.isNaN(change) ? 0 : change,
    }));

  return {
    currentScore: safeCurrentScore,
    scoreChange,
    trends,
    metricScores,
    scoreTrendingEntity: {
      entityId: 'org-score',
      weeks: weeklyScores,
    },
  };
};
