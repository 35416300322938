import { FC, useCallback, useMemo, useState } from 'react';

import { useGetProjectsMenuItems } from '../useGetMenuItems/useGetProjectsMenuItems';
import { useGetReposMenuItems } from '../useGetMenuItems/useGetReposMenuItems';

import { NotificationsSelect } from './components/NotificationsSelect/NotificationsSelect';

import { ActivateIllustration, ExploreIllustration, JiraProjectSelection, PrEnablementIllustration, SlackIllustration } from 'assets';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { TaskDialog } from 'components/TaskDialog/TaskDialog';
import { TaskDialogImage } from 'components/TaskDialog/TaskDialogImage';
import { TaskDialogTask } from 'components/TaskDialog/TaskDialogTask';
import { useGetActiveIntegration } from 'context/IntegrationsContext/hooks/useGetActiveIntegration';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { MenuItemKeyType } from 'types/interfaces';
import { ICommunicationPlatformValue, ITeamCheckDetails, ProjectValue, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

/**
 * BaseTaskDialog component
 *
 * Updated to support all TMS integrations, not just Jira.
 * Now uses the vendor from the active integration when creating project tasks.
 */
interface Props {
  isOpen: boolean;
  check: ITeamCheckDetails;
  handleClose: () => void;
  teamId: string;
  updateCheck: (check: ITeamCheckDetails, value?: string | ProjectValue) => void;
}

export interface MenuItemsData {
  menuItems: Partial<MenuItemType>[];
  isLoading: boolean;
}

export const BaseTaskDialog: FC<Props> = ({ isOpen, check, handleClose, teamId, updateCheck }) => {
  const [selectedItem, setSelectedItem] = useState<MenuItemKeyType | ICommunicationPlatformValue>(() => {
    if (typeof check?.value === 'string') {
      return check.value;
    }
    if (check?.value && 'name' in check.value) {
      return check.value.name;
    }
    return check?.value || '';
  });

  const [isLoading, setIsLoading] = useState(false);
  const { updateTeamChecks } = useTeamsService();
  const { getIconByVendor } = useDetailsByVendor();

  // Get the active TMS integration vendor
  const { activeIntegration } = useGetActiveIntegration(
    (integration) => integration.display?.categoryKey === IntegrationVendorType.tms,
  );
  const tmsVendor = activeIntegration?.vendor as Vendor || Vendor.Jira;
  const vendorIcon = getIconByVendor(tmsVendor, undefined, true);

  const isDisabled = useMemo(() => {
    if (typeof selectedItem === 'object') {
      return !selectedItem.channelId;
    }
    return !selectedItem;
  }, [selectedItem]);

  const getUpdateBodyForTask = useCallback(() => [{
    check_slug: check?.checkSlug,
    value: check?.checkSlug === TaskKeyEnum.prCheckEnablement
      ? true
      : check?.checkSlug === TaskKeyEnum.selectProject
        ? {
          name: String(selectedItem),
          vendor: tmsVendor, // Support for any TMS vendor, not just Jira
        } as ProjectValue
        : typeof selectedItem === 'object'
          ? { ...selectedItem } as ProjectValue
          : selectedItem,
    is_completed: true,
  }], [check?.checkSlug, selectedItem, tmsVendor]);

  const handleClick = useCallback(async () => {
    const postUpdateCheckCallbacks: { [checkSlug: string]: (updatedCheck: ITeamCheckDetails) => void } = {
      [TaskKeyEnum.exploreDeveloper]: (updatedCheck: ITeamCheckDetails) => {
        if (updatedCheck.metadata?.url) window.open(updatedCheck.metadata?.url, '_blank');
      },
    };

    setIsLoading(true);
    const result = await updateTeamChecks(teamId, getUpdateBodyForTask());

    result?.forEach((updatedCheck) => {
      const { value } = updatedCheck;
      // Ensure we only pass ProjectValue or string to updateCheck
      const updateValue = typeof value === 'object' && 'name' in value ? value : undefined;
      updateCheck(updatedCheck, updateValue);

      if (updatedCheck && postUpdateCheckCallbacks[updatedCheck.checkSlug]) {
        postUpdateCheckCallbacks[updatedCheck.checkSlug](updatedCheck);
      }
    });
    setIsLoading(false);
    handleClose();
  }, [updateTeamChecks, teamId, getUpdateBodyForTask, handleClose, updateCheck]);

  const keyToMenuItems: Record<string, { data: (selectedItem?: MenuItemKeyType) => MenuItemsData, type: 'multiSelect' | 'dropdown' }> = useMemo(() => ({
    [TaskKeyEnum.configureSlack]: {
      data: () => ({
        menuItems: [],
        isLoading: false,
      }),
      type: 'multiSelect',
    },
    [TaskKeyEnum.exploreDeveloper]: {
      data: useGetReposMenuItems,
      type: 'dropdown',
    },
    [TaskKeyEnum.selectProject]: {
      data: useGetProjectsMenuItems,
      type: 'dropdown',
    },
  }), []);

  const onMenuItemClick = useCallback(({ itemKey }: { itemKey: MenuItemKeyType }) => {
    setSelectedItem(itemKey);
  }, []);

  const keyToMenuItemsData = keyToMenuItems[check.checkSlug]?.data;
  const { menuItems, isLoading: isMenuLoading } = keyToMenuItemsData?.(selectedItem as MenuItemKeyType) ?? {
    menuItems: [],
    isLoading: false,
  };

  const fields = check.options?.fields;
  const activeFields = (check.isCompleted && fields?.configure) || fields?.activate;

  const menuComponent = useMemo(() => {
    if (!activeFields) return null;

    if (keyToMenuItems[check.checkSlug]?.type === 'multiSelect') {
      return (
        <NotificationsSelect
          onChange={(value: ICommunicationPlatformValue) => setSelectedItem(value)}
          value={selectedItem as ICommunicationPlatformValue}
        />
      );
    }
    if (keyToMenuItems[check.checkSlug]?.type === 'dropdown') {
      return (
        <JitDropdownNew
          borderColor={colors.darkGray03}
          defaultDisplayText={activeFields.select?.placeholder}
          displayText={selectedItem as string}
          height={40}
          isLoading={isMenuLoading}
          menuItems={menuItems}
          onMenuItemClick={onMenuItemClick}
          padding={20}
        />
      );
    }
    return null;
  }, [activeFields, check.checkSlug, isMenuLoading, keyToMenuItems, menuItems, onMenuItemClick, selectedItem]);

  // Get the appropriate illustration based on task type
  const getTaskImage = useCallback(() => {
    switch (check.checkSlug) {
      case TaskKeyEnum.selectProject:
        // For project selection, show vendor icon if available instead of Jira-specific image
        if (vendorIcon) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60px',
              }}
            >
              <JitIcon icon={vendorIcon} size={42} />
            </div>
          );
        }
        return <TaskDialogImage src={JiraProjectSelection} />;
      case TaskKeyEnum.configureSlack:
        return <TaskDialogImage src={check.isCompleted ? SlackIllustration : ActivateIllustration} />;
      case TaskKeyEnum.prCheckEnablement:
        return <TaskDialogImage src={PrEnablementIllustration} />;
      case TaskKeyEnum.exploreDeveloper:
        return <TaskDialogImage src={ExploreIllustration} />;
      default:
        return null;
    }
  }, [check.checkSlug, check.isCompleted, vendorIcon]);

  // Original TaskDialog structure to ensure compatibility
  const leftComponent = (
    <TaskDialogTask
      buttonLabel={activeFields?.button?.label}
      description={activeFields?.description || ''}
      handleClick={handleClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
      testId={`action-${check.checkSlug}`}
      title={activeFields?.title || ''}
    >
      {menuComponent}
    </TaskDialogTask>
  );

  const rightComponent = getTaskImage();

  return (
    <TaskDialog
      handleClose={handleClose}
      isOpen={isOpen}
      leftComponent={leftComponent}
      rightComponent={rightComponent}
    />
  );
};
