import { isEmpty } from 'lodash';
import { FC } from 'react';

import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { TicketsList } from 'components/TicketsList/TicketsList';
import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
}

export const TicketManagement: FC<Props> = ({ finding }) => {
  if (!finding.tickets || finding.tickets.length === 0) {
    return null;
  }

  return (
    <JitSidePanelItem title='pages.findings.findingDetails.ticketManagement.title' variant='light'>
      {!isEmpty(finding.tickets) && <TicketsList showStatus tickets={finding.tickets!} />}
    </JitSidePanelItem>
  );
};
