import { useMemo } from 'react';

import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { IntegrationTemplate } from 'context/IntegrationsContext/templates/interfaces';
import { IIntegration } from 'types/interfaces/Integrations/IIntegration';

export const useIntegrationProjects = (
  integrationTemplate: IntegrationTemplate | undefined,
  integrations: IIntegration[] | undefined,
) => {
  // Get Paragon user data for configurations
  const { user } = useParagonAuth();

  // Determine if this integration supports multiple projects
  const supportsMultipleProjects = useMemo(
    () => {
      if (!integrationTemplate || !integrations || !integrationTemplate.vendor) {
        return false;
      }

      // Find the matching integration by vendor (key)
      const matchingIntegration = integrations.find(
        (integration) => integration.vendor && integration.vendor.toLowerCase() === integrationTemplate.vendor.toLowerCase(),
      );

      // Check if this specific integration supports multiple projects
      // Property is defined as 'supportMultipleProjects' in the IExtraDisplayProps interface
      return Boolean(matchingIntegration?.display?.extras?.supportMultipleProjects);
    },
    [integrationTemplate, integrations],
  );

  // Get projects from Paragon configurations
  const availableProjects = useMemo(() => {
    if (!integrationTemplate || !user?.authenticated || !integrationTemplate.vendor) {
      return [];
    }

    const vendor = integrationTemplate.vendor.toLowerCase();
    const vendorIntegration = user.integrations?.[vendor];

    if (!vendorIntegration) {
      return [];
    }

    // Get all configurations for this vendor
    const configs = vendorIntegration.allConfigurations || {};
    const projects = Object.values(configs).map((config) => ({
      id: config.id || '',
      name: config.externalId || 'Default project',
      dateCreated: config.dateCreated ? new Date(config.dateCreated).toISOString() : new Date().toISOString(),
      externalId: config.externalId,
    }));

    return projects;
  }, [integrationTemplate, user]);

  return {
    supportsMultipleProjects,
    availableProjects,
  };
};

