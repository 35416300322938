import { FC, useMemo } from 'react';

import { prepareOrgScoreChartData } from './utils/prepareTeamsScoreChartData';

import { Chart } from 'components/Chart/Chart';
import { Section } from 'components/MetricDetails/components/Section/Section';
import { useOrgScoreTrending } from 'services/MetricService/hooks';
import { TimeAgo } from 'types/enums';

interface TeamsScoreDetailsProps {
  timeRange: TimeAgo;
}

export const TeamsScoreDetails: FC<TeamsScoreDetailsProps> = ({ timeRange }) => {
  const { data: orgScoreData, isLoading } = useOrgScoreTrending(timeRange);

  // Prepare organization score chart data
  const chartData = useMemo(
    () => prepareOrgScoreChartData(orgScoreData, timeRange),
    [orgScoreData, timeRange],
  );

  // Only render the chart if we have data
  if (!chartData) {
    return null;
  }

  return (
    <Section
      subTitle='pages.securityImpactV3.components.teams.details.subtitle'
      title='pages.securityImpactV3.components.teams.details.title'
    >
      <Chart data={chartData} loading={isLoading} />
    </Section>
  );
};

