/* eslint-disable react/jsx-no-literals */
import { ArrowDropDown, ArrowDropUp, Remove } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Trends.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Trend {
  label: string;
  value: number;

}

interface Props {
  trends: Trend[];
}

export const Trends: FC<Props> = ({ trends }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.trendsList}>
      {trends.length > 0 ? (
        trends.map((trend) => (
          <div key={`trend-${trend.label}`} className={styles.trendItem} data-testid='trend-item'>
            <span className={`${styles.trendArrowContainer} ${trend.value > 0 ? styles.positive : trend.value < 0 ? styles.negative : styles.static}`}>
              {trend.value > 0 ? <ArrowDropUp fontSize='small' /> : trend.value < 0 ? <ArrowDropDown fontSize='small' /> : <Remove fontSize='small' />}
            </span>

            <JitText color={colors.lightGray} component='span' size='m' text={trend.label} />

            <JitText color={colors.lightGray} component='span' size='m' text={`${trend.value > 0 ? '+' : ''}${trend.value?.toFixed(0)}%`} />

          </div>
        ))
      ) : (
        <div className={styles.noTrends} data-testid='no-trends-message'>
          {t('pages.securityImpact.orgScore.noTrends').toString()}
        </div>
      )}
    </div>
  );
};
