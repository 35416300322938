import { SxProps, Theme } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

// Common style patterns
const commonStyles = {
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  borderCommon: {
    border: `1px solid ${colors.borderColorAlpha}`,
  },
  borderTop: {
    borderTop: `1px solid ${colors.borderColorAlphaStrong}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${colors.borderColorAlphaStrong}`,
  },
  roundedBox: {
    borderRadius: '10px',
  },
  buttonBase: {
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontWeight: 500,
  },
  buttonPrimary: {
    backgroundColor: colors.blue,
    border: 'none',
    borderRadius: '8px',
    color: colors.white,
    fontSize: '0.95rem',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  buttonSecondary: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    fontSize: '0.95rem',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  modalPadding: {
    padding: '20px 28px',
  },
};

export const styles = {
  dialog: {
    backgroundColor: colors.modalBg,
    color: colors.white,
    borderRadius: '12px',
    maxWidth: '800px',
    minHeight: '600px',
    width: '100%',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
  },
  enhancedDialog: {
    backgroundColor: colors.modalBg,
    borderRadius: '12px',
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.6)',
    border: `1px solid ${colors.borderColorAlpha}`,
    overflow: 'hidden',
    maxWidth: '800px',
    width: '100%',
    minHeight: '300px',
  },
  dialogTitle: {
    ...commonStyles.flexBetween,
    padding: '20px 24px',
    ...commonStyles.borderBottom,
    backgroundColor: colors.modalBg,
  },
  enhancedDialogTitle: {
    ...commonStyles.borderBottom,
    ...commonStyles.modalPadding,
    ...commonStyles.flexBetween,
    backgroundColor: colors.modalBg,
  },
  dialogTitleText: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: colors.white,
    letterSpacing: '0.01em',
  },
  dialogContent: {
    padding: 0,
    backgroundColor: 'transparent',
  },
  enhancedDialogContent: {
    padding: '24px 28px 20px',
    backgroundColor: colors.modalBg,
  },
  dialogContentWithPadding: {
    padding: '28px',
    backgroundColor: colors.modalBg,
    minHeight: '120px',
  },
  dialogFooter: {
    ...commonStyles.flexBetween,
    ...commonStyles.modalPadding,
    backgroundColor: colors.modalBg,
    ...commonStyles.borderTop,
  },
  enhancedDialogFooter: {
    ...commonStyles.borderTop,
    ...commonStyles.modalPadding,
    gap: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colors.modalBg,
    alignItems: 'center',
  },
  dialogFooterEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: '24px 28px',
    backgroundColor: colors.modalBg,
    ...commonStyles.borderTop,
  },
  closeButton: {
    color: colors.textAlpha70,
    '&:hover': {
      color: colors.white,
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    padding: '8px',
    borderRadius: '50%',
  },
  description: {
    fontSize: '16px',
    color: colors.textAlpha70,
    marginBottom: '28px',
    lineHeight: 1.5,
    padding: '0 4px',
  },
  enhancedDescription: {
    fontSize: '1rem',
    color: colors.textAlpha80,
    marginBottom: '28px',
    lineHeight: 1.6,
    paddingLeft: '4px',
    marginTop: '40px',
  },
  contentContainer: {
    padding: '0',
    color: colors.white,
    backgroundColor: 'transparent',
    ...commonStyles.flexColumn,
    height: '100%',
  },
  loadingContainer: {
    ...commonStyles.flexCenter,
    padding: '60px 0',
    color: colors.textAlpha70,
  },
  noProjectsContainer: {
    ...commonStyles.flexCenter,
    padding: '80px 0',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    border: '1px dashed rgba(75, 85, 99, 0.3)',
    marginTop: '20px',
  },
  noProjectsText: {
    color: colors.textAlpha70,
    fontSize: '1.05rem',
    fontWeight: 500,
  },
  flexColumn: {
    ...commonStyles.flexColumn,
    flex: 1,
  },
  flexGrow: {
    flex: 1,
  },
  projectsContainer: {
    ...commonStyles.flexColumn,
    gap: '16px',
    marginBottom: '16px',
    maxHeight: '400px',
    overflowY: 'auto',
    paddingRight: '12px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.borderColorAlphaStrong,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: colors.borderColorAlphaHoverStrong,
    },
  },
  projectCard: {
    backgroundColor: colors.cardBg,
    ...commonStyles.roundedBox,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: colors.cardHoverBg,
    },
  },
  enhancedProjectCard: {
    ...commonStyles.roundedBox,
    ...commonStyles.borderCommon,
    backgroundColor: colors.cardBg,
    transition: 'all 0.25s ease-in-out',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      borderColor: colors.borderColorAlphaHover,
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.25)',
      transform: 'translateY(-2px)',
    },
  },
  projectCardHeader: {
    ...commonStyles.flexBetween,
  },
  enhancedProjectCardHeader: {
    padding: '20px 24px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  projectTitle: {
    fontWeight: 600,
    fontSize: '16px',
    color: colors.white,
    marginBottom: '4px',
  },
  enhancedProjectTitle: {
    fontSize: '1.15rem',
    fontWeight: 600,
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
  },
  defaultProjectBadge: {
    fontSize: '0.75rem',
    fontWeight: 500,
    backgroundColor: colors.buttonPrimaryAlpha10,
    color: colors.blue02,
    borderRadius: '4px',
    padding: '3px 8px',
    marginLeft: '12px',
    letterSpacing: '0.02em',
    border: `1px solid ${colors.buttonPrimaryAlpha25}`,
  },
  projectDate: {
    fontSize: '0.8rem',
    color: colors.textAlpha50,
  },
  enhancedProjectDate: {
    fontSize: '0.85rem',
    color: colors.textAlpha50,
    marginTop: '6px',
  },
  buttonGroup: {
    display: 'flex',
    gap: '10px',
  },
  enhancedButtonGroup: {
    display: 'flex',
    gap: '8px',
  },
  outlinedButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.borderColorAlpha}`,
    borderRadius: '6px',
    padding: '8px 16px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: colors.borderColorAlphaHoverStrong,
      color: colors.white,
    },
  },
  disconnectButton: {
    color: colors.failRed,
    borderColor: colors.dangerAlpha30,
    backgroundColor: 'transparent',
    padding: '6px 16px',
    borderRadius: '6px',
    fontSize: '0.85rem',
    fontWeight: 500,
    transition: 'all 0.2s ease',
    border: '1px solid',
    '&:hover': {
      borderColor: colors.dangerAlpha70,
      backgroundColor: colors.dangerAlpha10,
      color: colors.failRed,
    },
  },
  configureButton: {
    fontSize: '0.85rem',
    padding: '6px 14px',
    borderRadius: '40px',
    color: colors.blue02,
    backgroundColor: colors.buttonPrimaryAlpha10,
    border: 'none',
    fontWeight: 500,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: colors.buttonPrimaryAlpha20,
      color: colors.iris,
    },
    minWidth: '105px',
    justifyContent: 'center',
  },
  deleteButton: {
    fontSize: '0.85rem',
    padding: '6px 14px',
    borderRadius: '40px',
    color: colors.failRed,
    backgroundColor: colors.dangerAlpha10,
    border: 'none',
    fontWeight: 500,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: colors.dangerAlpha20,
      color: colors.dangerText,
    },
    minWidth: '80px',
    justifyContent: 'center',
  },
  createButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.blue02,
    cursor: 'pointer',
    fontSize: '0.95rem',
    fontWeight: 500,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    transition: 'color 0.2s ease',
    '&:hover': {
      color: colors.white,
    },
  },
  createProjectButton: {
    ...commonStyles.buttonSecondary,
    backgroundColor: colors.buttonPrimaryAlpha10,
    color: colors.blue02,
    border: `1px solid ${colors.buttonPrimaryAlpha25}`,
    padding: '10px 16px',
    fontSize: '0.9rem',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: colors.buttonPrimaryAlpha20,
      borderColor: colors.buttonPrimaryAlpha40,
    },
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  plusIcon: {
    marginRight: '4px',
    fontSize: '16px',
  },
  primaryButton: {
    ...commonStyles.buttonPrimary,
    padding: '12px 24px',
    '&:hover': {
      backgroundColor: colors.buttonHover,
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    '&.Mui-disabled': {
      opacity: 0.7,
      color: colors.white,
      backgroundColor: colors.buttonPrimaryAlpha50,
    },
  },
  enhancedPrimaryButton: {
    ...commonStyles.buttonPrimary,
    padding: '10px 24px',
    boxShadow: `0 2px 10px ${colors.buttonPrimaryAlpha20}`,
    minWidth: '90px',
    '&:hover': {
      backgroundColor: colors.buttonHover,
      transform: 'translateY(-1px)',
      boxShadow: `0 4px 12px ${colors.buttonPrimaryAlpha25}`,
    },
    textAlign: 'center',
    justifyContent: 'center',
  },
  disabledButton: {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
  secondaryButton: {
    ...commonStyles.buttonSecondary,
    border: '1px solid rgba(255, 255, 255, 0.2)',
    color: colors.textAlpha80,
    padding: '12px 24px',
    '&:hover': {
      color: colors.white,
      borderColor: 'rgba(255, 255, 255, 0.4)',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  },
  dangerButton: {
    backgroundColor: colors.dangerAlpha90,
    boxShadow: `0 2px 10px ${colors.dangerShadowLight}`,
    '&:hover': {
      backgroundColor: colors.failRed,
      boxShadow: `0 4px 12px ${colors.dangerShadowMedium}`,
    },
  },
  formContainer: {
    ...commonStyles.flexColumn,
    color: colors.white,
    backgroundColor: 'transparent',
    minHeight: '300px',
    width: '100%',
  },
  formContent: {
    padding: '24px',
    flex: 1,
  },
  formLabel: {
    display: 'block',
    marginBottom: '12px',
    color: colors.textAlpha90,
    fontSize: '1rem',
    fontWeight: 500,
  },
  formInput: {
    width: '100%',
    backgroundColor: 'rgba(31, 41, 55, 0.5)',
    border: `1px solid ${colors.borderColorAlpha}`,
    borderRadius: '8px',
    color: colors.white,
    '& .MuiOutlinedInput-root': {
      color: colors.white,
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: colors.borderColorAlphaStrong,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.buttonPrimaryAlpha50,
        borderWidth: '1px',
      },
    },
    '& .MuiInputBase-input': {
      padding: '16px 18px',
      height: '24px',
      fontSize: '1rem',
    },
    '& .MuiFormHelperText-root': {
      color: colors.error,
      marginTop: '8px',
      fontSize: '0.85rem',
    },
  },
  errorText: {
    color: colors.error,
    marginTop: '8px',
    fontSize: '0.85rem',
    fontWeight: 500,
  },
  divider: {
    height: '1px',
    backgroundColor: colors.borderColorAlpha,
    width: '100%',
    marginTop: 'auto',
  },
  // Fix for array-style sx props
  combinedProjectTitle: {
    fontSize: '1.15rem',
    fontWeight: 600,
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
  },
  combinedDangerButton: {
    backgroundColor: colors.dangerAlpha90,
    boxShadow: `0 2px 10px ${colors.dangerShadowLight}`,
    '&:hover': {
      backgroundColor: colors.failRed,
      boxShadow: `0 4px 12px ${colors.dangerShadowMedium}`,
    },
    border: 'none',
    borderRadius: '8px',
    color: colors.white,
    cursor: 'pointer',
    fontSize: '0.95rem',
    fontWeight: 500,
    padding: '10px 24px',
    transition: 'all 0.2s ease',
    minWidth: '84px',
    justifyContent: 'center',
  },
} as const;

export type StyleKey = keyof typeof styles;

export const sx = (key: StyleKey): SxProps<Theme> => styles[key] as SxProps<Theme>;
