import { useSecurityCoverageActions } from './useSecurityCoverageActions';

import { Actions as ActionsComponent } from 'components/MetricDetails/components/Actions/Actions';
import { useResourcesCoverage } from 'services/MetricService/hooks';

export const Actions = () => {
  const { data: coverage } = useResourcesCoverage();
  const actions = useSecurityCoverageActions(coverage);
  return <ActionsComponent actions={actions} />;
};
