import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useParagonProxy } from './useParagonProxy';

import { logError } from 'services/logger/logger';
import { ICardFieldProps, MappingValue } from 'types/interfaces/Integrations/IIntegration';

interface Props {
  integrationId: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  integrationKey: string;
  fields: ICardFieldProps[];
  mapping?: Record<string, MappingValue>;
  onSuccess: () => void;
}

export const useCustomCardIntegration = ({
  integrationId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  integrationKey,
  fields,
  mapping,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const { connectToParagonProxy, isLoading } = useParagonProxy();
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [error, setError] = useState<string | null>(null);

  // Check if all required fields have values - simple calculation, no need for memoization
  const isFormValid = fields.every((field) => formValues[field.name] && formValues[field.name].trim() !== '');

  const handleInputChange = useCallback((fieldName: string, value: string) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

    // Clear error when user starts editing
    setError(null);
  }, []);

  // eslint-disable-next-line consistent-return
  const handleSubmit = useCallback((e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (isFormValid) {
      // Clear any previous errors
      setError(null);

      return connectToParagonProxy(
        {
          integrationId,
          formValues,
          mapping,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
          onError: (err: unknown) => {
            logError(`Error in form submission: ${err}`);
            const errorMessage = err instanceof Error ? err.message : t('customCardIntegration.errors.credentialsFailed');
            setError(errorMessage);
          },
        },
      );
    }
  }, [isFormValid, connectToParagonProxy, integrationId, formValues, mapping, onSuccess, t]);

  return {
    formValues,
    handleInputChange,
    handleSubmit,
    isLoading,
    error,
    isFormValid,
  };
};
