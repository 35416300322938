import { useMemo } from 'react';

import { CircleFailure, WhiteDotCheck } from 'assets';
import { IntegrationTemplate, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { getIntegrationTemplateVendor } from 'pages/IntegrationsPage/utils/getIntegrationTemplateVendor';
import { IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

export const useStatusIcon = (
  integrationTemplate: IntegrationTemplate | undefined,
  integrationStatus: IntegrationStatus | undefined,
  isScmVendorIntegrated: (vendor: string) => boolean,
) => useMemo(() => {
  const isSCMIntegration = integrationTemplate?.vendorType === IntegrationVendorType.sourceCodeManagement;

  if (isSCMIntegration) {
    const isSuccessIntegration = integrationStatus === IntegrationStatus.SUCCESS;
    const isSCMVendorIntegrated = isScmVendorIntegrated(getIntegrationTemplateVendor(integrationTemplate.vendor));
    return isSCMVendorIntegrated && isSuccessIntegration ? WhiteDotCheck : undefined;
  }

  return integrationStatus === IntegrationStatus.SUCCESS ? WhiteDotCheck : CircleFailure;
}, [isScmVendorIntegrated, integrationTemplate, integrationStatus]);
