import { FC } from 'react';

import styles from './TeamCard.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitAvatarGroup } from 'components/JitAvatarGroup/JitAvatarGroup';
import { JitGrade } from 'components/JitGrade/JitGrade';
import { JitRank } from 'components/JitRank/JitRank';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';

interface Props {
  team: IGetTeamResponseItem;
  rank?: number;
  isLoadingRank?: boolean;
}

export const TeamCard: FC<Props> = ({ team, rank, isLoadingRank }) => (
  <div className={styles.root} data-testid='team-card'>
    <div className={styles.placementWrapper}>
      {isLoadingRank || !rank ? <CircularLoadingSpinner /> : <JitRank rank={rank} />}
    </div>

    <div className={styles.teamNameWrapper}>
      <div className={styles.textWrapper}>
        <TooltipOnlyOnOverflow tooltipText={team.name}>
          <JitText noWrap overflowHiddenEllipsis size='l' text={team.name} />
        </TooltipOnlyOnOverflow>
      </div>

      <JitAvatarGroup maxMembersToDisplay={7} members={team.members ?? []} />
    </div>

    <div className={styles.placementWrapper}>
      <JitGrade score={team.score} />
    </div>
  </div>
);
