import { useMemo } from 'react';

import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { Action } from 'components/MetricDetails/components/Actions/Actions';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { useTenantContext } from 'context/TenantContext';
import { AssetType, Dialogs } from 'types/enums';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';

const isValidTab = (scmVendor: string | undefined): scmVendor is TABS => Object.values(TABS).includes(scmVendor as TABS);

export const useSecurityCoverageActions = (coverage?: IResourcesCoverage): Action[] => {
  const { showDialog } = useDialogsContext();
  const { currentScmVendor } = useTenantContext();

  const assetTypeToTabMap: Record<AssetType, TABS> = useMemo(() => {
    const scmVendor = isValidTab(currentScmVendor) ? currentScmVendor : TABS.GITHUB; // Default to GitHub if invalid
    return ({
      [AssetType.REPO]: scmVendor,
      [AssetType.AWS_ACCOUNT]: TABS.AWS,
      [AssetType.AWS_ORG]: TABS.AWS,
      [AssetType.GCP_ACCOUNT]: TABS.GCP,
      [AssetType.AZURE_ACCOUNT]: TABS.AZURE,
      [AssetType.API]: TABS.API,
      [AssetType.WEB]: TABS.WEB,
      [AssetType.ORG]: scmVendor,
      [AssetType.APPLICATION]: TABS.API,
      [AssetType.IMAGE]: TABS.AWS,
    });
  }, [currentScmVendor]);

  return useMemo(() => {
    if (!coverage || !coverage.resourcesCoverage || coverage.resourcesCoverage.length === 0) {
      // Return empty array if no coverage data is available
      return [];
    }

    // Calculate coverage ratio for each resource type
    const resourcesWithCoverageRatio = coverage.resourcesCoverage.map((resource) => {
      const total = resource.covered + resource.notCovered;
      const coverageRatio = total > 0 ? resource.covered / total : 1; // If no resources, consider fully covered

      return {
        assetType: resource.assetType,
        coverageRatio,
        covered: resource.covered,
        notCovered: resource.notCovered,
        total,
      };
    });

    // Filter out resources with 100% coverage or no resources to cover
    const resourcesWithGaps = resourcesWithCoverageRatio.filter(
      (resource) => resource.total > 0 && resource.coverageRatio < 1,
    );

    // If no resources with gaps, return empty array
    if (resourcesWithGaps.length === 0) {
      return [];
    }

    // Sort by coverage ratio (ascending)
    resourcesWithGaps.sort((a, b) => a.coverageRatio - b.coverageRatio);

    // Create actions only for resources with coverage gaps
    const actions: Action[] = [];

    // Add action for the resource with lowest coverage if it exists
    if (resourcesWithGaps[0]) {
      const primaryResourceTab = assetTypeToTabMap[resourcesWithGaps[0].assetType];
      actions.push({
        text: 'pages.securityImpactV3.components.repositoryCoverage.actions.manageResources.text',
        label: 'pages.securityImpactV3.components.repositoryCoverage.actions.manageResources.label',
        handleClick: () => {
          showDialog({
            type: Dialogs.AssetsManagement,
            props: { defaultTab: primaryResourceTab },
          });
        },
      });
    }

    // Add action for the second lowest coverage resource if it exists
    if (resourcesWithGaps[1]) {
      const secondaryResourceTab = assetTypeToTabMap[resourcesWithGaps[1].assetType];
      actions.push({
        text: 'pages.securityImpactV3.components.repositoryCoverage.actions.improveSecondaryCoverage.text',
        label: 'pages.securityImpactV3.components.repositoryCoverage.actions.improveSecondaryCoverage.label',
        handleClick: () => {
          showDialog({
            type: Dialogs.AssetsManagement,
            props: { defaultTab: secondaryResourceTab },
          });
        },
      });
    }

    return actions;
  }, [assetTypeToTabMap, coverage, showDialog]);
};
