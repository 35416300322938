import { Action } from 'components/MetricDetails/components/Actions/Actions';
import { constants } from 'globalConstants';

export const useCriticalAssetSecurityActions = (): Action[] => {
  const actions: Action[] = [
    {
      title: 'pages.securityImpactV3.components.criticalAssetSecurity.actions.viewImportantAssets.title',
      text: 'pages.securityImpactV3.components.criticalAssetSecurity.actions.viewImportantAssets.text',
      label: 'pages.securityImpactV3.components.criticalAssetSecurity.actions.viewImportantAssets.label',
      handleClick: () => {
        window.open(`/${constants.routes.risks.BASE_ROUTE}/${constants.routes.risks.RESOURCES_LIST}`, '_blank');
      },
    },

  ];

  return actions;
};
