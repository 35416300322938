import { MetricCommonProps } from '../../types';
import { GenericDateBasedMetric } from '../GenericDateBasedMetric/GenericDateBasedMetric';

import { Actions } from './components/Actions/Actions';

import { Threshold } from 'services/MetricService/types';

const RISK_REMEDIATION_THRESHOLDS: Threshold[] = [
  {
    metric: 'risk_remediation',
    goal: 0,
    threshold: 0,
    isPlaceholder: true,
  },
];
export const RiskRemediation = ({ timeRange, thresholds, cardRef }: MetricCommonProps) => (
  <GenericDateBasedMetric
    cardRef={cardRef}
    dateRange={timeRange}
    details={<Actions timeFrame={timeRange} />}
    drillDownMetric='risk_remediation_rate_by_criticality'
    mainMetric='risk_remediation_rate'
    subtitle='pages.securityImpactV3.components.riskRemediation.subtitle'
    summaryText='pages.securityImpactV3.components.riskRemediation.summary'
    thresholds={thresholds ?? RISK_REMEDIATION_THRESHOLDS}
    title='pages.securityImpactV3.components.riskRemediation.title'
  />
);
