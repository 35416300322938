import { useInfiniteQuery } from 'react-query';

import * as keys from './keys';
import { fetchTeams } from './TeamsService';
import { FetchTeamOptions } from './useTeamsService';

import { useClient } from 'services/useClient';

export const useTeamsInfiniteQuery = (params: FetchTeamOptions) => {
  const { client } = useClient();

  return useInfiniteQuery({
    queryKey: keys.fetchTeams(params),
    queryFn: ({ pageParam }) => fetchTeams(client, {
      ...params,
      after: pageParam,
    }),
    getNextPageParam: (data) => data?.metadata.after,

  });
};
