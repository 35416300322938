import Alert from '@mui/material/Alert';
import { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomCardIntegrationField } from '../CustomCardIntegrationField/CustomCardIntegrationField';

import styles from './CustomCardIntegrationForm.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ICardFieldProps } from 'types/interfaces/Integrations/IIntegration';

interface Props {
  fields: ICardFieldProps[];
  formValues: Record<string, string>;
  handleInputChange: (fieldName: string, value: string) => void;
  handleSubmit: (e?: React.FormEvent) => Promise<void> | void;
  isSubmitting: boolean;
  error: string | null;
  isFormValid: boolean;
  onClose?: () => void;
}

const CustomCardIntegrationForm = memo(({
  fields,
  formValues,
  handleInputChange,
  handleSubmit,
  isSubmitting,
  error,
  isFormValid,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const onSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    if (!isSubmitting && isFormValid) {
      handleSubmit(e);
    }
  }, [handleSubmit, isSubmitting, isFormValid]);

  return (
    <form className={styles.form} data-testid='card-integration-form' onSubmit={onSubmit}>
      <div className={styles.fieldsContainer}>
        {fields.map((field) => (
          <div key={field.name} className={styles.fieldWrapper}>
            <CustomCardIntegrationField
              disabled={isSubmitting}
              field={field}
              onChange={handleInputChange}
              value={formValues[field.name] || ''}
            />
          </div>
        ))}
      </div>

      {error && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <div className={styles.formActions}>
        {onClose && (
          <JitButton
            data-testid='back-button'
            height={30}
            onClick={onClose}
            variant='outlined'
          >
            <JitText color={colors.iris} text={t('customCardIntegration.buttons.back')} />
          </JitButton>
        )}

        <JitButton
          data-testid='card-integration-connect-button'
          disabled={isSubmitting || !isFormValid}
          height={30}
          isLoading={isSubmitting}
          showChildrenWhileLoading
          type='submit'
          variant='contained'
        >
          <JitText text={t('customCardIntegration.buttons.connect')} />
        </JitButton>
      </div>
    </form>
  );
});

export { CustomCardIntegrationForm };
