import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { FC, useState, useEffect, ChangeEvent } from 'react';

import {
  StyledPopover,
  StyledList,
  StyledListItem,
  SearchContainer,
  SearchField,
  StyledProjectItemText,
  EmptyResultText,
} from './ProjectSelectionPopoverStyles';

interface TMSProject {
  key: string;
  name: string;
}

interface ProjectSelectionPopoverProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  projects: TMSProject[];
  onClose: () => void;
  onProjectSelect: (projectKey: string, event: React.MouseEvent) => void;
}

export const ProjectSelectionPopover: FC<ProjectSelectionPopoverProps> = ({
  isOpen,
  anchorEl,
  projects,
  onClose,
  onProjectSelect,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filteredProjects, setFilteredProjects] = useState<TMSProject[]>(projects);

  useEffect(() => {
    setFilteredProjects(
      projects.filter((project) => project.name.toLowerCase().includes(searchText.toLowerCase())
        || project.key.toLowerCase().includes(searchText.toLowerCase())),
    );
  }, [searchText, projects]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <StyledPopover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClick={(e) => e.stopPropagation()}
      onClose={onClose}
      open={isOpen}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <SearchContainer>
        <SearchField
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon fontSize='small' />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          placeholder='Search projects'
          size='small'
          value={searchText}
          variant='outlined'
        />
      </SearchContainer>

      <StyledList>
        {filteredProjects.length === 0 ? (
          <StyledListItem>
            <EmptyResultText primary='No projects found' />
          </StyledListItem>
        ) : (
          filteredProjects.map((project) => (
            <StyledListItem
              key={project.key}
              onClick={(e) => onProjectSelect(project.key, e)}
            >
              <StyledProjectItemText primary={project.name} />
            </StyledListItem>
          ))
        )}
      </StyledList>
    </StyledPopover>
  );
};
