import { useFalsePositiveReductionActions } from './useFalsePositiveReductionActions';

import { Actions as ActionsComponent } from 'components/MetricDetails/components/Actions/Actions';
import { TimeAgo } from 'types/enums';

interface Props {
  timeFrame: TimeAgo;
}

export const Actions = ({ timeFrame }: Props) => {
  const actions = useFalsePositiveReductionActions(timeFrame);

  return <ActionsComponent actions={actions} />;
};
