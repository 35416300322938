import { FC, useMemo } from 'react';

import styles from './MTTRSummary.module.scss';
import { ProcessedSeverityData, processMTTRSeverityItem } from './utils';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { getSeverityIcon } from 'components/JitTable/templates/SeverityCell/utils/getSeverityIcon';
import { JitText } from 'components/JitText/JitText';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import { useDateBasedMetric } from 'services/MetricService/hooks';
import { Severity, TimeAgo } from 'types/enums';

interface MTTRSummaryProps {
  timeRange: TimeAgo
}

const relevantSeverities = [Severity.Critical, Severity.High];

export const MTTRSummary: FC<MTTRSummaryProps> = ({ timeRange }) => {
  const { data, isLoading: loading } = useDateBasedMetric<Severity>('mttr_trend_by_criticality', timeRange);
  // Process data for each severity using the utility function
  const processedData = useMemo(
    () => (data ? relevantSeverities
      .map((severity) => processMTTRSeverityItem(data, severity))
      .filter((item): item is ProcessedSeverityData => item !== null) : []),
    [data],
  );

  if (!loading && processedData.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {loading && (
      <LoadingSkeleton style={{ width: 200,
        height: 60 }}
      />
      )}

      {processedData.map((item) => (
        <div key={item.severity} className={styles.severityItem}>
          <div className={styles.severityInfo}>
            <JitIcon
              icon={getSeverityIcon(item.severity, false, true)}
              size={40}
            />

            <PercentageChip
              displayBorder
              percentage={item.percentageChange ?? 0}
              positiveTrendIndicatorDirection='down'
            />

          </div>

          <JitText text={item.formattedValue} />
        </div>
      ))}
    </div>
  );
};
