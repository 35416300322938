
import { useMemo } from 'react';
import { HeaderGroup } from 'react-table';

import { StyledTableRow } from '../JitTableRow/JitTableRow.styles';

import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';

interface Props {
  rows: number;
  headerGroups: HeaderGroup<object>[];
}
export const TableSkeleton = ({ rows, headerGroups }: Props) => {
  const columnCount = useMemo(() => headerGroups.reduce((acc, headerGroup) => acc + headerGroup.headers.length, 0), [headerGroups]);

  return (
    <>
      {Array.from({ length: rows }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledTableRow key={index} isClickable={false}>
          <td colSpan={columnCount}>

            <LoadingSkeleton style={{ width: '100%',
              height: 70 }}
            />
          </td>
        </StyledTableRow>
      ))}
    </>
  );
};
