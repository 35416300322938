import { DateBasedChartData } from 'services/MetricService/types';
import { Severity } from 'types/enums';
import { formatHoursToDisplayText } from 'utils';

export interface ProcessedSeverityData {
  severity: string;
  formattedValue: string;
  percentageChange?: number;
}

export const processMTTRSeverityItem = (
  data: DateBasedChartData<Severity>,
  severity: Severity,
): ProcessedSeverityData | null => {
  const severityData = data.data.filter((item) => item[severity] !== null);
  if (!severityData || !severityData.length) {
    return null;
  }

  // Find latest date with data
  const sortedTimeSeries = [...severityData].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  const latestData = sortedTimeSeries[0];
  if (!latestData) return null;

  // Find previous data for percentage calculation
  const previousData = sortedTimeSeries[1];

  const formattedValue = formatHoursToDisplayText({
    hours: latestData[severity] / 60,
    roundUp: false,
    singleTimeUnit: true,
    fullWord: true,
  });

  let percentageChange: number | undefined;
  if (previousData && previousData[severity] > 0) {
    const change = ((latestData[severity] - previousData[severity]) / previousData[severity]) * 100;
    percentageChange = Math.round(change);
  }

  return {
    severity,
    formattedValue,
    percentageChange,
  };
};
