import { getResourceNamesMap } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

export const common = {
  unknown: 'Unknown',
  logout: 'Logout',
  na: 'N/A',
  passed: 'Passed',
  failed: 'Failed',
  ONE_WEEK: 'One week',
  TWO_WEEKS: 'Two weeks',
  ONE_MONTH: 'One month',
  github: 'GitHub',
  aws: 'AWS',
  all: 'All',
  python: 'Python',
  go: 'Go',
  terraform: 'Terraform',
  today: 'Today',
  dotJit: '.jit',
  repo: 'Repository',
  project: 'Project',
  assetType: getResourceNamesMap(),
  layer: {
    code: 'Application Security',
    data: 'Data',
    runtime: 'Web Application Security',
    infrastructure: 'Cloud Security',
    operations: 'Operations',
    third_party_app: 'CI/CD Security',
    // insights page formats the server response to camelCase
    thirdPartyApp: 'CI/CD Security',
  },
  vendor: {
    github: 'GitHub',
    gitlab: 'GitLab',
    aws: 'AWS',
    gcp: 'GCP',
    azure: 'Azure',
    aws_account: 'AWS',
    gcp_account: 'GCP',
    azure_account: 'Azure',
    aws_org: 'AWS',
    slack: 'Slack',
    jira: 'Jira',
    shortcut: 'Shortcut',
    semgrep: 'Semgrep',
    snyk: 'Snyk',
    github_security: 'GitHub Misconfiguration',
    npm: 'npm',
    docker: 'Docker',
  },
  comingSoon: 'Coming Soon',
  confirmDialog: {
    default: {
      title: 'Confirm',
      message: 'Are you sure?',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
  },
  severity: {
    critical: 'Critical',
    high: 'High',
    medium: 'Medium',
    low: 'Low',
  },
};
