import { useMemo } from 'react';

import { ChartData, Threshold } from 'services/MetricService/types';

export const useEnhanceDataWithThreshold = <X extends string, Y extends string>(data?: ChartData<X, Y>, threshold?: Threshold): ChartData<X, Y> | undefined => useMemo(() => {
  if (!threshold) {
    return data;
  }

  if (!data) {
    return undefined;
  }

  return {
    ...data,
    goal: threshold.goal,
    threshold: threshold.threshold,
  };
}, [data, threshold]);
