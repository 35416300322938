import { ReactNode, useMemo } from 'react';
import { ReferenceLine } from 'recharts';

import { BarChart } from './components/BarChart/BarChart';
import { LineChart } from './components/LineChart/LineChart';
import { PieChart } from './components/PieChart/PieChart';
import { BaseProps } from './types';

import { GraphLoading, GraphEmpty } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { logError } from 'services/logger/logger';
import { ChartData } from 'services/MetricService/types';
import colors from 'themes/colors.module.scss';

export interface LoadedProps<XField extends string, YFields extends string> {
  data: ChartData<XField, YFields>,
  loading?: false;
}

export interface LoadingProps {
  loading: true;
  data?: unknown
}

export type Props<XField extends string, YFields extends string> = (LoadedProps<XField, YFields> | LoadingProps) & BaseProps;

export const Chart = <XField extends string = string, YFields extends string = string>({ data, loading, height = 220 }: Props<XField, YFields>) => {
  // Create guiding lines based on chart type
  const guideLines = useMemo((): ReactNode => {
    if (!loading && (data.type === 'line' || data.type === 'bar')) {
      const result: ReactNode[] = [];

      if (data.goal !== undefined) {
        result.push(
          <ReferenceLine
            key='goal-line'
            label={{
              fill: colors.goalReferenceLineColor,
              fontSize: 12,
              position: 'insideTopRight',
            }}
            stroke={colors.goalReferenceLineColor}
            strokeDasharray='3 3'
            y={data.goal}
          />,
        );
      }

      if (data.threshold !== undefined) {
        result.push(
          <ReferenceLine
            key='threshold-line'
            label={{
              fill: colors.thresholdReferenceLineColor,
              fontSize: 12,
              position: 'insideBottomRight',
            }}
            stroke={colors.thresholdReferenceLineColor}
            strokeDasharray='3 3'
            y={data.threshold}
          />,
        );
      }

      return result;
    }
    return null;
  }, [data, loading]);

  if (loading) {
    return (
      <div
        data-testid='chart-loading'
        style={{ height }}
      >
        <GraphLoading />
      </div>
    );
  }

  if (data.data.length === 0) {
    return (
      <div style={{ height }}>
        <GraphEmpty />
      </div>
    );
  }

  switch (data.type) {
    case 'line':
      return <LineChart height={height} {...data}>{guideLines}</LineChart>;
    case 'bar':
      return <BarChart height={height} {...data}>{guideLines}</BarChart>;
    case 'pie':
      return <PieChart height={height} {...data} />;
    default:
      logError(`Unknown chart type: ${(data as ChartData<string, string>).type}`);
      return null;
  }
};
