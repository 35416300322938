import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Action } from 'components/MetricDetails/components/Actions/Actions';
import { constants } from 'globalConstants';
import { useTeamsInfiniteQuery } from 'services/TeamsService/hooks';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

export const useTeamActions = (): Action[] => {
  const { t } = useTranslation();

  // Fetch teams data, sorting by score in ascending order (worst teams first)
  const { data: teamsData, isLoading: isLoadingTeams } = useTeamsInfiniteQuery(
    {
      sortBy: TeamSortBy.SCORE,
      sortOrder: TeamSortOrder.ASC, // Ascending order to get worst teams first
      limit: 2, // We only need the two worst teams
      displayImage: false,
      displayMembers: false,
    },
  );

  return useMemo(() => {
    const actions: Action[] = [];

    // Don't add actions if data is still loading or no data is available
    if (isLoadingTeams || !teamsData?.pages[0]?.data?.length) {
      return actions;
    }

    // Get worst and second-worst teams (if available)
    const teams = teamsData.pages[0].data;
    const worstTeam = teams[0];
    const secondWorstTeam = teams.length > 1 ? teams[1] : undefined;

    // Add action for worst team if available
    if (worstTeam) {
      actions.push({
        title: t('pages.securityImpactV3.components.teams.actions.improveWorstTeam.title'),
        text: t('pages.securityImpactV3.components.teams.actions.improveWorstTeam.text'),
        label: t('pages.securityImpactV3.components.teams.actions.improveWorstTeam.label'),
        handleClick: () => {
          window.open(`/${constants.routes.TEAMS}/${worstTeam.id}`, '_blank');
        },
      });
    }

    // Add action for second-worst team if available
    if (secondWorstTeam) {
      actions.push({
        title: t('pages.securityImpactV3.components.teams.actions.addressSecondTeam.title'),
        text: t('pages.securityImpactV3.components.teams.actions.addressSecondTeam.text'),
        label: t('pages.securityImpactV3.components.teams.actions.addressSecondTeam.label'),
        handleClick: () => {
          window.open(`/${constants.routes.TEAMS}/${secondWorstTeam.id}`, '_blank');
        },
      });
    }

    return actions;
  }, [isLoadingTeams, t, teamsData]);
};
