import { IntegrationVendorType } from '../../../context/IntegrationsContext/templates/interfaces';

import { Vendor } from 'types/enums';

export enum IntegrationStatus {
  VALIDATING = 'validating',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum IntegrationLifecycleEventState {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}
export enum IntegrationStatusReason {
  SUCCESS = 'success',
  INVALID_SCHEMA = 'invalid schema',
  MISSING_SECRET = 'missing secret',
  INCORRECT_AUTH = 'incorrect auth',
  INCORRECT_EMAIL = 'incorrect email',
  PROJECT_NOT_FOUND = 'project id is not found',
  ISSUE_NOT_FOUND = 'issue id is not found',
  NOT_VALID_CUSTOM_FIELDS = 'custom fields are not valid',
}

export enum IntegrationProvider {
  THIRD_PARTY = 'third_party',
  JIT = 'jit',
}

export interface IProject {
  id: string;
  name: string;
  key?: string;
  externalId?: string;
  dateCreated?: string;
  url?: string;
}

export interface IExtraDisplayProps {
  headlessConfig?: boolean;
  supportMultipleProjects?: boolean;
  createTicket?: string;
}
export interface IDisplayProps {
  icon: string;
  description: string;
  name: string;
  categoryKey: IntegrationVendorType;
  isNew: boolean;
  extras?: IExtraDisplayProps;
  card?: ICardProps;
}

export interface ICardFieldProps {
  name: string;
  description: string;
  type: string;
}

export type MappingValue = string | number | boolean | null | Record<string, unknown>;

export interface ICardProps {
  fields: ICardFieldProps[];
  client_id?: string;
  client_secret?: string;
  mapping?: Record<string, MappingValue>;
}

interface IIntegrationBase {
  tenantId: string;
  integrationId: string;
  vendor: Vendor;
  status: IntegrationStatus;
  reason?: IntegrationStatusReason;
  provider: IntegrationProvider;
  projects?: IProject[];
}

export interface IIntegrationThirdParty extends IIntegrationBase {
  provider: IntegrationProvider.THIRD_PARTY;
  display: IDisplayProps;
}

interface IIntegrationOther extends IIntegrationBase {
  provider: Exclude<IntegrationProvider, IntegrationProvider.THIRD_PARTY>;
  display?: IDisplayProps;
}

export type IIntegration = IIntegrationThirdParty | IIntegrationOther;
