import { useState, useEffect, useRef } from 'react';

import { Header } from './components/Header/Header';
import { StepDetails } from './components/StepDetails/StepDetails';
import { ViewToggle, WorkflowView } from './components/ViewToggle/ViewToggle';
import { WorkflowGraph } from './components/WorkflowGraph/WorkflowGraph';
import { DIAGRAM_VIEW, HISTORY_VIEW } from './constants';
import styles from './EditWorkflowPage.module.scss';
import { WorkflowHistoryView } from './WorkflowHistoryView/WorkflowHistoryView';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

export const EditWorkflowPage = () => {
  const {
    workflow,
    isNewWorkflow,
    openStepDetails,
    isLoadingWorkflow,
    isLoadingStepsOptions,
    isFailedFetchingWorkflow,
    selectedStepId,
    closeStepDetailsPanel,
  } = useEditWorkflowContext();
  const { hasPermission } = useAuthContext();
  const hasOpenedTrigger = useRef(false);
  const previousView = useRef<WorkflowView>('diagram');
  const [view, setView] = useState<WorkflowView>('diagram');
  const isReadOnly = !hasPermission(PERMISSIONS.WRITE_WORKFLOWS);
  const isLoading = isLoadingWorkflow || isLoadingStepsOptions;
  useEffect(() => {
    if (!isLoading && workflow?.trigger && !hasOpenedTrigger.current && !selectedStepId) {
      hasOpenedTrigger.current = true;
      openStepDetails(workflow.trigger.id);
    }
  }, [isLoading, workflow?.trigger, openStepDetails, selectedStepId]);

  useEffect(() => {
    if (view === HISTORY_VIEW) {
      closeStepDetailsPanel();
      previousView.current = HISTORY_VIEW;
    } else if (view === DIAGRAM_VIEW && previousView.current === HISTORY_VIEW) {
      openStepDetails(workflow?.trigger?.id || '');
      previousView.current = DIAGRAM_VIEW;
    }
  }, [view, closeStepDetailsPanel, openStepDetails, workflow?.trigger?.id]);

  if (isLoading || isFailedFetchingWorkflow) {
    return (
      <div className={styles.loadingOverlay} data-testid='workflow-page-loading'>
        <LoadingBar />
      </div>
    );
  }

  const isShowingDetails = view === 'diagram' && selectedStepId;
  const contentClassName = isShowingDetails ? styles.mainContentWithDetails : styles.mainContentFull;

  return (
    <div className={styles.layoutContainer}>
      <div className={contentClassName}>
        <div className={styles.pageControls}>
          <Header isReadOnly={isReadOnly} />

          {workflow && <ViewToggle isNewWorkflow={isNewWorkflow} setView={setView} view={view} />}
        </div>

        {(view === 'diagram' || !workflow) ? (
          <WorkflowGraph />
        ) : (
          <WorkflowHistoryView workflowId={workflow.id} />
        )}
      </div>

      {isShowingDetails && (
        <div className={styles.detailsPanel}>
          <StepDetails />
        </div>
      )}
    </div>
  );
};
