import { FC, useCallback, useMemo } from 'react';

import { CustomCardIntegrationForm } from './components/CustomCardIntegrationForm/CustomCardIntegrationForm';
import styles from './CustomCardIntegrationModal.module.scss';
import { useCustomCardIntegration } from './hooks/useCustomCardIntegration';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

interface CustomCardIntegrationModalProps {
  integration: IIntegration;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const CustomCardIntegrationModal: FC<CustomCardIntegrationModalProps> = ({
  integration,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const { display, integrationId, vendor, status } = integration;
  const { getIconByVendor } = useDetailsByVendor();
  const { refetchUser } = useParagonAuth();

  // Get vendor icon
  const vendorIcon = getIconByVendor(vendor) || undefined;

  // checking integration status
  const isIntegrated = status === IntegrationStatus.SUCCESS;

  // Handle successful connection - Must be defined before any conditionals
  const handleSuccess = useCallback(() => {
    onSuccess();
    refetchUser();
  }, [onSuccess, refetchUser]);

  // Check if we have card configuration
  const hasCardConfig = !!display?.card;

  // Extract card properties and create integration details only if we have card config
  const cardFields = useMemo(() => {
    if (!hasCardConfig || !display?.card) {
      return {
        fields: [],
        mapping: undefined,
      };
    }

    const { fields = [], mapping } = display.card;
    return {
      fields,
      mapping,
    };
  }, [hasCardConfig, display?.card]);

  // Create integration details
  const integrationDetails = useMemo(() => ({
    integrationId,
    integrationKey: vendor,
    fields: cardFields.fields,
    mapping: cardFields.mapping,
    onSuccess: handleSuccess,
  }), [integrationId, vendor, cardFields, handleSuccess]);

  // Use custom integration hook
  const {
    formValues,
    handleInputChange,
    handleSubmit,
    isLoading,
    error,
    isFormValid,
  } = useCustomCardIntegration(integrationDetails);

  // If already integrated, don't render the modal at all
  if (isIntegrated && isOpen) {
    // Call onClose to update parent state
    onClose();
    return null;
  }

  // Early return after all hooks are called
  if (!hasCardConfig) {
    console.warn('Integration does not have card configuration:', vendor);
    return null;
  }

  // Create form props
  const formProps = {
    error,
    fields: cardFields.fields,
    formValues,
    handleInputChange,
    handleSubmit,
    isFormValid,
    isSubmitting: isLoading,
    onClose,
  };

  // Create modal content
  const modalContent = (
    <div className={styles.modalContent}>
      <div className={styles.header}>
        <div className={styles.icon}>
          {vendorIcon && <JitIcon icon={vendorIcon} size={60} />}
        </div>

        <JitText bold size='l' text={display.name} />

        <JitText color='lightGray' text={display.description} />
      </div>

      <CustomCardIntegrationForm {...formProps} />
    </div>
  );

  return (
    <CustomDialog
      content={modalContent}
      height='m'
      isOpen={isOpen && !isIntegrated}
      onClose={onClose}
      title={`${display.name} Setup`}
      width='l'
      withDivider
    />
  );
};
