import { DateBasedChartData } from './types';

import { getPeriodDate } from 'pages/InsightsPage/utils/getPeriodDates';
import { TimeAgo } from 'types/enums';
import { generateDateRange } from 'utils';

export const formatDateBasedMetric = <T extends string = string>(data: DateBasedChartData<T>): DateBasedChartData<T> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    date: new Date(item.date).toISOString().split('T')[0],
  })),
});

export const generateCompleteDateRange = (timeRange: TimeAgo): string[] => {
  const { startDate, endDate } = getPeriodDate(timeRange);

  return generateDateRange(startDate, endDate);
};

/**
 * Backfill any missing dates in the timeseries with the last known value
 */
export const backfillMissingDates = <TValue extends { date: string }, TKey extends Exclude<keyof TValue, 'date'>>(
  scoresByDay: TValue[],
  timeRange: TimeAgo,
  key: TKey,
): TValue[] => {
  // Generate the complete date range based on TimeAgo
  const dateRange = generateCompleteDateRange(timeRange);

  // Sort scores by date (oldest first)
  const sortedScores = [...scoresByDay].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  // Create a lookup map for existing dates (using yyyy-mm-dd format)
  const scoresMap = new Map(
    sortedScores.map((item) => {
      // Ensure the date is in yyyy-mm-dd format by extracting just the date part
      const dateOnly = new Date(item.date).toISOString().split('T')[0];
      return [dateOnly, item[key]];
    }),
  );

  let lastValue: number | null = null;
  return dateRange.flatMap((date) => {
    if (scoresMap.has(date)) {
      lastValue = (scoresMap.get(date) ?? null) as number | null;
    }

    if (lastValue === null) {
      return [];
    }

    return {
      date,
      [key]: lastValue,
    } as TValue;
  });
};
