import { useMemo } from 'react';

import { InnerOptions, IntegrationTemplate } from 'context/IntegrationsContext/templates/interfaces';
import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

// Function to determine integration status from various sources
export const useIntegrationStatus = (
  validation: ((x: string | undefined) => IntegrationStatus | undefined) | undefined,
  integrations: IIntegration[] | undefined,
  integrationTemplate?: IntegrationTemplate,
) => useMemo(() => {
  if (validation) {
    return validation((integrationTemplate?.options as InnerOptions).fields?.name?.value);
  }
  const integrationByStatus = integrations?.reduce((acc, integration) => {
    acc[integration.status] = acc[integration.status] ? acc[integration.status] + 1 : 1;
    return acc;
  }, {} as Record<IntegrationStatus, number>);

  if (integrationByStatus?.[IntegrationStatus.SUCCESS]) {
    return IntegrationStatus.SUCCESS;
  }
  if (integrationByStatus?.[IntegrationStatus.VALIDATING]) {
    return IntegrationStatus.VALIDATING;
  }

  if (integrationByStatus?.[IntegrationStatus.FAILURE]) {
    return IntegrationStatus.FAILURE;
  }
  return undefined;
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [integrations, validation]);
