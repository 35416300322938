import { useMemo } from 'react';

import { DateBasedChartData } from 'services/MetricService/types';
import { TimeAgo } from 'types/enums';

/**
 * Groups daily data into weekly points for ThreeMonths date range
 * - For 'aggregative' metrics: Takes the last data point from each week since the data is already accumulated
 * - For 'trending' metrics: Sums up the counts within each week
 */
export const useAggregateWeeklyData = <YFields extends string = string>(
  data: DateBasedChartData<YFields> | undefined,
  dateRange: TimeAgo,
): DateBasedChartData<YFields> | undefined => useMemo(() => {
    // If no data or not THREE_MONTHS, return original data
    if (!data || dateRange !== TimeAgo.ThreeMonths) {
      return data;
    }

    // Group data by weeks
    const weeklyData: Record<string, Array<Record<'date', string> & Partial<Record<YFields, number | null>>>> = {};

    // Extract yFields from data
    const yFields = 'yFields' in data ? data.yFields as YFields[] : [];

    // Sort data by date
    const sortedData = [...data.data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    // Group data into weekly buckets
    sortedData.forEach((item) => {
      const date = new Date(item.date);
      // Get the week start date (Sunday)
      const dayOfWeek = date.getDay();
      const diff = date.getDate() - dayOfWeek;
      const weekStart = new Date(date);
      weekStart.setDate(diff);
      const weekKey = weekStart.toISOString().split('T')[0];

      if (!weeklyData[weekKey]) {
        weeklyData[weekKey] = [];
      }

      weeklyData[weekKey].push(item);
    });

    // Determine if we should aggregate by sum (trending) or take the last point (aggregative)
    const isTrending = data.metricType === 'trending';

    const aggregatedData = Object.entries(weeklyData).map(([weekStartDate, days]) => {
      if (isTrending) {
        // For trending metrics: sum the values within each week
        const result: Record<string, string | number> = { date: weekStartDate };

        // Sum values for each yField
        yFields.forEach((field) => {
          // Filter out null/undefined values and sum them
          const validValues = days
            .filter((day) => day[field as keyof typeof day] !== null && day[field as keyof typeof day] !== undefined)
            .map((day) => day[field as keyof typeof day] as number);

          if (validValues.length > 0) {
            // Sum all values for trending metrics
            result[field] = validValues.reduce((sum, val) => sum + val, 0);
          } else {
            result[field] = 0;
          }
        });

        return result as Record<'date', string> & Record<YFields, number>;
      }
      // For aggregative metrics: take the last data point from the week
      const lastDayData = [...days].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).pop();

      if (!lastDayData) {
        return { date: weekStartDate } as Record<'date', string> & Record<YFields, number>;
      }

      return {
        ...lastDayData,
        date: weekStartDate,
      } as Record<'date', string> & Record<YFields, number>;
    });

    // Generate new tooltip data if it exists
    let newTooltip;
    if ('tooltip' in data && data.tooltip) {
      newTooltip = aggregatedData.map((item) => {
        const tooltipItem: Record<string, string> = {};
        yFields.forEach((field) => {
          const value = item[field];
          // Preserve the original format from tooltip if possible
          const originalFormat = data.tooltip?.[0]?.[field as keyof typeof data.tooltip[0]];
          if (originalFormat && typeof originalFormat === 'string' && originalFormat.includes('%')) {
            tooltipItem[field] = `${Math.round(value)}%`;
          } else {
            tooltipItem[field] = value !== null ? `${value}` : '';
          }
        });
        return tooltipItem;
      });
    }

    // Return the same chart type as the original data with updated values
    return {
      ...data,
      data: aggregatedData,
      ...(newTooltip ? { tooltip: newTooltip } : {}),
    } as DateBasedChartData<YFields>;
  }, [data, dateRange]);
