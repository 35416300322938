import { Popover, List, ListItem, TextField, Box, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

export const StyledPopover = styled(Popover)({
  '& .MuiPaper-root': {
    marginTop: '6px',
    boxShadow: 'none',
    borderRadius: '10px !important',
    backgroundColor: colors.cards,
    border: `1px solid ${colors.darkGray}`,
    overflow: 'hidden',
  },
});

export const StyledList = styled(List)({
  minWidth: '200px',
  maxHeight: '200px',
  overflow: 'auto',
  padding: 0,
  backgroundColor: colors.cards,
});

export const StyledListItem = styled(ListItem)({
  padding: '8px 6px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  '&.Mui-selected': {
    backgroundColor: 'inherit',
  },
});

export const SearchContainer = styled(Box)({
  padding: '8px',
  borderBottom: `1px solid ${colors.darkGray02}`,
});

export const SearchField = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    color: colors.white,
    fontSize: '14px',
    backgroundColor: colors.cardContent,
    borderRadius: '8px',
    padding: '4px 8px',
    '&.Mui-focused': {
      borderColor: colors.blue,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.darkGray02,
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.darkGray,
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.blue,
    borderWidth: '1px',
  },
  '& .MuiInputAdornment-root': {
    color: colors.gray,
  },
});

export const StyledProjectItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  marginLeft: '0.5rem',
  '& .MuiTypography-root': {
    fontSize: theme.typography?.fontSize,
    fontWeight: 500,
  },
  '& .MuiTypography-secondary': {
    display: 'none',
  },
}));

export const EmptyResultText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  marginLeft: '0.5rem',
  '& .MuiTypography-root': {
    fontSize: theme.typography?.fontSize,
    fontWeight: 400,
    color: colors.gray,
    fontStyle: 'italic',
  },
}));
