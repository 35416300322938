import { TeamSortBy } from './TeamSorting';

export interface IMember { // Represents a team member in a specific team.
  id: string;
  name: string;
  avatarUrl: string;
  teamId: string;
  vendorId: string;
}

export enum TaskKeyEnum {
  'configureSlack' = 'slack_notifications',
  'prCheckEnablement' = 'pr_check_enablement',
  'exploreDeveloper' = 'test_pr_experience',
  'resources' = 'check_resources',
  'selectProject' = 'project_selection',
}

export enum ImageShape {
  POLYGON = 'polygon',
  STAR = 'star',
  CIRCLE = 'circle',
  SQUARE = 'square',
  DIAMOND = 'diamond',
  PENTAGON = 'pentagon',
}

export interface ImageDetails {
  backgroundImageUrl: string;
  shape: ImageShape;
}

export interface ITaskButtonModel {
  label: string;
  actionKey: string;
}

interface SelectModel {
  placeholder: string;
}

interface IOptionsField {
  title: string;
  description: string;
  select?: SelectModel;
  button?: ITaskButtonModel;
}

interface IOptionsFields {
  [key: string]: IOptionsField;
}

interface ICheckOptions {
  button: ITaskButtonModel;
  fields?: IOptionsFields;
}

export interface ProjectValue {
  name: string;
  vendor: string;
}

export interface ICommunicationPlatformValue {
  vendor: string;
  channelId?: string;
}
export interface ITeamCheckDetails {
  checkSlug: TaskKeyEnum;
  isRequired: boolean;
  isHidden: boolean;
  title: string;
  description: string;
  dataComponent: string;
  isCompleted: boolean;
  info?: string;
  options?: ICheckOptions;
  value?: string | ICommunicationPlatformValue | ProjectValue;
  metadata?: ITeamCheckDetailsMetadata;
}

export interface ITeamCheckDetailsMetadata {
  url?: string;
}

export interface ITeam {
  id: string
  [TeamSortBy.NAME]: string
  [TeamSortBy.SCORE]: number
  installationId: string
  owner: string
  description?: string
  parentTeamId?: string
  childrenTeamIds: string[]
  isActivated: boolean
  checks?: ITeamCheckDetails[];
  imageDetails?: ImageDetails
  image?: string
  rank?: number
  source: string;
  project?: string
}

export interface IGetTeamResponseItem extends ITeam {
  members: IMember[] // limited to 20 members in the response
  membersCount: number // total number of members in the team
  imageDetails?: ImageDetails
}
