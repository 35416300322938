import { MetricCommonProps } from '../../types';
import { GenericDateBasedMetric } from '../GenericDateBasedMetric/GenericDateBasedMetric';

import { Actions } from './components/Actions/Actions';

import { Threshold } from 'services/MetricService/types';

const CRITICAL_ASSET_SECURITY_THRESHOLDS: Threshold[] = [
  {
    metric: 'critical_asset_security',
    goal: 0,
    threshold: 0,
    isPlaceholder: true,
  },
];

export const CriticalAssetSecurity = ({ timeRange, thresholds, cardRef }: MetricCommonProps) => (
  <GenericDateBasedMetric
    cardRef={cardRef}
    dateRange={timeRange}
    details={<Actions />}
    drillDownMetric='critical_asset_security_by_type'
    mainMetric='critical_asset_security'
    subtitle='pages.securityImpactV3.components.criticalAssetSecurity.subtitle'
    summaryText='pages.securityImpactV3.components.criticalAssetSecurity.summary'
    thresholds={thresholds ?? CRITICAL_ASSET_SECURITY_THRESHOLDS}
    title='pages.securityImpactV3.components.criticalAssetSecurity.title'
  />
);
