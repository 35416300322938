import { useGetActivationDataComponent } from '../useGetActivationDataComponent/useGetActivationDataComponent';
import { useGetConfiguredSlackDataComponent } from '../useGetConfiguredSlackDataComponent/useGetConfiguredSlackDataComponent';
import { useGetRankDataComponent } from '../useGetRankDataComponent/useGetRankDataComponent';
import { useGetReposDataComponent } from '../useGetReposDataComponent/useGetReposDataComponent';
import { useGetSelectProjectDataComponent } from '../useGetSelectProjectDataComponent/useGetSelectProjectDataComponent';

import { ITeam, ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

export const useGetDataComponents = (
  team: ITeam | undefined,
  checksMap: { [key: string]: ITeamCheckDetails } | undefined,
) => {
  const { dataComponent: activeDataComponent } = useGetActivationDataComponent(checksMap?.[TaskKeyEnum.prCheckEnablement]?.isCompleted || false);
  const { dataComponent: configureSlackComponent } = useGetConfiguredSlackDataComponent(checksMap?.[TaskKeyEnum.configureSlack]?.isCompleted || false);
  const { dataComponent: projectSelectionDataComponent } = useGetSelectProjectDataComponent(checksMap?.[TaskKeyEnum.selectProject]?.isCompleted || false);
  const { dataComponent: reposDataComponent } = useGetReposDataComponent();
  const { dataComponent: gradeDataComponent } = useGetRankDataComponent(team?.score || 0);

  return ({
    activeDataComponent,
    configureSlackComponent,
    projectSelectionDataComponent,
    reposDataComponent,
    gradeDataComponent,
  });
};
