import { useCallback } from 'react';

import { useWebSocketContext } from 'context/WebSocketContext/WebSocketContext';
import { WebSocketNotificationTopics } from 'types/enums';
import { WebsocketNotificationCallback } from 'types/types';

export const useWebSocketConnection = () => {
  const { subscribeTopic, unsubscribeTopic } = useWebSocketContext();

  const subscribe = useCallback(
    <EntityType extends object = object, SystemType extends object = EntityType>(
      topic: WebSocketNotificationTopics,
      handler: WebsocketNotificationCallback<EntityType, SystemType>,
    ) => {
      const { handlerId } = subscribeTopic<EntityType, SystemType>(topic, handler);
      return handlerId;
    },
    [subscribeTopic],
  );

  const unsubscribe = useCallback(
    (topic: WebSocketNotificationTopics, handler: WebsocketNotificationCallback<object>) => {
      // Find the handlerId for this topic and handler
      const { handlerId } = subscribeTopic(topic, handler);
      unsubscribeTopic(handlerId);
    },
    [subscribeTopic, unsubscribeTopic],
  );

  return {
    subscribe,
    unsubscribe,
  };
};
