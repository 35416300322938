/* eslint-disable react/no-unstable-nested-components */
import { ReactNode, useMemo } from 'react';
import { XAxis, YAxis, ResponsiveContainer, Line, LineChart as LineChartBase, DotProps, CartesianGrid, Tooltip } from 'recharts';
import { ActiveShape } from 'recharts/types/util/types';

import { BaseProps } from '../../types';

import styles from './LineChart.module.scss';

import { getColor } from 'components/Chart/utils';
import { CustomDot, CustomDotProps } from 'components/graphs/components/CustomDot/CustomDot';
import { GraphTooltip } from 'components/graphs/components/GraphTooltip/GraphTooltip';
import { commonAxisProps } from 'components/graphs/constants';
import { JitText } from 'components/JitText/JitText';
import { LineChartData } from 'services/MetricService/types';
import colors from 'themes/colors.module.scss';

interface LineChartProps<XField extends string, YFields extends string> extends LineChartData<XField, YFields>, BaseProps {
  children?: ReactNode;
}

export const LineChart = <XField extends string = string, YFields extends string = string>({
  data,
  xField,
  yFields,
  label,
  height,
  children,
  hideYAxis, tooltip,
}: LineChartProps<XField, YFields>) => {
  const yAxisWidth = useMemo(() => 10 + String(Math.max(...data.map((item) => {
    const value = item[yFields[0]];
    if (typeof value === 'number') {
      return Math.round(value);
    }
    return value;
  }))).length * 7.5, [data, yFields]);

  const dataWithTooltip = useMemo(() => data.map((dt, idx) => ({
    ...dt,
    tooltip: tooltip?.[idx],
  })), [data, tooltip]);

  return (
    <ResponsiveContainer height={height} width='100%'>
      <LineChartBase
        data={dataWithTooltip}
      >

        {!hideYAxis && (
          <CartesianGrid
            horizontal
            stroke={colors.cardsDivider}
            strokeWidth={1}
            vertical={false}
          />
        )}

        <XAxis
          dataKey={xField}
          dy={10}
          {...commonAxisProps}
          interval='preserveEnd'
        />

        {!hideYAxis && (
          <YAxis
            width={yAxisWidth}
            {...commonAxisProps}
          />
        )}

        {children}

        {yFields.map((field, idx) => {
          const color = getColor(idx);
          return (
            <Line
              key={field}
              activeDot={((props: CustomDotProps) => <CustomDot {...props} singleColor={color} />) as ActiveShape<DotProps>}
              color={color}
              dataKey={field}
              dot={false}
              isAnimationActive={data.length > 1}
              stroke={color}
              strokeWidth={3}
              type='monotone'
            />
          );
        })}

        <Tooltip
          content={({ active, payload }) => {
            const payloadData = payload?.[0]?.payload as (typeof dataWithTooltip)[number];

            if (!payloadData) {
              return null;
            }

            return (
              <GraphTooltip active={active} payload={payload}>
                <div className={styles.tooltipWrapper}>

                  <JitText muted size='xs' text={payloadData[xField]} />

                  {yFields.map((field, idx) => {
                    const color = getColor(idx);
                    const value = (payloadData.tooltip ?? payloadData)[field];
                    if (value === '') {
                      return null;
                    }
                    return (
                      <div
                        key={field}
                        className={styles.tooltipItem}
                      >
                        {yFields.length > 1 && (
                        <div
                          className={styles.colorIndicator}
                          style={{
                            background: color,
                          }}
                        />
                        )}

                        <JitText text={yFields.length === 1 ? (payloadData.tooltip ?? payloadData)[field] : `${label[field]}: ${(payloadData.tooltip ?? payloadData)[field]}`} />
                      </div>
                    );
                  })}
                </div>
              </GraphTooltip>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: 'none' }}
        />
      </LineChartBase>
    </ResponsiveContainer>
  );
};
