import { useCallback, useEffect, useState } from 'react';

import { MenuItemType } from 'components/JitMenu/JitMenu';
import { useGetActiveIntegration } from 'context/IntegrationsContext/hooks/useGetActiveIntegration';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { Vendor } from 'types/enums';
import { MenuItemKeyType } from 'types/interfaces';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';
import { useProjectConfigurations } from 'utils/hooks/useProjectConfigurations';

export interface MenuItemsData {
  menuItems: Partial<MenuItemType>[];
  isLoading: boolean;
}

/**
 * Hook that returns project menu items for any TMS vendor.
 * Uses the active TMS integration to determine the vendor.
 *
 * @param selectedItem - The currently selected project
 * @returns The menu items containing project information and isLoading status
 */
export const useGetProjectsMenuItems = (selectedItem?: MenuItemKeyType): MenuItemsData => {
  const [menuItems, setMenuItems] = useState<Partial<MenuItemType>[]>([]);
  const { getIconByVendor } = useDetailsByVendor();

  // Get the active TMS integration
  const { activeIntegration } = useGetActiveIntegration(
    (integration) => integration.display?.categoryKey === IntegrationVendorType.tms,
  );

  const vendor = activeIntegration?.vendor as Vendor;
  const { fetchProjects, isLoading } = useProjectConfigurations(vendor);

  const loadProjects = useCallback(async () => {
    if (!vendor) {
      setMenuItems([]);
      return;
    }

    try {
      const projects = await fetchProjects();

      // Map the projects to menu items
      const mappedProjects = projects.map((project) => ({
        itemKey: project.id,
        itemText: project.name,
        itemContent: project.name,
        icon: getIconByVendor(vendor, undefined, true),
        isSelected: selectedItem === project.id,
      }));

      setMenuItems(mappedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setMenuItems([]);
    }
  }, [vendor, fetchProjects, getIconByVendor, selectedItem]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  return {
    menuItems,
    isLoading,
  };
};
