
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricCommonProps } from '../../types';
import { DrillDownChart } from '../GenericDateBasedMetric/components/DrillDownChart/DrillDownChart';
import { DrillDownSummary } from '../GenericDateBasedMetric/components/DrillDownSummary/DrillDownSummary';
import { useValueAndTrend } from '../GenericDateBasedMetric/useValueAndTrend';

import { Actions } from './components/Actions/Actions';
import { RepositoryCoverageSummary } from './components/RepositoryCoverageSummary/RepositoryCoverageSummary';

import { MetricCard } from 'components/MetricCard/MetricCard';
import { useDateBasedMetric } from 'services/MetricService/hooks';
import { Threshold } from 'services/MetricService/types';

const DEFAULT_THRESHOLDS: Threshold[] = [
  {
    metric: 'repository_coverage',
    goal: 0,
    threshold: 0,
    isPlaceholder: true,
  },
];

export const RepositoryCoverage = ({ thresholds, timeRange, cardRef }: MetricCommonProps) => {
  const { data, isLoading } = useDateBasedMetric('total_asset_coverage', timeRange);
  const { t } = useTranslation();
  const transformLabels = useCallback((label: string) => t(`assetType.${label}`), [t]);
  const goal = useMemo(() => {
    if (thresholds?.length) {
      return `${thresholds[0].goal}%`;
    }

    return undefined;
  }, [thresholds]);

  const { formattedValue, trend } = useValueAndTrend(data);
  return (
    <MetricCard
      ref={cardRef}
      details={(
        <>

          {data?.narration ? (
            <DrillDownSummary
              narration={data.narration}
              subTitle='pages.securityImpactV3.components.repositoryCoverage.subTitle'
              title='pages.securityImpactV3.components.repositoryCoverage.title'
            />
          ) : null}

          <Actions />

          <DrillDownChart
            dateRange={timeRange}
            metricName='asset_coverage_by_type'
            subtitle='Repository Coverage'
            threshold={thresholds?.at(0)}
            title='Repository Coverage'
            transformLabels={transformLabels}
          />

        </>
      )}
      goal={goal}
      loading={isLoading}
      subtitle='pages.securityImpactV3.components.repositoryCoverage.subTitle'
      summaryComponent={<RepositoryCoverageSummary />}
      thresholds={thresholds ?? DEFAULT_THRESHOLDS}
      title='pages.securityImpactV3.components.repositoryCoverage.title'
      trend={trend}
      value={formattedValue}
    />
  );
};
