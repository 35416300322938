import { LineChartData } from 'services/MetricService/types';
import { backfillMissingDates } from 'services/MetricService/utils';
import { TimeAgo } from 'types/enums';
import { ScoreTrendMetric } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';

/**
 * Format the tooltip value to show percentage
 */
const formatTooltipValue = (score: number | null): string => (score === null ? '-' : `${score}%`);

/**
 * Prepare the chart data from organization score data
 */
export const prepareOrgScoreChartData = (
  data: ScoreTrendMetric | undefined,
  timeRange: TimeAgo,
): LineChartData<'date', 'score'> | null => {
  if (!data?.entitiesScores?.[0]?.scoresByDay) {
    return null;
  }

  const { scoresByDay } = data.entitiesScores[0];

  // Filter out days with null scores and ensure we have data
  const validScores = scoresByDay.filter((day) => day.score !== null);
  if (validScores.length === 0) {
    return null;
  }

  // Backfill missing dates to ensure continuous data
  const chartData = backfillMissingDates(validScores, timeRange, 'score');
  if (chartData.length === 0) {
    return null;
  }

  // Create tooltips with formatted values
  const tooltips = chartData.map((dataPoint) => ({
    score: formatTooltipValue(dataPoint.score),
  }));

  return {
    type: 'line',
    metricType: 'aggregative',
    data: chartData as LineChartData<'date', 'score'>['data'],
    xField: 'date',
    yFields: ['score'],
    label: {
      score: 'Organization Score',
    },
    tooltip: tooltips,
  };
};
