import { Action } from 'components/MetricDetails/components/Actions/Actions';
import { constants } from 'globalConstants';
import { TimeAgo } from 'types/enums';

export const useRiskRemediationActions = (timeFrame?: TimeAgo): Action[] => {
  const actions: Action[] = [
    {
      title: 'pages.securityImpactV3.components.riskRemediation.actions.viewOpenFindings.title',
      text: 'pages.securityImpactV3.components.riskRemediation.actions.viewOpenFindings.text',
      label: 'pages.securityImpactV3.components.riskRemediation.actions.viewOpenFindings.label',
      handleClick: () => {
        window.open(`/${constants.routes.FINDINGS}?${new URLSearchParams({
          resolution: 'OPEN',
          ignored: 'false',
          ...(timeFrame ? { time_ago: timeFrame } : {}),
        })}`, '_blank');
      },
    },
    {
      title: 'pages.securityImpactV3.components.riskRemediation.actions.manageWorkflows.title',
      text: 'pages.securityImpactV3.components.riskRemediation.actions.manageWorkflows.text',
      label: 'pages.securityImpactV3.components.riskRemediation.actions.manageWorkflows.label',
      handleClick: () => {
        window.open(`/${constants.routes.WORKFLOWS}`, '_blank');
      },
    },
  ];

  return actions;
};
