import styles from './RepositoryCoverageSummary.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { useResourcesCoverage } from 'services/MetricService/hooks';
import { useAssetTypeToVendor } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

export const RepositoryCoverageSummary = () => {
  const { data, isLoading } = useResourcesCoverage();
  const { getIconByAssetType } = useAssetTypeToVendor();

  if (isLoading) {
    return (
      <LoadingSkeleton style={{
        margin: 24,
        marginBottom: 0,
        height: 46 }}
      />
    );
  }

  return (
    <div className={styles.repositoryList}>
      {data?.resourcesCoverage.map((asset) => (
        <div key={asset.assetType}>
          <JitTooltip title={`assetTypes.${asset.assetType}`}>

            <div className={styles.item}>
              <JitIcon icon={getIconByAssetType(asset.assetType)} />

              <JitText component='span' text={asset.covered} />
            </div>
          </JitTooltip>

        </div>
      ))}
    </div>
  );
};
