import { FC } from 'react';

import { InsightsCard } from '../../baseComponents';

import { JitText } from 'components/JitText/JitText';
import { ThreatPreventionContent } from 'pages/InsightsPage/SubPages/SecurityImpactOld/components/DevProductivity/components/ThreatPreventionContent/ThreatPreventionContent';
import colors from 'themes/colors.module.scss';
import { ITrendingDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';

interface Props {
  devProductivityMetric: ITrendingDevProductivity;
}

export const ResolvedPreProductionComponent: FC<Props> = ({ devProductivityMetric }) => {
  const { preProdResolvedFindings, totalResolvedFindings } = devProductivityMetric;

  return (
    <InsightsCard
      content={<ThreatPreventionContent preProdResolvedFindings={preProdResolvedFindings} totalResolvedFindings={totalResolvedFindings} />}
      footer={<JitText color={colors.gray} text='pages.securityImpact.devProductivity.threatPrevention.description' />}
      header={{
        title: 'pages.securityImpact.devProductivity.threatPrevention.title',
      }}
      size='l'
      variant='top'
    />
  );
};
