import { useCallback, useState } from 'react';

import styles from './Goal.module.scss';
import { GoalValueInput } from './GoalValueInput';

import { JitText } from 'components/JitText/JitText';
import { Threshold } from 'services/MetricService/types';
import colors from 'themes/colors.module.scss';

interface ThresholdValue {
  goal: number | null;
  threshold: number | null;
}

interface GoalProps {
  autoFocus?: boolean;
  threshold: Threshold
  onChange: (value: Threshold) => void;
}

export const Goal = ({ onChange, threshold, autoFocus }: GoalProps) => {
  const [thresholdValue, setThresholdValue] = useState<ThresholdValue>(threshold.isPlaceholder ? { goal: null,
    threshold: null } : threshold);

  const handleChange = useCallback((value: number, key: keyof ThresholdValue) => {
    const newValue = { ...thresholdValue,
      [key]: value };

    setThresholdValue(newValue);
    if (typeof newValue.goal === 'number' && typeof newValue.threshold === 'number') {
      onChange({
        ...threshold,
        goal: newValue.goal,
        threshold: newValue.threshold,
      });
    }
  }, [onChange, threshold, thresholdValue]);

  return (
    <div className={styles.goalOptions}>

      <div className={styles.goalOption}>

        <JitText className={styles.goalQuestion} color={colors.lightGray} text='components.metricDetails.whatIsOrgGoal' />

        <GoalValueInput
          autoFocus={autoFocus}
          onChange={(value) => handleChange(value, 'goal')}
          placeholder='components.metricDetails.goalInputPlaceholder'
          value={thresholdValue.goal}
        />

      </div>

      <div className={styles.goalOption}>
        <JitText className={styles.goalQuestion} text='components.metricDetails.whatIsRiskTolerance' />

        <GoalValueInput
          onChange={(value) => handleChange(value, 'threshold')}
          placeholder='components.metricDetails.toleranceInputPlaceholder'
          value={thresholdValue.threshold}
        />
      </div>
    </div>
  );
};
