import { FC } from 'react';

import { useLeaderboardTable } from './hooks/useLeaderboardTable';
import styles from './LeaderboardTable.module.scss';

import { JitTable } from 'components/JitTable/JitTable';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';

interface Props {
  teams: IGetTeamResponseItem[];
  currentTeam?: IGetTeamResponseItem;
}

export const LeaderboardTable: FC<Props> = ({ teams, currentTeam }) => {
  const { tableInstance } = useLeaderboardTable({ teams,
    currentTeam });

  return (
    <div className={styles.tableWrapper} data-testid='leaderboard-table'>
      <JitTable
        cellPadding='30px 0px 30px 40px'
        headerCellPadding='16px 0px 16px 40px'
        isLoading={false}
        showPagination={false}
        tableInstance={tableInstance}
      />
    </div>
  );
};
