import { Dispatch, FC, SetStateAction } from 'react';

import { InsightsCard } from '../../baseComponents';
import styles from '../../baseComponents/InsightsCard/InsightsCard.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { DevelopersEfficiencyContent } from 'pages/InsightsPage/SubPages/SecurityImpactOld/components/DevProductivity/components/DevelopersEfficiencyContent/DevelopersEfficiencyContent';
import colors from 'themes/colors.module.scss';
import { ITrendingDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';

interface Props {
  setIsCalcDialogOpen: Dispatch<SetStateAction<boolean>>,
  devProductivityMetric: ITrendingDevProductivity;
}

export const DevelopersEfficiencyComponent: FC<Props> = ({ devProductivityMetric, setIsCalcDialogOpen }) => {
  const { hoursSaved, moneySaved } = devProductivityMetric;

  return (
    <InsightsCard
      content={<DevelopersEfficiencyContent hoursSaved={hoursSaved} moneySaved={moneySaved} />}
      footer={(
        <JitText
          className={styles.footerText}
          color={colors.gray}
          components={{
            button: <JitButton data-testid='DevelopersEfficiencyContent-editCalculation' onClick={() => { setIsCalcDialogOpen((prevState: boolean) => !prevState); }} variant='text' />,
          }}
          text='pages.overview.insights.developersTimeSaved'
        />
        )}
      header={{
        title: 'pages.overview.insights.developersEfficiency.title',
        infoTooltip: 'pages.overview.insights.developersEfficiency.tooltip',
      }}
      size='l'
      variant='bottom'
    />
  );
};
