import { FC, ReactNode } from 'react';

import { CustomCardIntegrationModal } from 'components/Integrations/CustomCardIntegrationModal/CustomCardIntegrationModal';
import { MultiProjectsManagementModal } from 'components/MultiProjectsManagementModal/MultiProjectsManagementModal';
import { Vendor } from 'types/enums';
import { IIntegration } from 'types/interfaces/Integrations/IIntegration';

interface IntegrationModalsProps {
  isOpened: boolean;
  component: ReactNode;
  showProjectsModal: boolean;
  toggleProjectsModal: () => void;
  vendor: string;
  customCardIntegration: IIntegration | null;
  closeCustomCardModal: () => void;
}

/**
 * Component to handle all modal displays for the integration card
 */
export const IntegrationModals: FC<IntegrationModalsProps> = ({
  isOpened,
  component,
  showProjectsModal,
  toggleProjectsModal,
  vendor,
  customCardIntegration,
  closeCustomCardModal,
}) => (
  <>
    {isOpened && component}

    {showProjectsModal && (
      <MultiProjectsManagementModal
        handleClose={toggleProjectsModal}
        vendor={vendor as Vendor}
      />
    )}

    {customCardIntegration && (
      <CustomCardIntegrationModal
        integration={customCardIntegration}
        isOpen={!!customCardIntegration}
        onClose={closeCustomCardModal}
        onSuccess={closeCustomCardModal}
      />
    )}
  </>
);
