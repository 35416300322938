import { FC } from 'react';

import { Section } from '../../../Section/Section';
import { Action } from '../../types';

import styles from './Recommendations.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';

interface Props {
  actions: Action[];
}

export const Recommendations: FC<Props> = ({ actions }) => (
  <Section subTitle='components.metricDetails.recommendedNextStep'>
    {actions.map((action) => (
      <div key={action.text}>
        <JitText text={action.label} />

        <JitGradientButton className={styles.button} onClick={action.handleClick}>
          <JitText text={action.text} />
        </JitGradientButton>
      </div>
    ))}
  </Section>
);
