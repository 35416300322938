import { FC } from 'react';

import { MultiProjectsManagementModal } from 'components/MultiProjectsManagementModal/MultiProjectsManagementModal';
import { useMultiProjectsManagementModal } from 'components/MultiProjectsManagementModal/useMultiProjectsManagementModal';

export const JiraProjectsManagementModalWrapper: FC = () => {
  const { isOpen, modalProps } = useMultiProjectsManagementModal();

  if (!isOpen) {
    return null;
  }

  return <MultiProjectsManagementModal {...modalProps} />;
};
