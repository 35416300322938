import { Ref, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAggregateWeeklyData } from '../../hooks/useAggregateWeeklyData';
import { useEnhanceDataWithThreshold } from '../../hooks/useEnhanceDataWithThreshold';

import { ChartSkeleton } from './components/ChartSkeleton/ChartSkeleton';
import { DrillDownChart } from './components/DrillDownChart/DrillDownChart';
import { DrillDownSummary } from './components/DrillDownSummary/DrillDownSummary';
import { useValueAndTrend } from './useValueAndTrend';

import { Chart } from 'components/Chart/Chart';
import { Summary } from 'components/MetricCard/components/Summary/Summary';
import { MetricCard, MetricCardProps, MetricCardRef } from 'components/MetricCard/MetricCard';
import { useDateBasedMetric } from 'services/MetricService/hooks';
import { GenericMetric } from 'services/MetricService/types';
import { TimeAgo } from 'types/enums';

interface Props extends Omit<MetricCardProps, 'loading' | 'trend' | 'mainComponent' | 'value' | 'summaryComponent'> {
  mainMetric: GenericMetric;
  drillDownMetric?: GenericMetric;
  dateRange: TimeAgo;
  summaryText?: string;
  cardRef: Ref<MetricCardRef>
}

export const GenericDateBasedMetric = <MainMetric extends string = string, DrillDownMetric extends string = string>
  ({ dateRange, mainMetric, drillDownMetric, summaryText, details, cardRef, ...rest }: Props) => {
  const { data: mainMetricData, isLoading } = useDateBasedMetric<MainMetric>(mainMetric, dateRange);

  // Apply weekly aggregation for ThreeMonths data
  const aggregatedData = useAggregateWeeklyData<MainMetric>(mainMetricData, dateRange);

  const { formattedValue, trend } = useValueAndTrend(aggregatedData);
  const { t } = useTranslation();

  const threshold = rest.thresholds?.at(0);

  const summaryComponent = useMemo(() => {
    if (!aggregatedData) {
      return undefined;
    }
    if (aggregatedData.narration?.description) {
      return <Summary summary={aggregatedData.narration?.description} />;
    }

    if (summaryText && formattedValue !== '-') {
      return <Summary summary={t(summaryText, { metric: formattedValue })} />;
    }
    return undefined;
  }, [aggregatedData, t, summaryText, formattedValue]);

  const enhancedMetricData = useEnhanceDataWithThreshold(aggregatedData, threshold);

  const drillDownChart = useMemo(() => {
    if (!drillDownMetric) {
      return undefined;
    }

    return <DrillDownChart<DrillDownMetric> dateRange={dateRange} metricName={drillDownMetric} subtitle={rest.title} threshold={threshold} title={rest.subtitle} />;
  }, [drillDownMetric, dateRange, rest.title, rest.subtitle, threshold]);

  return (
    <MetricCard
      ref={cardRef}
      details={(
        <>

          {aggregatedData?.narration ? (
            <DrillDownSummary
              narration={aggregatedData?.narration}
              subTitle={rest.subtitle}
              title={rest.title}
            />
          ) : null}

          {details}

          {drillDownChart}

        </>
      )}
      loading={isLoading}
      mainComponent={enhancedMetricData ? <Chart data={enhancedMetricData} /> : (<ChartSkeleton />)}
      summaryComponent={summaryComponent}
      trend={trend}
      value={formattedValue}
      {...rest}

    />
  );
};
