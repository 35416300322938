import { FC, useMemo, useState, useRef } from 'react';

import { NoIntegrationButton } from './components/NoIntegrationButton';
import styles from './CreateTicketButton.module.scss';
import { useCreateTicketAsync } from './hooks/useCreateTicketAsync';
import { useCreateTicketSync } from './hooks/useCreateTicketSync';
import { useVendorProjects } from './hooks/useVendorProjects';
import { useVendorTicketConfig } from './VendorConfig';

import { IconButton } from 'components/IconButton/IconButton';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ProjectSelectionPopover } from 'components/ProjectSelectionPopover/ProjectSelectionPopover';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useGetActiveIntegration } from 'context/IntegrationsContext/hooks/useGetActiveIntegration';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IFinding, TicketFinding } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

interface Props {
  onClose: (ticket?: TicketFinding) => void;
  findings: IFinding[];
  callToActionText?: string;
  fullSize?: boolean;
}

export const CreateTicketButton: FC<Props> = ({
  onClose,
  findings,
  callToActionText,
  fullSize = true,
}) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const iconButtonRef = useRef<HTMLDivElement>(null);
  const { frontEggUser } = useAuthContext();
  const { vendorTicketConfig } = useVendorTicketConfig();
  const { hasPermission } = useAuthContext();
  const { isLoading: isUserLoading } = useParagonAuth();
  const hasWriteTicketPermission = hasPermission(PERMISSIONS.WRITE_TICKETS);

  // Get active TMS integration
  const { activeIntegration } = useGetActiveIntegration(
    (integration) => integration.display?.categoryKey === IntegrationVendorType.tms,
  );

  // Always call hooks unconditionally
  const {
    vendorProjects,
    hasMultipleProjects,
  } = useVendorProjects(activeIntegration);

  const { createStory: createStorySync } = useCreateTicketSync();
  const { createStory: createStoryAsync } = useCreateTicketAsync({
    onSuccess: (ticket: TicketFinding) => {
      onClose({
        ticketUrl: ticket.ticketUrl,
        vendor: activeIntegration!.vendor,
        createdAt: new Date().toISOString(),
        userId: frontEggUser.id,
      });
    },
    onError: () => {
      onClose();
    },
  });

  const isSingleFindingWithTickets = useMemo(() => findings.length === 1 && !!findings[0].tickets?.length, [findings]);

  if (!activeIntegration && fullSize) return <NoIntegrationButton hoverText={callToActionText} />;
  if (!activeIntegration) return null;

  const vendorConfig = vendorTicketConfig[activeIntegration.vendor as keyof typeof vendorTicketConfig];

  const handleCreateStory = async (projectName?: string) => {
    if (!activeIntegration) return;
    setIsInProgress(true);
    setIsPopoverOpen(false);
    setAnchorElement(null);

    if (activeIntegration.provider === IntegrationProvider.JIT) {
      const ticketUrl = await createStorySync(
        findings,
        activeIntegration.integrationId,
        vendorConfig.openTicket,
        activeIntegration.provider,
        null,
        projectName,
      );
      if (ticketUrl) {
        onClose({
          ticketUrl: ticketUrl!,
          vendor: activeIntegration.vendor,
          createdAt: new Date().toISOString(),
          userId: frontEggUser.id,
        });
      } else {
        onClose();
      }
    } else {
      await createStoryAsync(
        findings,
        activeIntegration.integrationId,
        vendorConfig.openTicket,
        activeIntegration.provider,
        null,
        projectName === 'default' ? undefined : projectName,
      );
    }
    setIsInProgress(false);
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    if (hasMultipleProjects) {
      setAnchorElement(event.currentTarget as HTMLElement);
      setIsPopoverOpen(true);
    } else {
      handleCreateStory();
    }
  };

  const handleIconButtonClick = (event: React.MouseEvent) => {
    if (isSingleFindingWithTickets) {
      window.open(findings[0].tickets![0].ticketUrl, '_blank');
    } else if (hasMultipleProjects) {
      setAnchorElement(event.currentTarget as HTMLElement);
      setIsPopoverOpen(true);
    } else {
      handleCreateStory();
    }
  };

  const handleProjectSelect = (projectKey: string, event: React.MouseEvent) => {
    event.stopPropagation();
    handleCreateStory(projectKey);
  };

  const isDisabled = isInProgress || !activeIntegration || isUserLoading;
  const ticketName = i18n.t(vendorConfig.ticketName)
    .toString();
  const textColor = !isInProgress && hasWriteTicketPermission ? colors.iris : colors.grey;

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setAnchorElement(null);
  };

  if (fullSize) {
    return (
      <div className={styles.wrapper}>
        <div ref={buttonRef}>
          <JitButton
            data-testid='create-ticket-button'
            disabled={isDisabled}
            isLoading={isInProgress}
            isReadOnly={!hasWriteTicketPermission}
            noHover
            onClick={handleButtonClick}
            sx={{
              ':hover': {
                borderColor: colors.iris,
              },
            }}
            variant='outlined'
          >
            <div className={styles.button}>
              <JitIcon
                color={colors.iris}
                data-testid='integration-vendor-icon'
                icon={vendorConfig.icon}
                size='14px'
                strokeColor={hasWriteTicketPermission ? colors.iris : colors.lightGray}
              />

              <JitText
                color={textColor}
                data-testid='create-ticket-button-text'
                params={{ ticketName }}
                text='tickets.baseText'
              />
            </div>
          </JitButton>
        </div>

        {hasMultipleProjects && (
          <ProjectSelectionPopover
            anchorEl={anchorElement}
            isOpen={isPopoverOpen}
            onClose={handleClosePopover}
            onProjectSelect={handleProjectSelect}
            projects={vendorProjects}
          />
        )}
      </div>
    );
  }

  const tooltipText = (
    isSingleFindingWithTickets
      ? i18n.t('tickets.viewText', {
        ticketName: i18n.t(vendorConfig.ticketName)
          .toString(),
      })
      : i18n.t('tickets.baseText', {
        ticketName: i18n.t(vendorConfig.ticketName)
          .toString(),
      })
  );

  return (
    <div
      ref={iconButtonRef}
      style={{
        position: 'relative',
        display: 'inline-block',
      }}
    >
      <IconButton
        color={colors.iris}
        completed={isSingleFindingWithTickets}
        icon={vendorConfig.icon}
        isDisabled={isDisabled || !hasWriteTicketPermission}
        onClick={handleIconButtonClick}
        testId='create-ticket-icon-button'
        tooltipText={tooltipText}
      />

      {hasMultipleProjects && (
        <ProjectSelectionPopover
          anchorEl={anchorElement}
          isOpen={isPopoverOpen}
          onClose={handleClosePopover}
          onProjectSelect={handleProjectSelect}
          projects={vendorProjects}
        />
      )}
    </div>
  );
};
