/* eslint-disable react/jsx-no-literals */
import { FlagOutlined } from '@mui/icons-material';
import { t } from 'i18next';
import React, { MouseEventHandler } from 'react';

import styles from './Goal.module.scss';

interface GoalProps {
  value: string;
  goal?: string;
  onSetGoal?: MouseEventHandler<HTMLDivElement>;
}

export const Goal: React.FC<GoalProps> = ({ value, goal, onSetGoal }) => (
  <div className={styles.valueWrapper}>
    <span className={styles.value}>{value}</span>

    <span className={styles.separator}>/</span>

    {goal ? (
      <div className={styles.goalValue}>
        <span className={styles.goalLabel}>{t('components.metricCard.goal').toString()}</span>

        <span className={styles.goalNumber}>
          <FlagOutlined />

          {goal}
        </span>
      </div>
    ) : (
      <div
        className={styles.addGoal}
        onClick={onSetGoal}
        role='button'
        tabIndex={0}
      >
        <FlagOutlined />

        <div className={styles.addGoalText}>{t('components.metricCard.addGoal').toString()}</div>
      </div>
    )}
  </div>
);
