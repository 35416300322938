import { useMemo } from 'react';

import { BaseProps } from '../../types';

import { getColor } from 'components/Chart/utils';
import { JitPieChart } from 'components/graphs/JitPieChart/JitPieChart';
import { PieChartData } from 'services/MetricService/types';

export const PieChart = <XField extends string = string, YFields extends string = string>({ data, xField, yField, height }: PieChartData<XField, YFields> & BaseProps) => {
  const pieData = useMemo(() => data.map((item, idx) => ({
    name: item[xField],
    value: item[yField],
    color: getColor(idx),
  })), [data, xField, yField]);

  return (
    <JitPieChart
      data={pieData}
      height={height}
    />
  );
};
