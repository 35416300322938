import { FC } from 'react';

import { ActionConfigInputProps } from './ActionConfigInput';
import { MicrosoftteamsChannelPicker } from './ConfigInputs/MicrosotteamsChannelPicker/MicrosoftteamsChannelPicker';
import { ProjectPicker } from './ConfigInputs/ProjectPicker/ProjectPicker';
import { SlackChannelPicker } from './ConfigInputs/SlackChannelPicker/SlackChannelPicker';
import { VendorPicker } from './ConfigInputs/VendorPicker/VendorPicker';

import { IWorkflowActionStepOptionConfigSchema } from 'types/interfaces/Workflows/IWorkflowStepOptions';

export const configInputsByTypeMap: Record<IWorkflowActionStepOptionConfigSchema['type'], FC<ActionConfigInputProps> | undefined> = {
  VendorPicker,
  SlackChannelPicker,
  MicrosoftteamsChannelPicker,
  ProjectPicker,
  string: undefined,
  number: undefined,
  boolean: undefined,
  'string-array': undefined,
};
