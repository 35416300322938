import { AuthenticatedConnectUser } from '@useparagon/connect';
import { useCallback, useState } from 'react';

import { Vendor } from 'types/enums';

interface MultiProjectsManagementModalProps {
  paragonUser: AuthenticatedConnectUser;
  installationId: string;
  vendor: Vendor;
}

interface UseMultiProjectsManagementModalReturn {
  isOpen: boolean;
  openMultiProjectsManagementModal: (props: MultiProjectsManagementModalProps) => void;
  closeMultiProjectsManagementModal: () => void;
  modalProps: {
    handleClose: () => void;
    paragonUser?: AuthenticatedConnectUser;
    installationId?: string;
    vendor?: Vendor;
  };
}

export const useMultiProjectsManagementModal = (): UseMultiProjectsManagementModalReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [paragonUser, setParagonUser] = useState<AuthenticatedConnectUser | undefined>(undefined);
  const [installationId, setInstallationId] = useState<string | undefined>(undefined);
  const [vendor, setVendor] = useState<Vendor | undefined>(undefined);

  const openMultiProjectsManagementModal = useCallback(({ paragonUser: user, installationId: id, vendor: v }: MultiProjectsManagementModalProps) => {
    setParagonUser(user);
    setInstallationId(id);
    setVendor(v);
    setIsOpen(true);
  }, []);

  const closeMultiProjectsManagementModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    openMultiProjectsManagementModal,
    closeMultiProjectsManagementModal,
    modalProps: {
      handleClose: closeMultiProjectsManagementModal,
      paragonUser,
      installationId,
      vendor,
    },
  };
};
