import { MetricCommonProps } from '../../types';
import { GenericDateBasedMetric } from '../GenericDateBasedMetric/GenericDateBasedMetric';

import { Actions } from './components/Actions/Actions';

import { Threshold } from 'services/MetricService/types';

const SHIFT_LEFT_THRESHOLDS: Threshold[] = [
  {
    metric: 'shift_left',
    goal: 0,
    threshold: 0,
    isPlaceholder: true,
  },
];

export const ShiftLeft = ({ timeRange, thresholds, cardRef }: MetricCommonProps) => (
  <GenericDateBasedMetric
    cardRef={cardRef}
    dateRange={timeRange}
    details={<Actions timeFrame={timeRange} />}
    drillDownMetric='shift_left_score_drill_down_metric'
    mainMetric='shift_left_score'
    subtitle='pages.securityImpactV3.components.shiftLeft.subtitle'
    summaryText='pages.securityImpactV3.components.shiftLeft.summary'
    thresholds={thresholds ?? SHIFT_LEFT_THRESHOLDS}
    title='pages.securityImpactV3.components.shiftLeft.title'
  />
);
