import { useShiftLeftActions } from './useShiftLeftActions';

import { Actions as ActionsComponent } from 'components/MetricDetails/components/Actions/Actions';
import { TimeAgo } from 'types/enums';

interface Props {
  timeFrame: TimeAgo;
}

export const Actions = ({ timeFrame }: Props) => {
  const actions = useShiftLeftActions(timeFrame);

  return <ActionsComponent actions={actions} />;
};
