import { FetchTeamOptions } from './useTeamsService';

import { Queries } from 'types/enums/Queries';

export const base = [Queries.Teams];

export const fetchTeams = (queryParams: FetchTeamOptions) => [
  ...base,
  queryParams,
];
