import { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react';

import { Goal } from './components/Goal/Goal';
import styles from './MetricCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { SubmetricLabelTransform } from 'components/MetricDetails/components/Goals/types';
import { MetricDetails } from 'components/MetricDetails/MetricDetails';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import { Threshold } from 'services/MetricService/types';

export interface MetricCardRef {
  openDetails: () => void;
}
export interface MetricCardProps {
  title: string;
  subtitle: string;
  value: string;
  trend?: number;
  mainComponent?: ReactNode;
  summaryComponent?: ReactNode;
  goal?: string;
  details?: ReactNode;
  loading?: boolean;
  thresholds: Array<Threshold>;
  transformSubmetricLabel?: SubmetricLabelTransform;
}

export const MetricCard = forwardRef<MetricCardRef, MetricCardProps>(({
  title,
  subtitle,
  value,
  trend,
  mainComponent,
  summaryComponent,
  goal,
  details,
  loading,
  thresholds,
  transformSubmetricLabel,
}, ref) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [shouldFocusOnGoal, setShouldFocusOnGoal] = useState(false);
  const handleSetGoal = useCallback(() => {
    setShouldFocusOnGoal(true);
  }, []);
  if (!detailsOpen && shouldFocusOnGoal) {
    setShouldFocusOnGoal(false);
  }

  const handleOpenDetails = useCallback(() => {
    setDetailsOpen(true);
  }, []);

  const handleCloseDetails = useCallback(() => {
    setDetailsOpen(false);
  }, []);

  useImperativeHandle(ref, () => ({
    openDetails: handleOpenDetails,
  }), [handleOpenDetails]);

  return (
    <>
      <div
        className={styles.card}
        data-testid='metric-card'
        onClick={handleOpenDetails}
        role='button'
        tabIndex={0}
      >

        {typeof trend === 'number' ? <div className={styles.trendContainer}><PercentageChip displayBorder percentage={Math.round(trend)} /></div> : null}

        <div className={styles.container}>
          <div className={styles.header}>
            <JitText bold className={styles.title} size='xxl' text={title} variant='h2' />
          </div>

          <div className={styles.metricSection}>

            <Goal
              goal={goal}
              onSetGoal={handleSetGoal}
              value={value}
            />

            {loading && <LoadingSkeleton className={styles.loadingSkeleton} style={{ height: '100%' }} />}

          </div>

          <p className={styles.subtitle}>

            <JitText color='inherit' component='span' text={subtitle} />
          </p>

          {mainComponent && (
          <div className={styles.chart}>
            {mainComponent}
          </div>
          )}

          {summaryComponent && (
            <div className={styles.divider} />
          )}

          {summaryComponent}
        </div>
      </div>

      <MetricDetails
        focusOnGoal={shouldFocusOnGoal}
        onClose={handleCloseDetails}
        open={detailsOpen}
        thresholds={thresholds}
        transformSubmetricLabel={transformSubmetricLabel}
      >
        {details}
      </MetricDetails>
    </>
  );
});
