import { useCallback, useRef } from 'react';

import { FindingDetails } from '../FindingDetails/FindingDetails';

import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { useFindingById } from 'services/FindingsService/hooks';
import { IFinding } from 'types/interfaces';

export const FindingDetailsPanel = () => {
  const {
    selectedRow,
    updateSelectedRow,
    selectedRowId,
  } = useFindingTableContext();

  const { data: fetchedFinding } = useFindingById(selectedRowId, { enabled: !selectedRow });

  const handleClosePanel = useCallback(() => {
    updateSelectedRow(null);
  }, [updateSelectedRow]);

  const finding = selectedRow || fetchedFinding;

  const cachedFindingRef = useRef(finding);
  if (selectedRowId) {
    cachedFindingRef.current = finding || cachedFindingRef.current;
  } else {
    cachedFindingRef.current = undefined;
  }

  const findingToRender = cachedFindingRef.current;

  // Create a wrapper function that conforms to React.Dispatch<React.SetStateAction<IFinding | null>>
  const setFindingWrapper: React.Dispatch<React.SetStateAction<IFinding | null>> = useCallback((value) => {
    // Handle both direct value and function updater pattern
    const newValue = typeof value === 'function' ? value(finding || null) : value;
    updateSelectedRow(newValue);
  }, [finding, updateSelectedRow]);

  if (!findingToRender) {
    return null;
  }

  return (
    <JitSlidingPanel closePanel={handleClosePanel} isOpen={!!findingToRender}>
      <FindingDetails
        finding={findingToRender}
        onClosePanel={handleClosePanel}
        setFinding={setFindingWrapper}
      />
    </JitSlidingPanel>
  );
};
