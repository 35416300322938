import Markdown from 'markdown-to-jsx';
import { FC, MouseEvent, ReactElement, useState, useRef, useEffect } from 'react';

import styles from './ExtendedActionActions.module.scss';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMultiActionButton } from 'components/JitMultiActionButton/JitMultiActionButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { ProjectSelectionPopover } from 'components/ProjectSelectionPopover/ProjectSelectionPopover';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useGetActiveIntegration } from 'context/IntegrationsContext/hooks/useGetActiveIntegration';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { IActionOption, IMouseEvent } from 'types/interfaces';
import { markdownOptionsProps } from 'utils/constants/markdownOptionsProps';
import { ProjectConfig, useProjectConfigurations } from 'utils/hooks/useProjectConfigurations';

// Define vendor-neutral project interface for the ProjectSelectionPopover
// This follows the expected structure in ProjectSelectionPopover
interface TMSProject {
  key: string;
  name: string;
}

interface ExtendedCodeActionsProps {
  disableActions: boolean
  remediateFindingsAction?: (event: IMouseEvent) => void
  handleOpenIgnoreDialog: (event: IMouseEvent) => void
  handleCreateStory: (event: IMouseEvent, projectKey?: string) => void
  fixButtonText?: string
  guidelines: string
  isOpenPRLoading: boolean
  isCreateStoryLoading: boolean
  isCreateStoryDisabled: boolean
  actionId: string,
  actionName: string,
  ignoreButtonText: string,
  createTicketText: string,
  vendor?: Vendor, // Optional vendor prop, will use active TMS if not provided
}

export const ExtendedActionActions: FC<ExtendedCodeActionsProps> = ({
  disableActions, remediateFindingsAction, handleOpenIgnoreDialog, guidelines, isOpenPRLoading, actionId, actionName, fixButtonText,
  handleCreateStory, ignoreButtonText, createTicketText, isCreateStoryLoading, isCreateStoryDisabled, vendor,
}) => {
  const [isGuidelineOpen, setIsGuidelineOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [projects, setProjects] = useState<ProjectConfig[]>([]);
  const anchorRef = useRef<HTMLDivElement>(null);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  // Get the active TMS integration if no vendor is provided
  const { activeIntegration } = useGetActiveIntegration(
    (integration) => integration.display?.categoryKey === IntegrationVendorType.tms,
  );

  // Use the provided vendor or fall back to the active TMS vendor
  const tmsVendor = vendor || (activeIntegration?.vendor as Vendor);

  // Use the shared hook to fetch projects for the vendor
  const { fetchProjects, isLoading: isProjectsLoading } = useProjectConfigurations(tmsVendor);

  useEffect(() => {
    const loadProjects = async () => {
      if (tmsVendor) {
        const projectsList = await fetchProjects();
        setProjects(projectsList);
      }
    };

    loadProjects();
  }, [tmsVendor, fetchProjects]);

  // Check if there are multiple projects available
  const hasMultipleProjects = projects.length > 1;

  const handleCreateStoryWithProjectSelect = (event: IMouseEvent) => {
    if (hasMultipleProjects) {
      setAnchorElement(event.currentTarget as HTMLElement);
      setIsPopoverOpen(true);
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    // If no multiple projects, directly call the original handler
    handleCreateStory(event);
  };

  const handleProjectSelect = (projectKey: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setIsPopoverOpen(false);
    setAnchorElement(null);

    // Create a synthetic mouse event to pass to the original handler
    const syntheticEvent = {
      ...event,
      currentTarget: event.currentTarget,
      target: event.target,
      preventDefault: () => {},
      stopPropagation: () => {},
    } as unknown as IMouseEvent;

    handleCreateStory(syntheticEvent, projectKey === 'default' ? undefined : projectKey);
  };

  const createStoryWrapper = (wrapped: ReactElement) => (
    <JitTooltip
      followCursor={false}
      offset={[0, 10]}
      placement='left'
      title='tickets.noIntegrationActionTooltip'
    >
      {wrapped}
    </JitTooltip>
  );

  const isCreateStoryCurrentlyDisabled = disableActions || isCreateStoryDisabled || isCreateStoryLoading || isProjectsLoading;

  const buttonsOptions: IActionOption[] = [
    {
      handleClick: handleOpenIgnoreDialog,
      text: ignoreButtonText,
      disabled: disableActions,
    },
    {
      handleClick: handleCreateStoryWithProjectSelect,
      text: createTicketText,
      disabled: isCreateStoryCurrentlyDisabled,
      wrapper: isCreateStoryCurrentlyDisabled ? createStoryWrapper : undefined,
    },
  ];

  if (remediateFindingsAction && fixButtonText) {
    // Since we don't always have a primary fix action, we'll add it only if it's available.
    // For example, non-code controls don't have a "fix" action.
    buttonsOptions.unshift({
      handleClick: remediateFindingsAction,
      text: fixButtonText,
      disabled: disableActions || isOpenPRLoading,
    });
  }

  const handleOpenCloseGuideline = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setIsGuidelineOpen((prev: boolean) => !prev);
    sendAnalyticsEvent({
      action: 'guidelines-clicked',
      params: {
        actionId,
        actionName,
      },
    });
  };

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setAnchorElement(null);
  };

  // Convert ProjectConfig[] to TMSProject[] for the ProjectSelectionPopover
  const tmsProjects: TMSProject[] = projects.map((project) => ({
    key: project.id, // Use id as the key for TMSProject
    name: project.name,
  }));

  return (
    <div className={styles.wrapper} data-testid='actionCardActions'>
      <div className={styles.cardActions}>
        <div className={styles.actionGuidelinesWrapper} onClick={handleOpenCloseGuideline} role='button' tabIndex={0}>
          <JitIcon color={colors.iris} icon={ArrowRight} rotateDegrees={!isGuidelineOpen ? 0 : 90} />

          <JitText
            bold
            color={colors.iris}
            data-testid='actionCardGuidelines'
            display='inline'
            size='m'
            sx={{
              '&:hover': {
                textDecoration: 'underline',
                textDecorationColor: colors.iris,
                textUnderlineOffset: '3px',
              },
            }}
            text='pages.actions.actionCard.actions.guidelines'
          />
        </div>

        <div
          ref={anchorRef}
          style={{
            display: 'inline-block',
            position: 'relative',
          }}
        >
          <JitMultiActionButton
            data-testid='multiActionButton'
            disableActions={disableActions}
            isLoading={isOpenPRLoading || isCreateStoryLoading || isProjectsLoading}
            options={buttonsOptions}
          />

          {hasMultipleProjects && (
            <ProjectSelectionPopover
              anchorEl={anchorElement}
              isOpen={isPopoverOpen}
              onClose={handleClosePopover}
              onProjectSelect={handleProjectSelect}
              projects={tmsProjects}
            />
          )}
        </div>
      </div>

      {isGuidelineOpen && (
        <Markdown className={styles.markdownText} data-testid='markdown' style={{ color: colors.lightGray }} {...markdownOptionsProps}>
          {guidelines}
        </Markdown>
      )}
    </div>
  );
};
