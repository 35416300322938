import { capitalize } from 'lodash';
import { FC, useState, useEffect, useCallback } from 'react';

import { ActionConfigInputProps } from '../../ActionConfigInput';

import styles from './ProjectPicker.module.scss';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { Vendor } from 'types/enums';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';
import { ProjectConfig, useProjectConfigurations } from 'utils/hooks/useProjectConfigurations';

export const ProjectPicker: FC<ActionConfigInputProps> = ({
  configSchema,
  actionConfig,
  updateActionConfig,
}) => {
  const projectId = actionConfig[configSchema.key];
  const vendor = actionConfig.vendor as Vendor;
  const { getIconByVendor } = useDetailsByVendor();
  const { fetchProjects, isLoading } = useProjectConfigurations(vendor);

  const [projects, setProjects] = useState<ProjectConfig[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectConfig | null>(
    projectId
      ? {
        id: projectId,
        name: projectId,
        externalId: projectId,
      }
      : {
        id: 'default',
        name: i18n.t('tickets.defaultProject').toString(),
        externalId: undefined,
      },
  );

  const loadProjects = useCallback(async () => {
    if (!vendor) return;

    const projectsList = await fetchProjects();
    setProjects(projectsList);
  }, [vendor, fetchProjects]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  const handleProjectSelect = (menuItem: Partial<MenuItemType>) => {
    const project = projects.find((p) => p.id === menuItem.itemKey);
    if (project) {
      setSelectedProject(project);
      updateActionConfig(configSchema.key, project.id === 'default' ? null : project.id);
    }
  };

  const menuItems = projects.length > 0
    ? projects.map((project) => ({
      itemKey: project.id,
      itemName: project.name,
      itemContent: project.name,
    }))
    : [{
      itemKey: 'no-projects',
      itemName: i18n.t('tickets.projectPicker.noProjects').toString(),
      itemContent: <JitText color='secondary' size='s' text={i18n.t('pages.workflows.editWorkflowPage.projectPicker.noProjects').toString()} />,
      disabled: true,
    }];

  const vendorIcon = getIconByVendor(vendor, undefined, true);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <JitIcon icon={vendorIcon} size={24} />

        <JitText size='m' text={i18n.t('pages.workflows.editWorkflowPage.projectPicker.selectProjectVendor', { vendor: capitalize(vendor) }).toString()} />
      </div>

      <JitDropdownNew
        defaultDisplayText={i18n.t('pages.workflows.editWorkflowPage.projectPicker.selectProject').toString()}
        displayText={selectedProject?.name}
        isEnabled={!isLoading}
        isLoading={isLoading}
        menuItems={menuItems}
        onMenuItemClick={handleProjectSelect}
      />
    </div>
  );
};
