import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as keys from './keys';
import { getGenericMetric, getMTTRMetric, getThresholds, getScoreTrendMetric,
  updateThreshold,
  getResourceCoverageMetric } from './MetricService';
import { GenericMetric, Threshold } from './types';
import { formatDateBasedMetric } from './utils';

import { TimeAgo } from 'types/enums';
import { ScoreEntityType } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';

export const useThresholds = () => useQuery({
  queryKey: keys.thresholds,
  queryFn: () => getThresholds(),
});

export const useUpdateThreshold = () => {
  const queryClient = useQueryClient();
  return useMutation({
    onMutate: async (data: Threshold) => {
      const oldThresholds = queryClient.getQueryData<Array<Threshold>>(keys.thresholds);
      queryClient.setQueryData(keys.thresholds, (current: Array<Threshold> = []) => {
        let didUpdate = false;
        const updated = current.map((threshold) => {
          if (threshold.metric === data.metric && threshold.metricSubtype === data.metricSubtype) {
            didUpdate = true;
            return data;
          }
          return threshold;
        });
        if (didUpdate) {
          return updated;
        }
        return [...current, data];
      });

      return { oldThresholds };
    },
    mutationFn: async (data: Threshold) => updateThreshold({
      ...data,
      metric_subtype: data.metricSubtype,
    }),
    onError: (error, data, ctx) => {
      queryClient.setQueryData(keys.thresholds, ctx?.oldThresholds);
    },
    onSuccess: async (result) => {
      if (result.metric !== 'mttr') {
        return;
      }

      // these metrics are dependent on mttr
      await Promise.all([
        queryClient.invalidateQueries(keys.dateBasedMetricBase('mttr_threshold_compliance_by_criticality')),
        queryClient.invalidateQueries(keys.dateBasedMetricBase('mttr_overall_threshold_compliance')),
      ]);
    },
  });
};

export const useMTTRMetric = (range: TimeAgo) => useQuery({
  queryKey: keys.mttr(range),
  queryFn: () => getMTTRMetric(range),
});

export const useDateBasedMetric = <T extends string = string>(name: GenericMetric, range: TimeAgo) => useQuery({
  queryKey: keys.dateBasedMetric(name, range),
  queryFn: async () => {
    const result = await getGenericMetric<'date', T>(name, range);

    return formatDateBasedMetric(result);
  } });

export const useOrgScoreTrending = (range: TimeAgo) => useQuery({
  queryKey: keys.teamsScoreTrending(range),
  queryFn: () => getScoreTrendMetric({
    period: range,
    params: {
      entities_type: ScoreEntityType.Org,
    },
  }),
});

export const useTeamTrendQuery = (teamId: string) => useQuery({
  queryKey: keys.teamTrend(teamId),
  queryFn: async () => getScoreTrendMetric({
    period: TimeAgo.OneWeek,
    params: {
      entities_type: ScoreEntityType.Team,
      entities_ids: teamId,
    },
  }),
});

export const useResourcesCoverage = () => useQuery({
  queryKey: keys.resourcesCoverage,
  queryFn: getResourceCoverageMetric,
});
