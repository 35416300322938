/**
 * Extracts a display label from a Slack-related value object
 * @param value - The value to extract the label from (can be a Slack channel object or other value)
 * @returns A string representation of the value
 */
export const extractValueLabel = (value: unknown): string => {
  if (!value) return '';

  if (typeof value === 'object' && value !== null) {
    if ('name' in value) {
      return value.name as string;
    }
    if ('channelId' in value) {
      return (value as { channelId: string }).channelId;
    }
    return value.toString();
  }

  return value.toString();
};
