import { useCallback, memo, ChangeEvent, useMemo } from 'react';

import { JitPasswordInput } from 'components/JitPasswordInput/JitPasswordInput';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { ICardFieldProps } from 'types/interfaces/Integrations/IIntegration';

interface Props {
  field: ICardFieldProps;
  value: string;
  onChange: (fieldName: string, value: string) => void;
  disabled?: boolean;
}

const CustomCardIntegrationField = memo(({
  field,
  value,
  onChange,
  disabled = false,
}: Props) => {
  const handleInputChange = useCallback((newValue: string) => {
    onChange(field.name, newValue);
  }, [field.name, onChange]);

  // Render the input based on the field type
  const renderedInput = useMemo(() => {
    switch (field.type) {
      case 'password':
        return (
          <JitPasswordInput
            disabled={disabled}
            inputDataTestId={`card-field-${field.name}`}
            inputProps={{
              'data-testid': `card-field-${field.name}`,
              value: value || '',
              onChange: (e: ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value),
              fullWidth: true,
            }}
          />
        );
      case 'string':
      default:
        return (
          <JitTextInput
            data-testid={`card-field-${field.name}`}
            debounceDelay={50}
            disabled={disabled}
            onChange={handleInputChange}
            value={value || ''}
          />
        );
    }
  }, [field.name, field.type, disabled, value, handleInputChange]);

  return (
    <div>
      <JitText bold text={field.name} />

      {field.description && <JitText muted text={field.description} />}

      {renderedInput}
    </div>
  );
});

export { CustomCardIntegrationField };
