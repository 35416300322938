import { identity } from 'lodash';
import { Fragment } from 'react/jsx-runtime';

import { Section } from '../Section/Section';

import { Goal } from './components/Goal/Goal';
import { SubmetricLabelTransform } from './types';

import { JitText } from 'components/JitText/JitText';
import { useUpdateThreshold } from 'services/MetricService/hooks';
import { Threshold } from 'services/MetricService/types';

interface GoalsProps {
  thresholds: Array<Threshold>,
  transformSubmetricLabel?: SubmetricLabelTransform
  focus?: boolean
}

export const Goals = ({ thresholds, transformSubmetricLabel = identity, focus }: GoalsProps) => {
  const { mutateAsync } = useUpdateThreshold();

  if (!thresholds.length) {
    return null;
  }

  return (
    <Section section='components.metricDetails.goalSectionTitle' title='components.metricDetails.setYourTargetGoal'>
      <JitText muted text='components.metricDetails.goalDescription' />

      {thresholds.map((threshold, idx) => (
        <Fragment key={`${threshold.metric} ${threshold.metricSubtype}`}>
          {thresholds.length > 1 && <JitText text={transformSubmetricLabel(threshold.metricSubtype)} />}

          <Goal autoFocus={focus && idx === 0} onChange={mutateAsync} threshold={threshold} />
        </Fragment>
      ))}

    </Section>
  );
};
