export const securityImpactV3 = {
  title: 'Organization overview',
  subTitle: 'Security report since your organization started using Jit until today: <dates />',
  components: {
    mttr: {
      title: 'Mean Time to Remediate (MTTR)',
      subTitle: 'MTTR by Severity',
      details: {
        subtitle: 'Daily resolution time',
        title: 'Mean Time to Remediate (MTTR)',
      },
      actions: {
        configureWorkflows: {
          title: 'Configure workflows',
          label: 'By configuring workflows, you can automate the resolution of security findings.',
          text: 'Configure workflows',
        },
        addressOldestFindings: {
          title: 'Address oldest findings',
          label: 'Address the oldest findings in your organization.',
          text: 'Address oldest findings',
        },
      },
      goals: {
        labels: {
          CRITICAL: 'Time to resolve Critical findings (hours)',
          HIGH: 'Time to resolve High findings (hours)',
          MEDIUM: 'Time to resolve Medium findings (hours)',
          LOW: 'Time to resolve Low findings (hours)',
          percentage: 'Percentage of findings resolved within time',
        },
      },
    },
    falsePositiveReduction: {
      title: 'Detection & False Positive Reduction',
      subtitle: 'Findings Assigned High Priority of total Findings Detected.',
      summary: '{{metric}} of findings are correctly prioritized, ensuring high-risk issues get attention first.',
      actions: {
        viewResources: {
          title: 'Resource Investigation',
          text: 'View Resources',
          label: 'Examine resources with high-priority findings',
        },
        reviewHighPriorityFindings: {
          title: 'High Priority Analysis',
          text: 'Review High Priority Findings',
          label: 'Check backlog of high-priority findings in the selected time frame',
        },
      },
    },
    shiftLeft: {
      title: 'Shift Left',
      subtitle: 'The percentage of findings resolved before production.',
      summary: '{{metric}} of security findings are fixed before reaching production. Increasing pre-production fixes can further reduce vulnerabilities.',
      actions: {
        workflowConfiguration: {
          title: 'Workflow Configuration',
          text: 'Configure Shift-Left Workflows',
          label: 'Set up workflows for early detection and remediation',
        },
        ignoredFindingsReview: {
          title: 'Ignored Findings Review',
          text: 'Review Ignored Findings',
          label: 'Check findings marked as accepted risks or false positives',
        },
      },
    },
    repositoryCoverage: {
      title: 'Resources Coverage',
      subTitle: 'Security coverage across your resources',
      actions: {
        manageResources: {
          title: 'Manage Resources',
          label: 'View and manage resources with the lowest security coverage',
          text: 'Manage Resources',
        },
        improveSecondaryCoverage: {
          title: 'Improve Secondary Coverage',
          label: 'Address resources with second-lowest security coverage',
          text: 'Improve Secondary Coverage',
        },
      },
    },
    teams: {
      title: 'Team Security Performance',
      subTitle: 'Top three teams',
      details: {
        title: 'Team Security Performance',
        subtitle: 'Code changes without security findings of total PRs opened',
      },
      actions: {
        improveWorstTeam: {
          title: 'Improve Worst Team',
          label: 'Work with the lowest-performing team',
          text: 'Improve Worst Team',
        },
        addressSecondTeam: {
          title: 'Address Second Team',
          label: 'Support the second-lowest performing team',
          text: 'Address Second Team',
        },
      },
    },
    riskRemediation: {
      title: 'Risk Remediation',
      subtitle: 'The percentage of resolved findings over total findings.',
      summary: '{{metric}} of all security findings have been resolved - showing strong remediation progress.',
      actions: {
        viewOpenFindings: {
          title: 'Open Findings Review',
          text: 'View Open Findings',
          label: 'Check all open findings in the selected time frame',
        },
        manageWorkflows: {
          title: 'Workflow Management',
          text: 'Manage Workflows',
          label: 'Configure automated workflows for remediation',
        },
      },
    },
    criticalAssetSecurity: {
      title: 'Critical Asset Security',
      subtitle: 'Secured Important Assets of total Important Assets.',
      summary: '{{metric}} of important assets are secured. Strengthening security policies for critical assets will improve resilience.',
      actions: {
        viewImportantAssets: {
          title: 'Important Assets Review',
          text: 'View Important Assets',
          label: 'Check security status of important assets',
        },
      },
    },
  },
};
